/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  InputAdornment,
  InputBase,
  Grid,
  Avatar,
  Chip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ChatIcon from "@material-ui/icons/Chat";

import "./ChatListMenu.scss";
import { useSelector } from "react-redux";
import moment from "moment";

const ChatListMenu = (props) => {
  const [searchText, setSearchText] = useState("");

  const { listChat } = useSelector((state) => state.stateChat);

  const { openProcessModal } = props;

  return (
    <div className="clientList">
      <div className="searchChat">
        <InputBase
          placeholder="Procurar por número do processo"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </div>

      {listChat.length > 0 ? (
        listChat
          .filter((f) => f.newMessages > 0)
          .map((item, index) => {
            return (
              <Grid
                container
                key={index}
                className="notification-item"
                onClick={() => openProcessModal(item)}
              >
                <Grid item container xs={12} style={{ padding: "8px" }}>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar style={{ backgroundColor: "#6f2c91" }}>
                      <ChatIcon style={{ color: "#FFFFFF" }} fontSize="small" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={9} className="d-flex align-items-center">
                    <span>{item.process.clientReference} </span> {" | "}
                    <span>{item.process.processNumber} </span>
                  </Grid>
                  <Grid item xs={1} className="notification-item-time">
                    <span>{moment(item.updatedAt).format("HH:mm")}</span>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="notification-item-content">
                  <span>você tem uma nova mensagem...</span>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Chip
                      label="Novo!"
                      style={{ backgroundColor: "#6f2c91", color: "#FFFFFF" }}
                      size="small"
                    />
                  </span>
                </Grid>
              </Grid>
            );
          })
      ) : (
        <div style={{ overflow: "auto", maxHeight: "473px" }}>
          <p style={{ textAlign: "center", marginTop: "3px" }}>
            Sem novas mensagens
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatListMenu;
