import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import "./PDFViewer.scss";

export default (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const { pdf } = props;

  return (
    <>
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          key={`page_${pageNumber}`}
          pageNumber={pageNumber}
          scale={0.85}
          size="auto"
        />
      </Document>
      <div className="navigation-controls">
        <Button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          <ArrowBackIos className="navigation-button" />
        </Button>

        <Button
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          <ArrowForwardIos className="navigation-button" />
        </Button>
      </div>
    </>
  );
};
