import { createActions, createReducer } from "reduxsauce";

const initialState = {
  listChat: [],
  newMessages: 0,
  processSelected: { id: "", processNumber: "" },
};

// Cria os action types e creators

export const { Types, Creators } = createActions({
  myListChat: ["newMessages, listChat"],
  handleChatNotify: ["newMessages"],
  handleChatRead: ["myListChat"],
  alterProcessSelected: ["processSelected"],
});

// Criando os reducers handle

const myListChat = (state = initialState, action) => ({
  ...state,
  newMessages: action.newMessages,
  listChat: action.listChat,
});

const handleChatNotify = (state = initialState, action) => ({
  ...state,
  newMessages: action.newMessages,
});

const handleChatRead = (state = initialState, action) => ({
  ...state,
  myListChat: action.myListChat,
});

const alterProcessSelected = (state = initialState, action) => ({
  ...state,
  processSelected: action.processSelected,
});
// Criando reducer

export default createReducer(initialState, {
  [Types.MY_LIST_CHAT]: myListChat,
  [Types.HANDLE_CHAT_NOTIFY]: handleChatNotify,
  [Types.HANDLE_CHAT_READ]: handleChatRead,
  [Types.ALTER_PROCESS_SELECTED]: alterProcessSelected,
});
