import React, { Fragment } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import NumberFormat from "react-number-format";

import { EquipmentsDetails } from "..";

import { ReactComponent as Dangerous } from "assets/icons/danger.svg";

const CargoDetails = (props) => {
  const { cargo, classes, knowledge } = props;

  return (
    <>
      <Typography
        color="textSecondary"
        variant="h6"
        style={{ fontWeight: "600" }}
      >
        Carga{" "}
        {cargo.isDangerous && (
          <Tooltip title="Carga Perigosa">
            <Dangerous
              style={{ height: "20px", width: "24px", marginTop: "-4px" }}
            />
          </Tooltip>
        )}
      </Typography>

      <Grid container style={{ padding: "12px" }}>
        <React.Fragment>
          {cargo.type ? (
            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid">Tipo de carga</b>
                <br />
                <span className="subtitle-grid">{cargo.type}</span>
              </Typography>
            </Grid>
          ) : (
            <React.Fragment />
          )}

          {cargo.merchandise ? (
            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid"> Mercadoria</b>
                <br />
                <span className="subtitle-grid">{cargo.merchandise}</span>
              </Typography>
            </Grid>
          ) : (
            <React.Fragment />
          )}

          {cargo.merchandiseCategory ? (
            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid">Grupo</b>
                <br />
                <span className="subtitle-grid">
                  {cargo.merchandiseCategory || ""}
                </span>
              </Typography>
            </Grid>
          ) : (
            <React.Fragment />
          )}

          {knowledge &&
            knowledge.equipaments &&
            knowledge.equipaments.length > 0 && (
              <EquipmentsDetails
                equipaments={knowledge.equipaments}
                classes={classes}
              />
            )}

          {cargo.houseMeasures &&
          (cargo.houseMeasures.grossWeight ||
            cargo.houseMeasures.sizeInCubicMeters ||
            cargo.houseMeasures.volumesQuantity ||
            cargo.houseMeasures.consideredWeight) ? (
            <Fragment>
              <Typography className={classes.subtitle1}>
                Medidas house
              </Typography>
              {cargo.houseMeasures.grossWeight ? (
                <Grid xs={3} item={true}>
                  <Typography
                    color="textSecondary"
                    style={{ paddingLeft: "6px" }}
                  >
                    <b className="title-grid">Peso bruto</b>
                    <br />
                    <span className="subtitle-grid">
                      <NumberFormat
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={3}
                        value={cargo.houseMeasures.grossWeight}
                        fixedDecimalScale={true}
                      />{" "}
                      kg
                    </span>
                  </Typography>
                </Grid>
              ) : (
                <React.Fragment />
              )}

              {cargo.houseMeasures.cubicMeters ? (
                <Grid xs={3} item={true}>
                  <Typography color="textSecondary">
                    <b className="title-grid">Metros cúbicos</b>
                    <br />
                    <span className="subtitle-grid">
                      <NumberFormat
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        value={cargo.houseMeasures.cubicMeters}
                        fixedDecimalScale={true}
                      />{" "}
                      m³
                    </span>
                  </Typography>
                </Grid>
              ) : (
                <React.Fragment />
              )}

              {cargo.houseMeasures.volumeQuantity ? (
                <Grid xs={3} item={true}>
                  <Typography color="textSecondary">
                    <b className="title-grid">Qtde volumes</b>
                    <br />
                    <span className="subtitle-grid">
                      <NumberFormat
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        value={cargo.houseMeasures.volumeQuantity}
                        fixedDecimalScale={true}
                      />{" "}
                    </span>
                  </Typography>
                </Grid>
              ) : (
                <React.Fragment />
              )}

              {cargo.houseMeasures.consideredWeight ? (
                <Grid xs={3} item={true}>
                  <Typography color="textSecondary">
                    <b className="title-grid"> Peso considerado</b>
                    <br />
                    <span className="subtitle-grid">
                      <NumberFormat
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={3}
                        value={cargo.houseMeasures.consideredWeight}
                        fixedDecimalScale={true}
                      />
                      w/m
                    </span>
                  </Typography>
                </Grid>
              ) : (
                <React.Fragment />
              )}
            </Fragment>
          ) : (
            <React.Fragment />
          )}

          {cargo.insurance && cargo.insurance.hasInsurance && (
            <React.Fragment>
              <Typography className={classes.subtitle1}>Seguro</Typography>

              <Grid xs={3} item={true}>
                <Typography color="textSecondary">
                  <b className="title-grid"> Seguro</b>
                  <br />
                  <span className="subtitle-grid">
                    {cargo.insurance.hasInsurance ? "SIM" : "NÃO"}
                  </span>
                </Typography>
              </Grid>

              {cargo.insurance.currency && (
                <Grid xs={3} item={true}>
                  <Typography color="textSecondary">
                    <b className="title-grid">Moeda</b>
                    <br />
                    <span className="subtitle-grid">{cargo.currency}</span>
                  </Typography>
                </Grid>
              )}

              {cargo.insurance.cargoValue && (
                <Grid xs={3} item={true}>
                  <Typography color="textSecondary">
                    <b className="title-grid">Valor segurado</b>
                    <br />
                    <span className="subtitle-grid">
                      {cargo.insurance.insuranceValue}
                      {cargo.insurance.insuranceValue
                        ? cargo.currency === "USD"
                          ? ".00"
                          : ",00"
                        : ""}
                    </span>
                  </Typography>
                </Grid>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </Grid>
    </>
  );
};

export default CargoDetails;
