/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";

import { Col, Row } from "react-bootstrap";

import "./styles.scss";
import {
  Divider,
  OutlinedInput,
  FormHelperText,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { upload } from "services/Upload/upload.service";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Upload from "assets/icons/upload_cloud.svg";
import File from "assets/icons/file.svg";
import { CreateOrderNew, UpdateOrderNew } from "services/Order/order.service";
import bytesToSize from "helpers/ConvertByteToSize";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";
import { useEffect } from "react";

const NewOrder = ({
  closeModal,
  setShowModal,
  setShowModalError,
  setMessageError,
  orderEdit,
}) => {
  const [data, setData] = useState({
    clientReference: "",
    provider: "",
    modality: "Marítima",
    incoterm: "",
    notes: "",
    documents: [],
  });

  const [myFiles, setMyFiles] = useState({
    images: [],
  });

  const [formErrors, setFormErrors] = useState({
    clientReference: "",
    provider: "",
  });
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let errors = {};
    let hasError = false;

    if (!data.clientReference) {
      errors.clientReference = "Campo obrigatório";
      hasError = true;
    } else {
      errors.clientReference = "";
    }

    if (data.clientReference.length <= 2) {
      errors.clientReference = "Necessário uma referência válida";
      hasError = true;
    } else {
      errors.clientReference = "";
    }

    if (!data.provider) {
      errors.provider = "Campo obrigatório";
      hasError = true;
    } else {
      errors.provider = "";
    }

    setFormErrors({ ...formErrors, ...errors });
    return hasError;
  };

  useEffect(() => {
    if (data.clientReference.length > 0 && data.clientReference.length <= 2) {
      setFormErrors({
        ...formErrors,
        clientReference: "Necessário uma referência válida",
      });
    } else {
      setFormErrors({ ...formErrors, clientReference: "" });
    }
  }, [data.clientReference]);

  const callbackSubmit = async (e) => {
    let response;
    let linkDocuments = [];

    try {
      e.preventDefault();
      let hasError = validate();
      if (!hasError) {
        setLoading(true);
        const dt = new FormData();

        if (myFiles.images.length > 0) {
          data.documents.map((link) => linkDocuments.push(link));

          for (const doc of myFiles.images) {
            dt.append("image", doc);
            let result = await upload(dt);
            linkDocuments.push(result.data.link);
          }
        }
      }

      const dataForm = {
        clientReference: data.clientReference.trim().replace(/\s+/g, ' '),
        provider: data.provider.trim(),
        modality: data.modality.trim(),
        incoterm: data.incoterm.trim(),
        notes: data.notes.trim(),
        employer: RetrieveUserInformation().employer._id,
        responsible: RetrieveUserInformation()._id,
        documents: linkDocuments,
      };

      if (!orderEdit || orderEdit === undefined || orderEdit === [""]) {
        response = await CreateOrderNew(dataForm);
      } else {
        response = await UpdateOrderNew(orderEdit._id, dataForm);
      }
      if (response.data) {
        setLoading(false);
        closeModal();
        setShowModal();
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setMyFiles((oldFiles) => ({
      ...oldFiles,
      images: [...myFiles.images, ...acceptedFiles],
    }));
  });

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (orderEdit && orderEdit !== undefined) {
      setData(orderEdit, { ...data, documents: [] });
    }
  }, [orderEdit]);

  return (
    <Row className="newOrderComponentContainer">
      <form autoComplete="off" onSubmit={callbackSubmit} className="w-100">
        <Col className="p-0 titlePageNewOrder">Incluir pedidos</Col>
        <Divider />
        <Col xs={12} className="p-0 d-flex flex-row mb-2">
          <Col xs={6} className="p-0 mr-1">
            <Col className="titleFieldNewOrder p-0">
              RF Cliente <span>*</span>
            </Col>
            <OutlinedInput
              className="w-100"
              error={formErrors["clientReference"] ? true : false}
              id="clientReference"
              value={data.clientReference.replace(/\s+/g, ' ')}
              autoFocus={true}
              onChange={(event) =>
                setData({
                  ...data,
                  clientReference: event.target.value,
                })
              }
            />
            {formErrors["clientReference"] && (
              <FormHelperText error>
                {formErrors["clientReference"]}
              </FormHelperText>
            )}
          </Col>
          <Col xs={6} className="p-0">
            <div className="titleFieldNewOrder">
              Fornecedor <span>*</span>
            </div>
            <OutlinedInput
              className="w-100"
              error={formErrors["provider"] ? true : false}
              id="provider"
              value={data.provider}
              onChange={(event) =>
                setData({
                  ...data,
                  provider: event.target.value,
                })
              }
            />
            {formErrors["provider"] && (
              <FormHelperText error>{formErrors["provider"]}</FormHelperText>
            )}
          </Col>
        </Col>
        <Col xs={12} className="p-0 d-flex flex-row mb-2">
          <Col xs={6} className="p-0 mr-1">
            <div className="titleFieldNewOrder">
              Modalidade <span>*</span>
            </div>
            <FormControl variant="filled" className="w-100">
              <Select
                id="modality"
                required
                error={formErrors["modality"] ? true : false}
                variant="outlined"
                value={data.modality}
                onChange={(event) =>
                  setData({ ...data, modality: event.target.value })
                }
                style={{ color: "#ada5b1" }}
              >
                <MenuItem value="Marítima">Marítima</MenuItem>
                <MenuItem value="Aérea">Aérea</MenuItem>
              </Select>
            </FormControl>
            {formErrors["modality"] && (
              <FormHelperText error>{formErrors["modality"]}</FormHelperText>
            )}
          </Col>
          <Col xs={6} className="p-0">
            <div className="titleFieldNewOrder">Incoterm</div>
            <OutlinedInput
              className="w-100"
              error={formErrors["incoterm"] ? true : false}
              id="incoterm"
              value={data.incoterm}
              onChange={(event) =>
                setData({
                  ...data,
                  incoterm: event.target.value,
                })
              }
            />
          </Col>
        </Col>
        <Col xs={12} className="p-0 mb-4">
          <Col className="titleFieldNewOrder p-0">Observações</Col>
          <TextField
            fullWidth={true}
            multiline
            rows="2"
            defaultValue={data.notes}
            onChange={(event) =>
              setData({
                ...data,
                notes: event.target.value,
              })
            }
            variant="outlined"
            placeholder="Escreva a observação aqui..."
            className="obsNewOrder"
          />
        </Col>
        <Col xs={12} className="containerFileNewOrder p-0">
          <div className="displayFlex contentFileNewOrder">
            <div className="containerUploadNewOrder">
              <div className="titleFileNewOrder">Upload de arquivos*</div>
              <div {...getRootProps()} className="descriptionUploadNewOrder">
                <input {...getInputProps()} />
                <div>
                  <img src={Upload} alt="upload" />
                </div>
                <p>Arraste e solte seu arquivo aqui</p>
                <p>ou</p>
                <div>
                  <Button variant="outlined" className="btnUploadNewOrder">
                    Escolha seu arquivo
                  </Button>
                </div>
              </div>
            </div>
            <div className="containerListNewOrder">
              <div className="titleFileNewOrder">
                {" "}
                {data.documents.length > 0 && data.documents[0] !== ""
                  ? "Arquivos já enviados"
                  : "Arquivos enviados"}
              </div>
              {data.documents &&
                data.documents.length > 0 &&
                data.documents[0] !== "" && (
                  <div className="flex containerItemsCloud">
                    {data.documents.map((item, index) => {
                      return (
                        <div className="itemListUploadNewOrder" key={index}>
                          <div className="previewItemNewOrder">
                            <img alt="upload" src={item} />
                          </div>
                          <div style={{ width: "100%" }}>
                            <div className="headerItemNewOrder">
                              <div className="flex itemNameNewOrder">
                                {/* {item.name} */}
                              </div>
                              <div className="flex">
                                {/* {bytesToSize(item.size)} */}
                              </div>
                              <div>
                                <DeleteForeverIcon
                                  onClick={() => {
                                    setData((oldData) => ({
                                      ...data,
                                      documents: oldData.documents
                                        .filter((documents, i) => i !== index)
                                        .map((documents) => documents),
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="containerLoadingItemNewOrder">
                              <div className="loadingItemNewOrder" />
                              <p>100%</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

              <div className="containerItemsNewOrder">
                {myFiles.images && myFiles.images.length > 0 ? (
                  myFiles.images.map((item, index) => {
                    return (
                      <div className="itemListUploadNewOrder" key={index}>
                        <div className="previewItemNewOrder">
                          <img
                            alt="upload"
                            src={
                              item.type.includes("image")
                                ? URL.createObjectURL(item)
                                : File
                            }
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="headerItemNewOrder">
                            <div className="flex itemNameNewOrder">
                              {item.name}
                            </div>
                            <div className="flex">{bytesToSize(item.size)}</div>
                            <div>
                              <DeleteForeverIcon
                                onClick={() => {
                                  setMyFiles((oldData) => ({
                                    ...myFiles,
                                    images: oldData.images
                                      .filter((documents, i) => i !== index)
                                      .map((documents) => documents),
                                  }));
                                }}
                              />
                            </div>
                          </div>
                          <div className="containerLoadingItemNewOrder">
                            <div className="loadingItemNewOrder" />
                            <p>100%</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : data.documents[0] !== "" && data.documents.length > 0 ? (
                  ""
                ) : (
                  <div>Nenhum arquivo selecionado</div>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col className="buttonsNewOrder p-0 d-flex justify-content-between">
          <Button
            variant="outlined"
            onClick={() => closeModal()}
            className="btnCloseNewOrder"
          >
            Cancelar
          </Button>
          <Button type="submit" variant="outlined" className="btnSaveNewOrder">
            {orderEdit ? "Atualizar pedido" : "Cadastrar pedido"}
          </Button>
        </Col>
      </form>
      <Backdrop className="loading" open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Row>
  );
};

export default NewOrder;
