/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  NavBar,
  MainHeader,
  MapBoxHome,
  NewModal,
  ExternalModal,
  AlertNotification,
} from "components";
import { Container, Col, Row } from "react-bootstrap";
import { Types as openMapsTypes } from "store/reducers/openMapsReducer";
import { Types as alertTypes } from "store/reducers/alertReducer";
import { ArrowForwardIos } from "@material-ui/icons";

import { RetrieveToken } from "services/Auth/usermanager.service";

import { Types as currentProcessTypes } from "store/reducers/selectedReducer";
import { Modal } from "@material-ui/core";

import "../global.scss";
import "./styles.scss";

const Private = (props) => {
  const { route } = props;

  const { data } = useSelector((state) => state.stateProcess);
  const { checked, clickOnMap } = useSelector((state) => state.stateShowMaps);
  const { message, severity, open } = useSelector((state) => state.stateAlert);

  const { notify } = useSelector((state) => state.stateNotify);

  const dispatch = useDispatch();
  const history = useHistory();

  const [externalDetailsModal, setExternalDetailsModal] = useState(false);
  const [kanbanDetailsModal, setKanbanDetailsModal] = useState(false);

  const isAuthenticated = () => {
    const token = RetrieveToken();

    if (token === undefined || token === "undefined" || !token) {
      localStorage.clear();
      history.push("/login");
    }
  };

  const handleChange = () => {
    dispatch({
      type: openMapsTypes.SHOW_MAPS,
      checked: true,
    });
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  const handleOpenExternalDetails = (item) => {
    dispatch({
      type: currentProcessTypes.CURRENT_SELECTED,
      currentProcess: item,
    });

    setExternalDetailsModal(true);
  };

  const handleCloseExternalDetails = () => {
    setExternalDetailsModal(false);
  };

  const handleOpenModalKanbanDetails = (item) => {
    dispatch({
      type: currentProcessTypes.CURRENT_SELECTED,
      currentProcess: item,
    });
  };

  const handleCloseModalKanbanDetails = () => {
    setKanbanDetailsModal(false);
  };

  return (
    <Container fluid className="p-0 private-root">
      <Helmet>
        <title>
          {notify.length > 0 ? `(${notify.length}) Cargo Play` : "Cargo Play"}
        </title>
      </Helmet>

      <Row className="p-0 m-0 d-flex flex-column">
        <div
          id="myDiv"
          className={checked || clickOnMap ? "map-none" : "map-home p-0"}
        >
          <div className="button-map-actived">
            <button onClick={handleChange} id="btnMapa">
              <span>Fechar</span>
              <span className="">
                <ArrowForwardIos />
              </span>
              <span>Mapa</span>
            </button>
          </div>
          <MapBoxHome
            data={data}
            handleOpenModalKanbanDetails={(item) =>
              handleOpenModalKanbanDetails(item)
            }
            handleOpenExternalDetails={(item) =>
              handleOpenExternalDetails(item)
            }
          />
        </div>

        <Col className="p-0">
          <MainHeader />
        </Col>
        <Col className="p-0 d-flex flex-row">
          <section>
            <NavBar />
          </section>
          <section className="private-content">
            <Suspense fallback={<ProgressBar striped />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </section>
        </Col>
      </Row>
      {kanbanDetailsModal && (
        <NewModal
          onClose={handleCloseModalKanbanDetails}
          open={kanbanDetailsModal}
        />
      )}

      <Modal
        open={externalDetailsModal}
        onClose={() => setExternalDetailsModal(false)}
        disableEscapeKeyDown={false}
      >
        <ExternalModal onClose={handleCloseExternalDetails} />
      </Modal>

      <AlertNotification
        open={open}
        message={message}
        severity={severity}
        onClose={() => {
          dispatch({
            type: alertTypes.SHOW_ALERT,
          });
        }}
      />
    </Container>
  );
};

Private.propTypes = {
  route: PropTypes.object,
};

export default Private;
