import apiConsumer, { newDataAPI, buildURL } from "../apiConsumer";

export const getNotifications = async (page) => {
  return await apiConsumer.get(`api/notification?pageIndex=${page}`);
};

export const getNotificationsNEW = async (query, sort, page, limit) => {
  return await newDataAPI.get(
    `user-notifications/${buildURL(query, sort, page, limit ? limit : 99)}`
  );
};

//método para marcar todas como lidas
export const readAllNotifications = async (infos) => {
  return await newDataAPI.post(`/user-notifications/readall`, infos);
};

//método para marcar uma como lida
export const putNotification = async (notification) => {
  return await newDataAPI.patch(
    `/user-notifications/${notification._id}`,
    notification
  );
};
