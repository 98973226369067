import "./MapBoxHome.scss";

import { Tooltip } from "@material-ui/core";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Types as openMapsTypes } from "store/reducers/openMapsReducer";
import { Types as currentProcessTypes } from "store/reducers/selectedReducer";

import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faAnchor, faShip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { MicroStatus } from "components";

const MapBoxHome = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selecteds, setSelecteds] = React.useState([]);

  const { data } = props;
  const locations = [];

  const mapRef = useRef(null);
  const [position, setPosition] = useState({
    lat: 0.0,
    lng: 0.0,
  });

  const mapStyle = [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  function setMarkerName(process) {
    if (
      process.transportationModality === "Marítimo" ||
      process.mode === "SEA"
    ) {
      if (process.onBoard) {
        return `A bordo de ${
          process.dynamicVessel !== null
            ? process.dynamicVessel.name
            : "Návio Dinânico com problema"
        }`;
      } else {
        return `${process.dynamicPort ? process.dynamicPort.name : -1}`;
      }
    } else {
      // return `Aéroporto de ${process.dynamicAirport.name}`;
      return "TESTE";
    }
  }

  function setLocation(process) {
    let location = { lat: -1, lng: -1 };
    if (
      process.transportationModality === "Marítimo" ||
      process.mode === "SEA"
    ) {
      if (process.onBoard) {
        location.lat = process.dynamicVessel?.lastLocation?.latitude
          ? process.dynamicVessel.lastLocation.latitude
          : -1;
        location.lng = process.dynamicVessel?.lastLocation?.longitude
          ? process.dynamicVessel.lastLocation.longitude
          : -1;
        return location;
      } else {
        location.lat = process.dynamicPort?.latitude
          ? process.dynamicPort.latitude
          : -1;
        location.lng = process.dynamicPort?.longitude
          ? process.dynamicPort.longitude
          : -1;
        return location;
      }
    } else {
      location.lat = process.dynamicAirport?.latitude
        ? process.dynamicAirport.latitude
        : -1;
      location.lng = process.dynamicAirport?.longitude
        ? process.dynamicAirport.longitude
        : -1;
      return location;
    }
  }

  data.forEach((process) => {
    if (
      process.boarding ||
      (process.mode === "SEA" && process.status !== "PENDING")
    ) {
      let marker = {
        name: setMarkerName(process),
        location: setLocation(process),
        type: process.operationType,
        port: !process.onBoard,
        process: process,
      };
      locations.push(marker);
    }
  });

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  };

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const onSelect = (item) => {
    /**
     * Vamos pegar todas as cargas com a mesma posição então é necessário:
     * 1. Filtrar todas as cargas com última localização igual.
     * 2. Adicionar todas elas em uma lista.
     * 3. Alterar a modal para exibir uma lista e não apenas um carga.
     */
    let filteredProcess = locations.filter((temp) => {
      return (
        temp.location.lat === item.location.lat &&
        temp.location.lng === item.location.lng
      );
    });
    setSelecteds(filteredProcess);
    //setShow(true)
  };

  /**
   *
   * @param {*} process - qual o processo
   * @param {*} desired - Deseja o ETA ou o ETD?
   *
   */
  function getETDETA(process, desired) {
    if (process.boarding) {
      //se tem boarding é um processo da Next
      if (desired === "eta") {
        return process.boarding.cargoUnload
          ? process.boarding.cargoUnload
          : process.boarding.estimatedCargoUnload;
      } else {
        return process.boarding.cargoLoad
          ? process.boarding.cargoLoad
          : process.boarding.estimatedCargoLoad;
      }
    } else if (process.dates) {
      //vamos trabalhar as datas da carga externa
      if (desired === "etd") {
        return process.dates.actualLoad
          ? process.dates.actualLoad
          : process.dates.plannedLoad
          ? process.dates.plannedLoad
          : null;
      } else {
        return process.dates.actualDischarge
          ? process.dates.actualDischarge
          : process.dates.plannedDischarge
          ? process.dates.plannedDischarge
          : null;
      }
    }
    return null;
  }

  function getDestionation(process) {
    if (process.boarding) {
      return process.boarding.destination.length < 4
        ? process.boarding.destination
        : process.boarding.destination.slice(0, 3) + "...";
    } else if (process.destination) {
      return process.destination.name.length < 4
        ? process.destination.name
        : process.destination.name.slice(0, 3) + "...";
    } else return null;
  }

  return (
    <React.Fragment>
      <LoadScript googleMapsApiKey="AIzaSyDQO3QxcSR1If9RTRH1DRc6Bpu2xYX7DDk">
        <GoogleMap
          options={{
            styles: mapStyle,
          }}
          mapContainerStyle={mapStyles}
          zoom={2}
          onLoad={handleLoad}
          onDragEnd={handleCenter}
          center={position}
          id="map"
          maxZoom={3}
        >
          <MarkerClusterer
            options={options}
            gridSize={32}
            averageCenter={true}
            maxZoom={3}
            zoomOnClick={false}
          >
            {(cluster) =>
              locations.map((item, index) => {
                return (
                  <Marker
                    // key={createKey(item.location, index)}
                    key={index}
                    position={item.location}
                    onClick={() => onSelect(item)}
                    clusterer={cluster}
                    icon={{
                      url: item.port
                        ? `assets/icons/port.png`
                        : item.process.transportationModality === "Aéreo"
                        ? `assets/icons/airplane.png`
                        : `assets/icons/ship-importacao.png`,
                    }}
                  />
                );
              })
            }
          </MarkerClusterer>
          {selecteds.length > 0 && selecteds[0].location && (
            <InfoWindow
              position={selecteds[0].location}
              clickable={true}
              onCloseClick={() => setSelecteds([])}
              options={{ disableAutoPan: false }}
            >
              <React.Fragment>
                <div className="mapbox-custom-popup">
                  <div className="popup-ship-info">
                    <p className="titlePopup">
                      <FontAwesomeIcon
                        className="icon-title"
                        icon={!selecteds[0].port ? faShip : faAnchor}
                      />
                      {selecteds[0].name}
                    </p>
                    <hr />
                    <div className="containerItems">
                      {selecteds.map((selected, index) => (
                        <div key={index}>
                          <div
                            className="listCargoContentPopup"
                            onClick={() => {
                              dispatch({
                                type: currentProcessTypes.CURRENT_SELECTED,
                                currentProcess: selected.process,
                              });
                              selected.process.processNumber
                                ? history.push(
                                    `/process-details/${selected.process._id}`
                                  )
                                : props.handleOpenExternalDetails(
                                    selected.process
                                  );
                              dispatch({
                                type: openMapsTypes.HANDLE_CLICK_ON_MAP,
                                clickOnMap: true,
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "40% 60%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tooltip
                                title={
                                  selected.process.clientReference +
                                  " | " +
                                  selected.process.processNumber
                                }
                                placement="top"
                                arrow
                              >
                                <span className="codeCargo">
                                  {selected.process.clientReference ||
                                    selected.process.processNumber}
                                </span>
                              </Tooltip>
                              {selected.process.kanbanStatus !==
                                "Pré embarque" && (
                                <div>
                                  <Typography
                                    color="textSecondary"
                                    className="dateBorderCard"
                                    component={"span"}
                                    style={{ marginRight: "6px" }}
                                  >
                                    ETD
                                    <span
                                      style={{
                                        marginBottom: "0px",
                                        marginLeft: "2px",
                                        color:
                                          selected.process.boarding &&
                                          selected.process.boarding.cargoLoad
                                            ? "green"
                                            : "black",
                                      }}
                                    >
                                      {moment
                                        .utc(getETDETA(selected.process, "etd"))
                                        .format("DD MMM")}
                                    </span>
                                  </Typography>

                                  <Typography
                                    color="textSecondary"
                                    className="dateBorderCard"
                                    component={"span"}
                                    title={setMarkerName(selected.process)}
                                  >
                                    ETA{" "}
                                    {(" ", getDestionation(selected.process))}
                                    <span
                                      style={{
                                        marginBottom: "0px",
                                        marginLeft: "2px",
                                        color:
                                          selected.process.boarding &&
                                          selected.process.boarding.cargoUnload
                                            ? "green"
                                            : "black",
                                      }}
                                    >
                                      {moment
                                        .utc(getETDETA(selected.process, "eta"))
                                        .format("DD MMM")}
                                    </span>
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </div>
                          <MicroStatus
                            message={
                              selected.process.microStatus
                                ? "Aguardando " + selected.process.microStatus
                                : "Carga externa"
                            }
                            icon={selected.process.microStatus ? faClock : ""}
                          ></MicroStatus>
                          <hr />
                        </div>
                      ))}
                      <div className="lastUpdate">
                        <span>
                          Última atualização:{" "}
                          {selecteds[0].process.onBoard
                            ? selecteds[0].process.dynamicVessel &&
                              selecteds[0].process.dynamicVessel.lastLocation
                              ? moment
                                  .utc(
                                    selecteds[0].process.dynamicVessel
                                      .lastLocation.lastUpdate
                                  )
                                  .format("DD/MM HH:mm")
                              : "Desconhecida"
                            : moment
                                .utc(selecteds[0].process.updatedAt)
                                .format("DD/MM HH:mm")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
      {/**
        <SidePanel selecteds={selecteds} show={show}></SidePanel>
         */}
    </React.Fragment>
  );
};

export default MapBoxHome;
