/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { Types as processTypes } from "store/reducers/processReducer";
import { Types as alertTypes } from "store/reducers/alertReducer";
import {
  Divider,
  FormHelperText,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Radio,
  TextField,
} from "@material-ui/core";

import { externalprocesses } from "services/Process/process.service";

import "./styles.scss";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";
import { NewGetUsers } from "services/User/user.service";

const OtherModal = (props) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.stateProcess);
  const mySelect = [
    { identifier: "ALIANCA", name: "Aliança", scac: "ANRM" },
    { identifier: "CMA_CGM", name: "CMA-CGM", scac: "CMDU" },
    { identifier: "COSCO", name: "COSCO", scac: "COSU" },
    { identifier: "EVERGREEN", name: "Evergreen", scac: "EGLV" },
    { identifier: "HAMBURG_SUD", name: "Hamburg Sud", scac: "SUDU" },
    { identifier: "HAPAG_LLOYD", name: "Hapag Lloyd", scac: "HLCU" },
    { identifier: "MAERSK", name: "MAERSK", scac: "MAEU" },
    { identifier: "MSC", name: "MSC", scac: "MEDU" },
    {
      identifier: "OCEAN_NETWORK_EXPRESS",
      name: "Ocean Network Express",
      scac: "ONEY",
    },
    { identifier: "PIL", name: "PIL", scac: "PCIU" },
    { identifier: "SAFMARINE", name: "SAFMARINE", scac: "SAFM" },
    { identifier: "SEAGO", name: "Sealand", scac: "SEJJ" },
  ];

  const [listUsers, setListUsers] = React.useState([]);

  const CNPJ = JSON.parse(localStorage.getItem("userinfo")).employer._id;

  const [data, setData] = useState({
    master: "",
    carrier: "",
    clientReference: "",
    mode: "SEA",
    client: CNPJ,
    analist: [],
  });

  const [formErrors, setFormErrors] = useState({
    master: "",
    carrier: "",
    clientReference: "",
    mode: "",
    client: "",
    //analist: "",
  });
  const handleAnalyst = (event) => {
    let n = listUsers.filter((f) => f._id === event).map((m) => m.name);
    const analist = {
      name: n[0],
      id: event,
    };
    setData({ ...data, analist: analist });
  };
  const filterAnalystAndCompanyAdministrator = (value) => {
    if (
      value.profiles.includes("Analyst") ||
      value.profiles.includes("CompanyAdministrator")
    ) {
      return value;
    }
  };
  const getUsers = async () => {
    let criteria = [];

    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });
    try {
      const result = await NewGetUsers(
        criteria.length > 0 && JSON.stringify({ $and: criteria })
      );

      setListUsers(
        result.data.items.filter(filterAnalystAndCompanyAdministrator)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (event) => {
    let errors = {};
    let hasError = false;
    switch (event) {
      case "master":
        if (!data.master) {
          errors.master = "Campo obrigatório";
          hasError = true;
        }
        break;
      case "carrier":
        if (!data.carrier) {
          errors.carrier = "Campo obrigatório";
          hasError = true;
        }
        break;
      case "clientReference":
        if (!data.clientReference) {
          errors.clientReference = "Campo obrigatório";
          hasError = true;
        }
        break;

      default:
        errors.master = "";
        break;
    }

    setFormErrors({ ...formErrors, ...errors });

    return hasError;
  };

  // useEffect(() => {
  //   validate();
  // }, [data.master, data.carrier, data.clientReference]);
  useEffect(() => {
    getUsers();
  }, []);
  const callbackSubmit = async (e) => {
    dispatch({
      type: processTypes.CURRENT_PROCESS_REQUEST,
    });

    try {
      e.preventDefault();

      let hasError = validate();
      if (!hasError) {
        await externalprocesses(data);
        dispatch({
          type: alertTypes.SHOW_ALERT,
          open: true,
          message: "Processo externo, incluído com sucesso!",
          severity: "success",
        });
      }
    } catch (error) {
      dispatch({
        type: alertTypes.SHOW_ALERT,
        open: true,
        message: "Error ao incluir processo externo, verificar informações!",
        severity: "error",
      });

      dispatch({
        type: processTypes.CURRENT_PROCESS_FAILURE,
        error: error,
      });
    } finally {
      props.closeModal();
    }
  };

  return (
    <form autoComplete="off" onSubmit={callbackSubmit}>
      <Row className="otherModalComponentContainer">
        <Col xs={12} className="p-0">
          <div className="titlePageOtherModal">
            Inclusão de processos externos
          </div>
        </Col>
        <Divider />
        <Col className="p-0">
          <div className="containerFieldOtherModal">
            <div className="itemOtherModal">
              <div className="titleFieldOtherModal">
                Número da Master <span>*</span>
              </div>
              <TextField
                required
                id="master"
                error={formErrors["master"] ? true : false}
                value={data?.master}
                variant="outlined"
                size="small"
                onChange={(event) =>
                  setData({
                    ...data,
                    master: event.target.value,
                  })
                }
                onBlur={(event) => validate(event.target.id)}
              />
              {formErrors["master"] && (
                <FormHelperText error>{formErrors["master"]}</FormHelperText>
              )}
            </div>
          </div>
          <div className="itemOtherModal">
            <div className="titleFieldOtherModal">Analista</div>
            <FormControl variant="filled" size="small">
              <Select
                variant="outlined"
                value={data?.analist?.id}
                id="analist"
                onChange={(event) => handleAnalyst(event.target.value)}
                style={{ color: "#ada5b1" }}
              >
                <MenuItem value="-1">Sem analista</MenuItem>
                {RetrieveUserInformation().roles.includes(
                  "CompanyAdministrator"
                ) ? (
                  listUsers.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={RetrieveUserInformation()._id}>
                    {RetrieveUserInformation().name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div className="itemOtherModal">
            <div className="titleFieldOtherModal">Transporte</div>

            <Radio
              onChange={(event) =>
                setData({ ...data, mode: event.target.value })
              }
              checked
              value="SEA"
              name="transpor"
              inputProps={{ "aria-label": "Transporte" }}
            />
            <label>Marítimo</label>
          </div>
        </Col>
        <Col className="p-0">
          <div className="itemOtherModal">
            <div className="titleFieldOtherModal">
              Armador <span>*</span>
            </div>
            <FormControl variant="filled" size="small">
              <Select
                required
                error={formErrors["carrier"] ? true : false}
                id="carrier"
                variant="outlined"
                value={data?.carrier}
                onChange={(event) =>
                  setData({ ...data, carrier: event.target.value })
                }
                onBlur={(event) => validate(event.target.id)}
                style={{ color: "#ada5b1" }}
              >
                <MenuItem value="" selected={true}>
                  Selecione
                </MenuItem>
                {mySelect &&
                  mySelect.length > 0 &&
                  mySelect.map((b) => (
                    <MenuItem key={b.identifier} value={b.identifier}>
                      {b.name}{" "}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {formErrors["carrier"] && (
              <FormHelperText error>{formErrors["carrier"]}</FormHelperText>
            )}
          </div>
          <div className="itemOtherModal2">
            <div className="titleFieldOtherModal">
              Referência para o embarque <span>*</span>
            </div>
            <TextField
              required
              id="clientReference"
              error={formErrors["clientReference"] ? true : false}
              value={data?.clientReference}
              variant="outlined"
              size="small"
              onChange={(event) =>
                setData({
                  ...data,
                  clientReference: event.target.value,
                })
              }
              onBlur={(event) => validate(event.target.id)}
            />
            {formErrors["clientReference"] && (
              <FormHelperText error>
                {formErrors["clientReference"]}
              </FormHelperText>
            )}
          </div>
        </Col>
        <Col xs={12}>
          <div className="buttonsOtherModal">
            <Button
              type="submit"
              variant="outlined"
              className="btnSaveOtherModal"
            >
              {isLoading ? <CircularProgress color="inherit" /> : "Confirmar"}
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default OtherModal;
