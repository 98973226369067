import { createActions, createReducer } from "reduxsauce";

const initialState = {
  open: false,
  message: "",
  severity: "",
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  showAlert: ["open", "message", "severity"],
});

// Criando os reducers handle
const showAlert = (state = initialState, action) => ({
  ...state,
  open: action.open,
  message: action.message,
  severity: action.severity,
});
// Criando reducer
export default createReducer(initialState, {
  [Types.SHOW_ALERT]: showAlert,
});
