/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { useSelector } from "react-redux";

import "./DetailTab.scss";

import {
  CargoDetails,
  KnowledgeDetails,
  BoardingDetails,
  BoardingAir,
} from "./components";

import { BrokerSelect } from "components";
import KnowledgeAir from "./components/KnowledgeAir";
import AnalistSelect from "components/AnalistSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  gridContent: {
    borderBottom: "1px solid #CFCFCF",
    paddingTop: "4%",
    paddingBottom: "4%",
  },
  subtitle1: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.75",
    letterSpacing: "0.00938em",
    color: "#6f2c91",
    width: "100%",
    paddingTop: "15px",
    paddingLeft: "0px",
  },
  textLine: {
    fontSize: "11px",
  },
  AccordionSummary: {
    margin: "0px",
  },
  heading: {
    fontSize: "19px",
  },
}));

function DetailTab(props) {
  const { currentProcess } = useSelector((state) => state.stateSelected);
  const classes = useStyles();
  // const {
  //   transportationModality,
  //   boarding,
  //   transshipments,
  //   consignee,
  //   shipper,
  //   notify,
  //   microStatus,
  //   pickups,
  // } = props.itemSelected ? props.itemSelected : props.currentProcess;

  const [cargocraftName, setCargocraftName] = React.useState("");
  const [cargoVoyageName, setCargoVoyageName] = React.useState("");

  useEffect(() => {
    function getCargoCraftName() {
      if (currentProcess.boarding !== null) {
        if (currentProcess.onBoard)
          return currentProcess.dynamicVessel
            ? `${currentProcess.dynamicVessel.name}`
            : " - ";
        else if (!currentProcess.onBoard && currentProcess.boarding.cargoUnload)
          return currentProcess.dynamicVessel
            ? `${currentProcess.dynamicVessel.name}`
            : currentProcess.boarding.vessel &&
              currentProcess.boarding.vessel.name
            ? currentProcess.boarding.vessel.name
            : " - ";
        return "Sem informação de navio";
      }
    }
    let shipName = getCargoCraftName();
    setCargocraftName(shipName);
  }, []);

  useEffect(() => {
    function getCargoVoyageName() {
      if (currentProcess.boarding != null) {
        if (
          !currentProcess.transshipments ||
          currentProcess.transshipments.length < 1 ||
          !currentProcess.transshipments[0].arrival ||
          currentProcess.microStatus.includes("Descarregamento no transbordo")
        )
          return currentProcess.boarding.voyageId;
        else if (
          currentProcess.transshipments &&
          currentProcess.transshipments.length > 0
        ) {
          let _cargoVoyageName = currentProcess.transshipments.filter(
            (item) => {
              return item.vessel === currentProcess.dynamicVessel;
            }
          );

          return _cargoVoyageName &&
            _cargoVoyageName.length > 0 &&
            _cargoVoyageName[_cargoVoyageName.length - 1].voyageId
            ? _cargoVoyageName[_cargoVoyageName.length - 1].voyageId
            : "-";
        }
        return "Sem informação de viagem";
      }
    }
    let voyageName = getCargoVoyageName();
    setCargoVoyageName(voyageName);
  }, []);

  return (
    <div className={classes.root}>
      <hr />
      <Typography
        color="textSecondary"
        variant="h6"
        style={{ fontWeight: "600" }}
      >
        Despachante
      </Typography>
      <Grid container style={{ padding: "12px" }}>
        <BrokerSelect />
      </Grid>
      <hr />
      <Typography
        color="textSecondary"
        variant="h6"
        style={{ fontWeight: "600" }}
      >
        Analista
      </Typography>
      <Grid container style={{ padding: "12px" }}>
        <AnalistSelect />
      </Grid>
      <hr />
      <Typography
        color="textSecondary"
        variant="h6"
        style={{ fontWeight: "600" }}
      >
        Embarque
      </Typography>
      <Grid container style={{ padding: "12px" }}>
        {currentProcess.transportationModality === "Marítimo" ? (
          <BoardingDetails
            cargoVoyageName={cargoVoyageName}
            cargocraftName={cargocraftName}
            boarding={currentProcess.boarding}
            shipper={currentProcess.shipper && currentProcess.shipper.name}
            consignee={
              currentProcess.consignee && currentProcess.consignee.name
            }
            notify={currentProcess.notify && currentProcess.notify.name}
            classes={classes}
          />
        ) : (
          <BoardingAir
            cargoVoyageName={cargoVoyageName}
            cargocraftName={cargocraftName}
            boarding={currentProcess?.boarding}
            shipper={currentProcess && currentProcess?.shipper?.name}
            consignee={currentProcess && currentProcess?.consignee?.name}
            notify={currentProcess && currentProcess?.notify?.name}
            classes={classes}
          />
        )}
      </Grid>
      <hr />

      {currentProcess.boarding && currentProcess.boarding.cargo && (
        <CargoDetails
          cargo={currentProcess.boarding.cargo}
          classes={classes}
          knowledge={currentProcess.boarding.knowledge}
        />
      )}

      <Typography
        color="textSecondary"
        variant="h6"
        style={{ fontWeight: "600" }}
      >
        Conhecimento
      </Typography>
      {currentProcess && (
        <Grid container style={{ padding: "12px" }}>
          {currentProcess.boarding &&
          currentProcess.boarding.knowledge &&
          currentProcess.transportationModality === "Marítimo" ? (
            <KnowledgeDetails
              classes={classes}
              knowledge={currentProcess.boarding.knowledge}
            />
          ) : (
            <KnowledgeAir
              classes={classes}
              // knowledge={currentProcess.boarding.knowledge}
              modality={currentProcess.transportationModality}
            />
          )}
          {/** {transportationModality === 'Aéreo' && <KnowledgeAir />} */}
        </Grid>
      )}
    </div>
  );
}

export default DetailTab;
