import { createActions, createReducer } from "reduxsauce";

const initialState = {
  currentUser: {
    name: "",
    avatar: "",
    email: "",
    phone: "",
    password: "",
    employersAccessList: [],
    employer: {
      name: "",
      _id: "",
    },
    confirmPassword: "",
    currentPassword: "",
    profiles: [],
    hasChat: true,
    disabled: false,
    companyName: "",
    faturas: "0",
    permissions: [],
    releasePermissions: [],
  },
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  loadUser: ["currentUser"],
  resetUser: ["currentUser"],
});

// Criando os reducers handle
const loadUser = (state = initialState, action) => ({
  ...state,
  currentUser: action.currentUser,
});

const resetUser = (state = initialState, action) => ({
  currentUser: {
    name: "",
    avatar: "",
    email: "",
    phone: "",
    password: "",
    employersAccessList: [],
    employer: {
      name: "",
      _id: "",
    },
    confirmPassword: "",
    currentPassword: "",
    profiles: [],
    hasChat: true,
    disabled: false,
    companyName: "",
    faturas: "0",
    permissions: [],
    releasePermissions: [],
  },
});

// Criando reducer
export default createReducer(initialState, {
  [Types.LOAD_USER]: loadUser,
  [Types.RESET_USER]: resetUser,
});
