import React from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import "./KanbanMenu.scss";

const KanbanMenu = (props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={ButtonGroup} id="dropdown-custom-components">
        <MoreVertIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>{props.children}</Dropdown.Menu>
    </Dropdown>
  );
};
export default KanbanMenu;
