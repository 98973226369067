import { createActions, createReducer } from "reduxsauce";

const initialState = {
  checked: true,
  clickOnMap: false,
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  showMaps: ["checked"],
  handleClickOnMap: ["clickOnMap"],
});

// Criando os reducers handle
const showMaps = (state = initialState, action) => ({
  ...state,
  checked: action.checked,
});

const handleClickOnMap = (state = initialState, action) => ({
  ...state,
  clickOnMap: action.clickOnMap,
});

// Criando reducer
export default createReducer(initialState, {
  [Types.SHOW_MAPS]: showMaps,
  [Types.HANDLE_CLICK_ON_MAP]: handleClickOnMap,
});
