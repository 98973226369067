export const PersistUserToLocalStorage = async (token) => {
  localStorage.setItem("token", token.accessToken);
  localStorage.setItem("userinfo", JSON.stringify(token));
  if (!JSON.parse(localStorage.getItem("typeView"))) {
    localStorage.setItem("typeView", JSON.stringify("kanban"));
  }

  return;
};

export const RetrieveToken = () => {
  return localStorage.getItem("token");
};

export const RetrieveUserInformation = () => {
  let user = localStorage.getItem("userinfo");
  if (user) return JSON.parse(user);

  return undefined;
};
