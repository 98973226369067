import { createActions, createReducer } from "reduxsauce";

const initialState = {
  modalCreateOrder: false,
  modalKanbanDetails: false,
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  openCloseModal: ["modalCreateOrder", "modalKanbanDetails"],
});

// Criando os reducers handle
const openCloseModal = (state = initialState, action) => ({
  ...state,
  modalCreateOrder: action.modalCreateOrder || state,
  modalKanbanDetails: action.modalKanbanDetails,
});

//Criando Reducer
export default createReducer(initialState, {
  [Types.OPEN_CLOSE_MODAL]: openCloseModal,
});
