import React from "react";
import "./IssuesTimeline.scss";
// import { IssueItem } from "./components";
import { IssueItem } from "..";

function IssuesTimeline(props) {
  const { issues, processID, responsibles, processNumber, refClient } = props;

  const viewPDF = (file) => {
    props.openPDF(file);
  };

  return (
    <div className="">
      <div className="timeline-issue">
        {issues.map((item, index) => (
          <IssueItem
            item={item}
            key={index}
            processID={processID}
            responsibles={responsibles}
            processNumber={processNumber}
            refClient={refClient}
            openPDF={(e) => viewPDF(e)}
          />
        ))}
      </div>
    </div>
  );
}

export default IssuesTimeline;
