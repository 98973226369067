import React, { useState } from "react";
import { Col } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";

import { faShip, faPlaneArrival } from "@fortawesome/free-solid-svg-icons";

import EtdEta from "components/EtdEta";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import "./styles.scss";

const CardExternal = (props) => {
  const { itemSelected, handleDelete, myItem } = props;
  const [itemDelete, setItemDelete] = useState(false);

  return (
    <Col
      onClick={myItem}
      className="external-container"
      onMouseOver={() => setItemDelete(true)}
      onMouseLeave={() => setItemDelete(false)}
    >
      {itemDelete && itemSelected.status === "PENDING" && (
        <div className="image-delete" onClick={handleDelete}>
          <DeleteIcon />
        </div>
      )}

      <Col className="shadow-sm card-content" key={itemSelected.status}>
        <Col className="p-0 d-flex justify-content-center align-items-center card-title">
          {itemSelected.mode === "SEA"
            ? "Transporte Marítimo"
            : "Transporte Aéreo"}
          <FontAwesomeIcon
            className="icon-card ml-4"
            icon={itemSelected.mode === "SEA" ? faShip : faPlaneArrival}
          />
        </Col>
        <Col className="card-text p-0">{itemSelected.clientReference}</Col>

        {itemSelected.status !== "PENDING" && (
          <Col className="d-flex flex-row p-0">
            <Col xs={5} className="p-0 return-info">
              <Col>Origem</Col>
              <Col>{itemSelected.origin.name}</Col>
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-center align-items-center return-info p-0"
            >
              <ArrowRightAltIcon />
            </Col>
            <Col xs={5} className="p-0 return-info">
              <Col>Destino</Col>
              <Col>{itemSelected.destination.name}</Col>
            </Col>
          </Col>
        )}

        {itemSelected.status !== "PENDING" && (
          <EtdEta infos={itemSelected.dates} external />
        )}

        <Col className="p-0 pb-2 return-info">
          <Col className="p-0">Número Master</Col>
          <Col className="p-0">{itemSelected.master}</Col>
        </Col>
        <Col className="p-0 pb-2 return-info-ship">
          {itemSelected.dynamicVessel && (
            <Col className="p-0">
              {`A bordo de ${
                itemSelected.dynamicVessel
                  ? itemSelected.dynamicVessel.name
                  : ""
              }`}{" "}
            </Col>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default CardExternal;
