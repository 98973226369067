import { mobileDataAPI } from "services/apiMobile";
import apiConsumer, { newDataAPI, buildURL } from "../apiConsumer";

export const getProcesses = async () => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  };
  return await apiConsumer.get("api/processes/mine", headers);
};

export const delOtherProcess = async (id) => {
  return await newDataAPI.delete(`externalprocesses/${id}`);
};

export const getOtherProcess = async (id) => {
  return await newDataAPI.get(`externalprocesses/employer/${id}`);
};
export const getNewOtherProcess = async (query, sort, page, limit) => {
  return await newDataAPI.get(
    `externalprocesses/${buildURL(query, sort, page, limit ? limit : 999)}`
  );
};
export const externalprocesses = async (data) => {
  return await newDataAPI.post(`externalprocesses`, data);
};

export const getProcessesNew = async (query, sort, page, limit) => {
  return await newDataAPI.get(
    // `cargoprocesses/${buildURL(query, sort, page, 9999)}`
    `cargoprocesses/${buildURL(query, sort, page, limit ? limit : 999)}`
  );
};

export const getUnitProcesses = async (processID) => {
  return await newDataAPI.get(`cargoprocesses/${processID}`);
};
export const getUnitProcessesMobile = async (processID) => {
  return await mobileDataAPI.get(`cargoprocesses/${processID}`);
};
export const resolveIssue = async (processID, issueID) => {
  return await newDataAPI.patch(`cargoprocesses/${processID}/resolve/`, {
    issueID,
  });
};

export const askForCorrection = async (data) => {
  return await newDataAPI.post(`email/send/docs_feedback`, data);
};

export const getLastLocation = async (id) => {
  return await newDataAPI.get(`cargoprocesses/checklocation/${id}`);
};

export const patchProcess = async (processID, data) => {
  return await newDataAPI.patch(`cargoprocesses/${processID}/update/`, data);
};
export const patchExternalProcess = async (processID, data) => {
  return await newDataAPI.patch(`externalprocesses/${processID}`, data);
};

export const getArchiveDocumentsNew = async (guid) => {
  return await newDataAPI.get(`files/documents/${guid}`);
};

export const updateDocument = async (data) => {
  return await apiConsumer.put(
    `api/processes/${data.serviceProviderReferenceId}/issues/${data.documentPendingIssueId}/documents`,
    data
  );
};

export const updatePending = async (data) => {
  return await apiConsumer.put(
    `api/processes/${data.serviceProviderReferenceId}/issues/${data.issueId}/acknowledgement`,
    data
  );
};

export const getDocumentsUpload = async (data) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  };
  return await apiConsumer.get(
    `api/uploaddocuments/${data.serviceProviderExternalReferenceId}`,
    headers
  );
};

export const updateDocumentByUser = async (data) => {
  return await apiConsumer.post(`api/uploaddocuments`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteDocument = async (data) => {
  return await apiConsumer.delete(
    `api/uploaddocuments/${data.serviceProviderExternalReferenceId}/${data.id}`
  );
};

export const getArchieveCargos = async (index, textFilter) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  };
  let dto = index === 1 ? 0 : index - 1;
  return await apiConsumer.get(
    `api/processes/archived?pageIndex=${dto}&textFilter=${textFilter}`,
    headers
  );
};
