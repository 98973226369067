import React from "react";
import { Button, Modal } from "@material-ui/core";

import "./ModalAlert.scss";

const modalAlert = (props) => (
  <div>
    <Modal
      open={props.isOpenModalAlert}
      onClose={() => {
        props.setStateModalAlert({
          isOpenModalAlert: false,
        });
      }}
    >
      <div>
        <div className="modalErro">
          <div className="msgErro">{props.message}</div>
          <div className="btnCloseModalErro">
            <Button
              onClick={() =>
                props.setStateModalAlert({
                  isOpenModalAlert: false,
                })
              }
            >
              Fechar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
);

export default modalAlert;
