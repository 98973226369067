import { createActions, createReducer } from "reduxsauce";

const initialState = {
  currentProcess: [],
  isLoading: false,
};

export const { Types, Creator } = createActions({
  currentSelected: ["currentProcess"],
});

const currentSelected = (state = initialState, action) => ({
  ...state,
  currentProcess: action.currentProcess || state,
});

export default createReducer(initialState, {
  [Types.CURRENT_SELECTED]: currentSelected,
});
