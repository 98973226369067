/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NewTimeline, MapBoxModal, MyTimeline } from "./components";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    minWidth: 100,
  },
  tabAtividade: {
    bottom: 0,
    fontSize: "14px",
    overflowY: "scroll",
    maxHeight: "400px",
  },
  grid: {
    textAlign: "center",
    fontWeight: 600,
    color: "#6B6B6B",
  },
  gridTextRight: {
    textAlign: "right",
    fontWeight: 600,
    color: "#6B6B6B",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  gridTextLeft: {
    textAlign: "left",
    fontWeight: 600,
    color: "#6B6B6B",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  indicator: {
    backgroundColor: "#fdcfca",
  },
  tabSelected: {
    backgroundColor: "#fdcfca",
  },
  dialogTitle: {
    paddingLeft: "10%",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  subTitle: {
    fontSize: 14,
    textAlign: "left",
    paddingTop: "0px",
    paddingLeft: "4.5%",
  },
  dialogContent: {
    margin: "auto !important",
    left: 0,
    height: "100%",
    width: "100%",
    padding: "0px",
  },
  dateBorder: {
    border: "1px solid grey",
    borderRadius: "4px",
    width: "9%",
    background: "#D3D3D3",
    marginLeft: "4.5%",
    alignItems: "center",
  },
  card: {
    padding: "5px",
  },
  mapModal: {
    height: "140px",
    cursor: "pointer",
    marginBottom: "16px",
  },
  root: {
    padding: "2px",
  },
  map: {
    height: "80% !important",
  },
  timeline: {
    transform: "rotate(90deg)",
  },
  timelineContentContainer: {
    textAlign: "left",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(-90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  timelineIcon: {
    transform: "rotate(-90deg)",
  },
}));

function ActivityTab(props) {
  const classes = useStyles();
  const [myLocation, setMyLocation] = React.useState({ lat: -1, lng: -1 });

  const { currentProcess } = useSelector((state) => state.stateSelected);

  const { noMaps } = props;

  function setLocation() {
    let location = { lat: -1, lng: -1 };
    if (
      currentProcess.transportationModality === "Marítimo" ||
      currentProcess.mode === "SEA"
    ) {
      if (currentProcess.onBoard) {
        location.lat =
          currentProcess?.dynamicVessel !== null &&
          currentProcess?.dynamicVessel?.lastLocation
            ? currentProcess?.dynamicVessel?.lastLocation?.latitude
            : -1;
        location.lng =
          currentProcess?.dynamicVessel !== null &&
          currentProcess?.dynamicVessel?.lastLocation
            ? currentProcess?.dynamicVessel?.lastLocation?.longitude
            : -1;
        return location;
      } else {
        location.lat = currentProcess?.dynamicPort
          ? currentProcess?.dynamicPort?.latitude
          : -1;
        location.lng = currentProcess?.dynamicPort
          ? currentProcess?.dynamicPort?.longitude
          : -1;
        return location;
      }
    } else {
      location.lat = currentProcess.dynamicAirport
        ? currentProcess?.dynamicAirport?.latitude
        : -1;
      location.lng = currentProcess?.dynamicAirport
        ? currentProcess?.dynamicAirport?.longitude
        : -1;
      return location;
    }
  }

  useEffect(() => {
    setMyLocation(setLocation());
  }, [currentProcess]);

  return (
    <div>
      {currentProcess && !noMaps && (
        <div
          className={classes.mapModal}
          onClick={() => {
            props.onClose();
            if (document.getElementsByClassName("mapboxgl-map").length === 1) {
              let mapShow = document.getElementById("myDiv");
              if (!mapShow.classList.contains("map-home")) {
                let btn = document.getElementById("btnMapa");
                btn.click();
              }
            }
          }}
        >
          {props.value === 0 &&
            (currentProcess?.konfereResponse ? (
              <MapBoxModal
                value={props.value}
                zoom={4}
                maxZoom={4}
                minZoom={4}
                interactive={false}
                lat={myLocation.lat}
                lng={myLocation.lng}
                operationType={currentProcess?.operationType}
                type={currentProcess?.transportationModality}
              />
            ) : (
              <MapBoxModal
                value={props.value}
                zoom={4}
                maxZoom={4}
                minZoom={4}
                interactive={false}
                lat={currentProcess?.destination?.latitude}
                lng={currentProcess?.destination?.longitude}
                operationType={currentProcess?.operationType}
                type={currentProcess?.transportationModality}
              />
            ))}
        </div>
      )}
      <div className="tabAtividade">
        <MyTimeline />

        <div className="content-newtimeline">
          <NewTimeline />
        </div>
      </div>
    </div>
  );
}

export default ActivityTab;
