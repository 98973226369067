import apiConsumer, { newDataAPI, buildURL } from "../apiConsumer";

export const CreateOrder = async (data) => {
  return await apiConsumer.post("api/neworder", data);
};

export const GetOrders = async () => {
  return await apiConsumer.get("api/neworder", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const CreateOrderNew = async (data) => {
  return await newDataAPI.post("new-orders", data);
};

export const GetOrdersNew = async (query, sort, page, limit) => {
  return await newDataAPI.get(
    `new-orders/${buildURL(query, sort, page, limit ? limit : 9999)}`
  );
};

export const UpdateOrderNew = async (id, data) => {
  return await newDataAPI.patch(`new-orders/${id}`, data);
};

export const DeleteOrderNew = async (id) => {
  return await newDataAPI.delete(`new-orders/${id}`);
};
