import apiConsumer, { newDataAPI } from "../apiConsumer";

export const getDebts = async (index = 0, data) => {
  let dto = index === 1 || index === 0 ? 0 : index - 1;
  return await apiConsumer.post(`/api/debts/mine?pageIndex=${dto}`, data);
};

export const getUrlDebts = async (data) => {
  return await apiConsumer.get(`/api/debts/${data.serviceProviderCargoProcesssId}/${data.externalReferenceId}`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const sentFollowUp = async (debtId) => {
  return await apiConsumer.post(`/api/debts/followup/${debtId}`);
}

export const uploadReceiptDocument = async (data) => {
  return await apiConsumer.post(`api/debts/${data.serviceProviderReferenceId}/receipt/${data.externalReferenceId}`, data.receipt, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export const getDocumentsTemplates = async (data) => {
  return await apiConsumer.get(`/api/documenttemplates/invoice`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const updateDocumentTemplate = async (rawHtmlTemplate, typeTemplate) => {
  return await apiConsumer.put(`/api/documenttemplates/invoice`, { rawHtmlTemplate: rawHtmlTemplate, typeTemplate: typeTemplate });
}


export const requestValeuReview = async (data) => {
  return await newDataAPI.post(`/email/review`, data);
}