import { createActions, createReducer } from "reduxsauce";

const initialState = {
  preferences: {
    document: "hbl",
    hasDocument: true,
    hasPerson: true,
    person: "shipper",
    secondRef: "processNumber",
  },
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  getPreferences: ["preferences"],
});

// Criando os reducers handle
const getPreferences = (state = initialState, action) => ({
  ...state,
  preferences: action.preferences,
});

//Criando Reducer
export default createReducer(initialState, {
  [Types.GET_PREFERENCES]: getPreferences,
});
