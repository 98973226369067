/* eslint-disable array-callback-return */
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/pt-br";
import "./NewTimeline.scss";

function NewTimeline(props) {
  const { currentProcess } = useSelector((state) => state.stateSelected);

  return (
    <div>
      <div>
        <div className="timelineExternal">
          {currentProcess.konfereResponse &&
          currentProcess.konfereResponse.voyages &&
          currentProcess.konfereResponse.voyages.length > 0 ? (
            currentProcess.konfereResponse.voyages.map((item, index) => {
              if (item)
                return (
                  <Fragment key={index}>
                    {item.dates.actualLoad &&
                      item.dates.actualLoad !== null && (
                        <div
                          key={index}
                          className="containerExternal rightExternal"
                        >
                          <p className="text-grey fs-15 mg-0 ml-4">
                            <b>{"EMBARCADO"}</b>
                          </p>
                          <p className="text-grey fs-15 mg-0 newTimeLineText ml-4">
                            <b>{`${item.origin.identifier} - ${item.origin.name}`}</b>
                          </p>
                          <small className="text-grey ml-4 fs-15 mg-0 newTimeLineText d-block">
                            {`${item.voyage} - ${item.vehicle.identifier}`}
                          </small>
                          <small className="text-grey ml-4 ">
                            Atualizado em{" "}
                            {moment
                              .utc(item.dates.actualLoad)
                              .locale("pt-br")
                              .format("DD MMMM YYYY - HH:mm")}
                          </small>
                        </div>
                      )}

                    {item.dates.actualDischarge &&
                      item.dates.actualDischarge !== null && (
                        <div
                          key={index}
                          className="containerExternal rightExternal"
                        >
                          <p className="text-grey fs-15 mg-0 ml-4">
                            <b>{"DESEMBARCADO"}</b>
                          </p>
                          <p className="text-grey fs-15 mg-0 newTimeLineText ml-4">
                            <b>{`${item.origin.identifier} - ${item.origin.name}`}</b>
                          </p>
                          <small className="text-grey fs-15 mg-0 newTimeLineText ml-4 d-block">
                            {`${item.voyage} - ${item.vehicle.identifier}`}
                          </small>
                          <small className="text-grey ml-4">
                            Atualizado em{" "}
                            {moment
                              .utc(item.dates.actualDischarge)
                              .locale("pt-br")
                              .format("DD MMMM YYYY - HH:mm")}
                          </small>
                        </div>
                      )}
                  </Fragment>
                );
            })
          ) : (
            <div>Nenhum status encontrado</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewTimeline;
