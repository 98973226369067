/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SendIcon from "@material-ui/icons/Send";

import "./ChatUser.scss";

import SwipeableViews from "react-swipeable-views";

import { Typography, Tab, Tabs, Box } from "@material-ui/core";

import { ChatContent } from "./components";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "100%",
  },
  content: {
    padding: "5px",
    height: "100%",
  },
  modalWidth: {
    maxWidth: "fit-content",
    overflow: "hidden",
  },
  dialogContent: {
    margin: "auto !important",
    display: "inline !important",
    left: 0,
    height: "100%",
    width: "100%",
    padding: "0px",
  },

  tabContainer: {
    padding: "2px",
    flexGrow: 1,
    height: "100%",
  },
  tabRoot: {
    minWidth: 100,
    textTransform: "none",
    fontSize: "10px",
  },
  textTitle: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
    overflow: "hidden !important",
    flex: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  textSubTitle: {
    fontSize: 12,
    textAlign: "left",
    paddingTop: "0px",
    width: "max-content",
  },
  activeChat: {
    fontSize: 15,
    float: "right",
    height: "11px",
    paddingRight: "0",
    cursor: "pointer",
    color: "#707070",
  },
  indicatorCurrentTabActived: {
    backgroundColor: "transparent",
  },
  currentTabSelected: {
    backgroundColor: "#F2DFFC",
    color: "#6F2C91",
    borderRadius: "5px",
  },
  customIconFavorite: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#FFBFB5" },
    marginTop: "-9px",
    marginLeft: "5px",
    padding: "3px",
    alignSelf: "start",
  },
  dateBorder: {
    border: "1px grey",
    borderRadius: "5px",
    background: "#F1F1F1",
    width: "fit-content",
    textAlign: "center",
    padding: "5px",
  },
  chatButton: {
    width: "130px",
    fontSize: "18px",
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
  wrapper: {
    fontSize: 15,
    fontWeight: 600,
    height: "100%",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      style={{ height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box style={{ paddingTop: "10px", height: "100%" }} p={0}>
        {children}
      </Box>
    </Typography>
  );
};

const ChatUser = (props) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const { process } = props;

  const [newMessage, setNewMessage] = React.useState("");

  /**
   * Executado no carregamento do chat. Para funcionar eu preciso:
   * 1. Verificar se recebi um processo válido;
   * 2. Verficar se este processo tem algum chat criado;
   * 2.1. Caso não preciso criar o chat e adicionar os responsáveis nos chats corretos
   * 2.2. Caso sim preciso preciso verificar se este usuário esta no grupo
   * 2.3. Caso não preciso adicioná-lo ao grupo ?
   * 2.4. Preciso buscar o histórico de mensagens deste chat na applozic
   *
   *
   */
  useEffect(() => {
    // setLoading(true);
    // handleChatGroup(chatActivated);
  }, []);

  // const scrollToBottom = () => {
  //   messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  // };

  // const handleChatGroup = useCallback((group) => {
  //   setMessages([]);
  //   setLoading(true);
  //   setChatActivated(group);
  //   switch (group) {
  //     case "operational":
  //       if (process.supportChat && process.supportChat.operational)
  //         getMessages(process.supportChat.operational.id);
  //       else createGroup("operational");
  //       break;
  //     case "comercial":
  //       if (process.supportChat && process.supportChat.comercial)
  //         getMessages(process.supportChat.comercial.id);
  //       else createGroup("comercial");
  //       break;
  //     case "financial":
  //       if (process.supportChat && process.supportChat.financial)
  //         getMessages(process.supportChat.financial.id);
  //       else createGroup("financial");
  //       break;
  //     default:
  //       break;
  //   }
  // });

  // const getMessages = async (groupid) => {
  //   let { data } = await newGetMessages(groupid, userId);
  //   setGroupId(groupid);
  //   setMessages(data.response.message ? data.response.message.reverse() : null);
  //   setUsersDetails(
  //     data.response.userDetails ? data.response.userDetails : null
  //   );
  //   setLoading(false);
  //   // scrollToBottom();
  // };

  // const createGroup = async (type) => {
  //   let tempGroup = {
  //     groupType: type,
  //     users: [userId],
  //     clientReference: process.clientReference,
  //     processNumber: process.processNumber,
  //     processId: process._id,
  //   };
  //   await newCreateGroup(tempGroup)
  //     .then((r) => {
  //       setNewMessage("");
  //       //setProcess(r.data)
  //       const getWhat =
  //         type === "operational"
  //           ? r.data.supportChat.operational.id
  //           : type === "comercial"
  //           ? r.data.supportChat.comercial.id
  //           : type === "financial"
  //           ? r.data.supportChat.financial.id
  //           : null;
  //       getMessages(getWhat);
  //     })
  //     .catch(() => console.log("Erro ao criar o grupo"));
  // };

  // const getUserName = (email) => {
  //   let user = usersDetails.filter((item) => {
  //     return item.userId === email;
  //   });
  //   return user.length > 0 ? user[0].displayName : null;
  // };

  // const readChat = async (groupId) => {
  //   readConversation(groupId, userId);
  // };

  // const sendMessage = async () => {
  //   let message = {
  //     type: 5,
  //     contentType: 0,
  //     message: newMessage,
  //     groupId: groupId,
  //     source: 1,
  //     read: true,
  //     metadata: {
  //       read: true,
  //       sendUser: userId,
  //       processNumber: process.processNumber,
  //       clientReference: process.clientReference,
  //       chat: chatActivated,
  //     },
  //   };

  //   newSendMessage(message);
  //   await readChat(groupId)
  //     .then((r) => {
  //       setNewMessage("");
  //       showMessages();
  //     })
  //     .catch((e) => console.log("Erro no envio da mensagem"));
  // };

  // useEffect(() => {
  //   if (groupId) showMessages();
  // }, [groupId]);

  // const showMessages = async () => {
  //   try {
  //     const result = await newGetMessages(groupId);

  //     setMessages(
  //       result.data.response.message
  //         ? result.data.response.message.reverse()
  //         : null
  //     );

  //     setUsersDetails(result.data.response.userDetails);

  //     // scrollToBottom();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "2px",
        position: "relative",
      }}
    >
      <Tabs
        variant="fullWidth"
        value={value}
        classes={{
          indicator: classes.indicatorCurrentTabActived,
        }}
        onChange={handleChange}
        aria-label="tabs of modal"
      >
        <Tab
          classes={{
            root: classes.tabRoot,
            selected: classes.currentTabSelected,
            wrapper: classes.wrapper,
          }}
          label="Operacional"
        />
        <Tab
          classes={{
            root: classes.tabRoot,
            selected: classes.currentTabSelected,
            wrapper: classes.wrapper,
          }}
          label="Comercial"
        />
        <Tab
          classes={{
            root: classes.tabRoot,
            selected: classes.currentTabSelected,
            wrapper: classes.wrapper,
          }}
          label="Financeiro"
        />
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        style={{ height: "100%" }}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          className={classes.tabContainer}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <ChatContent
            supportChat={
              (process.supportChat &&
                process.supportChat.operacional &&
                process.supportChat.operacional.id) ||
              ""
            }
            type="operacional"
            active={value}
            index={0}
            processNumber={process.processNumber}
          />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <ChatContent
            supportChat={
              (process.supportChat &&
                process.supportChat.comercial &&
                process.supportChat.comercial.id) ||
              ""
            }
            type="comercial"
            active={value}
            index={1}
            processNumber={process.processNumber}
          />
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          <ChatContent
            supportChat={
              (process.supportChat &&
                process.supportChat.financeiro &&
                process.supportChat.financeiro.id) ||
              ""
            }
            type="financeiro"
            active={value}
            index={2}
            processNumber={process.processNumber}
          />
        </TabPanel>
      </SwipeableViews>

      <div className="card-chat-input">
        <OutlinedInput
          style={{ width: "100%", marginTop: "8px" }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              // sendMessage();
            }
          }}
          autoComplete="off"
          placeholder="Escreva sua mensagem"
          value={newMessage}
          onChange={(e) => {
            e.preventDefault();
            setNewMessage(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={newMessage.length < 1}
                aria-label="toggle password visibility"
                edge="start"
                style={{ color: "#00B2AF" }}
                // onClick={() => sendMessage()}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    </div>
  );

  // return (
  //   <div className="chat-popup" id="myForm">
  //     <form className="form-container">
  //       <div className="body" style={{ height: "100%" }}>
  //         <div className="chat-container">
  //

  //           <div className="card-chat">
  //
  //             <div className="card-container">
  //               <div className="card-messages" id="messages">
  //                 {messages.length === 0 && !loading && !chatNotCreated && (
  //                   <div className="emptyMessage">
  //                     Nenhuma mensagem nesse chat.
  //                   </div>
  //                 )}
  //                 {chatNotCreated && (
  //                   <div className="emptyMessage">
  //                     Chat ainda não disponível para esse perfil.
  //                   </div>
  //                 )}
  //                 {loading && (
  //                   <div className="loadingMessages">
  //                     <CircularProgress color="inherit" />
  //                   </div>
  //                 )}
  //                 {messages.map((item, index) => {
  //                   let labelData;
  //                   let showLabel = true;
  //                   if (
  //                     moment(item.createdAtTime).format("DD/MM/YYYY") ===
  //                     moment().format("DD/MM/YYYY")
  //                   ) {
  //                     labelData = "HOJE";
  //                   } else if (
  //                     moment(item.createdAtTime).format("DD/MM/YYYY") ===
  //                     moment().subtract(1, "d").format("DD/MM/YYYY")
  //                   ) {
  //                     labelData = "ONTEM";
  //                   } else {
  //                     labelData = moment(item.createdAtTime).format(
  //                       "DD [de] MMMM [de] YYYY"
  //                     );
  //                   }
  //                   if (
  //                     index > 0 &&
  //                     moment(item.createdAtTime).format("DD/MM/YYYY") ==
  //                       moment(messages[index - 1].createdAtTime).format(
  //                         "DD/MM/YYYY"
  //                       )
  //                   ) {
  //                     showLabel = false;
  //                   }
  //                   return (
  //                     <div id="messages" key={index}>
  //                       {showLabel && (
  //                         <div className="containerLabelData">
  //                           <div className="labelData">{labelData}</div>
  //                         </div>
  //                       )}
  //                       <div className="containerMessageDefault">
  //                         <div
  //                           className={
  //                             item.metadata && item.metadata.sendUser !== userId
  //                               ? "card-chat-received-messages"
  //                               : "card-chat-sent-messages"
  //                           }
  //                         >
  //                           {item.metadata &&
  //                             item.metadata.sendUser !== userId && (
  //                               <div
  //                                 className={
  //                                   item.metadata &&
  //                                   item.metadata.sendUser !== userId
  //                                     ? "userReceivedMessage"
  //                                     : "userSentMessage"
  //                                 }
  //                               >
  //                                 {item.metadata &&
  //                                 item.metadata.sendUser === userId
  //                                   ? userId
  //                                   : getUserName(
  //                                       item.metadata && item.metadata.sendUser
  //                                         ? item.metadata &&
  //                                             item.metadata.sendUser
  //                                         : item.to
  //                                     )}
  //                               </div>
  //                             )}
  //                           <p>{item.message}</p>
  //                           <span className="time_date">
  //                             {" "}
  //                             {moment.utc(item.createdAtTime).format("HH:mm")}
  //                           </span>{" "}
  //                         </div>
  //                       </div>
  //                       <div ref={messagesEnd}></div>
  //                     </div>
  //                   );
  //                 })}
  //               </div>

  //               <div className="card-chat-input">
  //                 <OutlinedInput
  //                   onKeyPress={(event) => {
  //                     if (event.key === "Enter") {
  //                       event.preventDefault();
  //                       sendMessage();
  //                     }
  //                   }}
  //                   classes={{ root: classes.rootInput }}
  //                   autoComplete="off"
  //                   placeholder="Escreva sua mensagem"
  //                   disabled={chatNotCreated}
  //                   value={newMessage}
  //                   onChange={(e) => {
  //                     e.preventDefault();
  //                     setNewMessage(e.target.value);
  //                   }}
  //                   endAdornment={
  //                     <InputAdornment position="end">
  //                       <IconButton
  //                         disabled={newMessage.length < 1}
  //                         aria-label="toggle password visibility"
  //                         edge="start"
  //                         style={{ color: "#00B2AF" }}
  //                         onClick={() => sendMessage()}
  //                       >
  //                         <SendIcon />
  //                       </IconButton>
  //                     </InputAdornment>
  //                   }
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </form>
  //   </div>
  // );
};

export default ChatUser;
