/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Types as preferencesTypes } from "store/reducers/settingsReducer";
import { Types as currentProcessTypes } from "store/reducers/selectedReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {
  faShip,
  faPlaneArrival,
  faCubes,
  faStar as solidStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faFolderOpen,
  faStar,
} from "@fortawesome/free-regular-svg-icons";

import { RetrieveUserInformation } from "services/Auth/usermanager.service";

import "./styles.scss";
import { checkIssues } from "util/useIssues";

import EtdEta from "components/EtdEta";
import { patchProcess } from "services/Process/process.service";
import { Tooltip } from "@material-ui/core";

const CardNext = (props) => {
  const { myItem, itemSelected, settings } = props;

  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.stateSettings);

  const [pendingIssues, setPendingIssues] = React.useState(false);
  const [favorited, setFavorited] = useState(false);

  useEffect(() => {
    if (settings) {
      dispatch({
        type: preferencesTypes.GET_PREFERENCES,
        preferences: settings,
      });
    }
  }, [settings]);

  const setFavorite = async () => {
    const user = RetrieveUserInformation()._id;
    let tempArray =
      itemSelected && itemSelected.MyFavorited ? itemSelected.MyFavorited : [];

    let idx = await tempArray.indexOf(user);
    if (idx !== -1) {
      tempArray.splice(idx, 1);
      await patchProcess(itemSelected._id, {
        // favorited: !itemSelected.favorited,
        myFavorited: tempArray,
      });
    } else {
      tempArray.push(user);
      await patchProcess(itemSelected._id, {
        // favorited: !itemSelected.favorited,
        myFavorited: tempArray,
      });
    }

    await patchProcess(itemSelected._id, {
      // favorited: !itemSelected.favorited,
      myFavorited: tempArray,
    });

    // await data
    //   .filter((item) => item._id === itemSelected._id)
    //   .map((i) => {
    //     return { ...i, favorited: !itemSelected.favorited };
    //   });

    setFavorited(!favorited);
  };

  useEffect(() => {
    if (itemSelected.myFavorited) {
      if (itemSelected.myFavorited.includes(RetrieveUserInformation()._id)) {
        setFavorited(true);
      } else {
        setFavorited(false);
      }
    }
  }, [itemSelected]);

  useEffect(() => {
    function getIssues() {
      if (!itemSelected.issues || itemSelected.issues.length < 1) return false;
      else {
        return (
          itemSelected.issues.filter((issue) => {
            return (
              issue.solved === false &&
              checkIssues(itemSelected.issues.createdAt)
            );
          }).length > 0
        );
      }
    }
    setPendingIssues(getIssues());
  }, [itemSelected]);

  return (
    <Col className="shadow-sm card-content-next">
      {favorited ? (
        <div className="favorited" onClick={setFavorite}>
          <FontAwesomeIcon
            style={{ color: "yellow" }}
            title="Favorito"
            icon={solidStar}
          />
        </div>
      ) : (
        <div className="favorite" onClick={setFavorite}>
          <FontAwesomeIcon
            className="icon-card"
            style={{ color: "yellow" }}
            title="Favoritar"
            icon={faStar}
          />
        </div>
      )}
      <Col className="p-0 d-flex flex-row align-items-center pt-1 next-header">
        <Tooltip title={itemSelected.microStatus} placement="top" arrow>
          <Col
            xs={10}
            className={
              pendingIssues
                ? "d-flex justify-content-center align-items-center next-pendent ml-3"
                : "d-flex justify-content-center align-items-center next-payment ml-3"
            }
          >
            <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faClock} />
            <span>{itemSelected && itemSelected.microStatus}</span>
          </Col>
        </Tooltip>
        <Col
          xs={2}
          className="d-flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon
            className="icon-card"
            icon={
              itemSelected && itemSelected.transportationModality === "Aéreo"
                ? faPlaneArrival
                : faShip
            }
          />
        </Col>
      </Col>
      <Col
        className="p-0"
        onClick={() => {
          myItem();
          dispatch({
            type: currentProcessTypes.CURRENT_SELECTED,
            currentProcess: itemSelected,
          });
        }}
      >
        <Col className="card-text">
          {itemSelected.clientReference
            ? itemSelected.clientReference + " - "
            : "" || " -"}
          {preferences.secondRef && preferences.secondRef === "processNumber"
            ? itemSelected.processNumber
            : preferences.secondRef && preferences.secondRef === "incoterm"
            ? itemSelected.boarding.knowledge.incoterm
            : ""}{" "}
        </Col>
        <Col className="d-flex flex-row p-0">
          <Col xs={5} className="p-0 return-info">
            <Col className="card-info-title">Origem</Col>
            <Col className="card-info-text-details">
              {itemSelected && itemSelected.boarding.origin}
            </Col>
          </Col>
          <Col
            xs={2}
            className="d-flex justify-content-center align-items-center return-info p-0"
          >
            <ArrowRightAltIcon />
          </Col>
          <Col xs={5} className="p-0 return-info">
            <Col className="card-info-title">Destino</Col>
            <Col className="card-info-text-details">
              {itemSelected &&
                (itemSelected.boarding.destinationFinal ||
                  itemSelected.boarding.destination)}
            </Col>
          </Col>
        </Col>

        {(itemSelected.boarding.estimatedCargoLoad ||
          itemSelected.boarding.preBooking) && (
          <EtdEta infos={itemSelected.boarding} />
        )}

        <Col className="p-0 return-info">
          {preferences &&
          preferences.hasPerson &&
          preferences.person === "shipper" ? (
            <Col className="d-flex flex-column mt-2 card-info-title">
              <span className="person">{preferences.person}</span>
              <span>{itemSelected.shipper && itemSelected.shipper.name}</span>
            </Col>
          ) : preferences.hasPerson && preferences.person === "consignee" ? (
            <Col className="d-flex flex-column mt-2 card-info-title">
              <span className="person">{preferences.person}</span>
              <span>
                {itemSelected.consignee && itemSelected.consignee.name}
              </span>
            </Col>
          ) : preferences.hasPerson && preferences.person === "notify" ? (
            <Col className="d-flex flex-column mt-2 card-info-title">
              <span className="person">{preferences.person}</span>
              <span>{itemSelected.notify && itemSelected.notify.name}</span>
            </Col>
          ) : null}
        </Col>

        <Col className="p-0 return-info">
          {preferences &&
          preferences.hasDocument &&
          preferences.document === "mbl" &&
          itemSelected.boarding.knowledge.blMaster ? (
            <Col className="card-info-title">
              <span className="person">MBL</span>
              <span>
                {itemSelected?.boarding?.knowledge?.blMaster
                  ? itemSelected.boarding.knowledge.blMaster.identification
                  : null}
              </span>
            </Col>
          ) : preferences.hasDocument &&
            preferences.document === "hbl" &&
            itemSelected?.boarding?.knowledge?.bls?.length > 0 ? (
            <Col className="card-info-title">
              <span className="person">HBL</span>
              <span>
                {itemSelected?.boarding?.knowledge?.bls?.length > 0
                  ? itemSelected.boarding.knowledge.bls[0].identification
                  : null}
              </span>
            </Col>
          ) : preferences.hasDocument &&
            preferences.document === "ceh" &&
            itemSelected?.boarding?.knowledge?.bls?.length > 0 ? (
            <Col className="card-info-title">
              <span className="person">CE House</span>
              <span>
                {itemSelected?.boarding?.knowledge?.bls?.length > 0
                  ? itemSelected.boarding.knowledge.bls[0].deconsolidation
                  : null}
              </span>
            </Col>
          ) : null}
        </Col>

        {itemSelected &&
          itemSelected.kanbanStatus === "Em trânsito" &&
          itemSelected.onBoard &&
          itemSelected.dynamicVessel && (
            <Col className="p-0 return-info">
              <Col className="card-info-title">
                A bordo de {itemSelected.dynamicVessel.name}
              </Col>
            </Col>
          )}

        {itemSelected && itemSelected.originAgent && (
          <Col className="p-0 return-info">
            <Col className="d-flex flex-column mt-2 card-info-title">
              <span className="person">Agente de origem:</span>
              <span>{itemSelected.originAgent}</span>
            </Col>
          </Col>
        )}

        {itemSelected?.analist?.length > 0 && (
          <Col className="p-0 return-info">
            <Col className="d-flex flex-column mt-2 card-info-title">
              <span className="person">Analista</span>
              {itemSelected.analist.map((item) => (
                <span key={"span_" + item.name}>{item.name}</span>
              ))}
            </Col>
          </Col>
        )}

        <Col className="d-flex flex-row justify-content-between align-items-center p-0">
          <Col className="card-info-icons d-flex  align-items-center pr-0 mt-2">
            <FontAwesomeIcon
              className="icon-card"
              style={{
                marginRight: "8px",
                color: "#5b5b5b",
                fontSize: 16,
              }}
              icon={faClock}
            />
            {itemSelected && itemSelected.boarding.transitTimeInDays} dias
          </Col>
          <Col className="d-flex justify-content-center align-items-center card-info-icons pr-0 mt-2 ">
            <FontAwesomeIcon
              className="icon-card"
              style={{
                marginRight: "8px",
                color: "#5b5b5b",
                fontSize: 16,
              }}
              icon={faCubes}
            />
            {(itemSelected &&
              itemSelected?.boarding &&
              itemSelected.boarding.knowledge &&
              itemSelected.boarding.knowledge.bls[0]?.cargoItems?.length) ||
              0}
          </Col>
          <Col className="d-flex justify-content-end align-items-center pb-1 card-info-icons pr-0 mt-2">
            <FontAwesomeIcon
              className="icon-card"
              style={{
                marginRight: "8px",
                color: "#5b5b5b",
                fontSize: 16,
              }}
              icon={faFolderOpen}
            />
            {itemSelected && (itemSelected.documents.length || 0)}
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default CardNext;
