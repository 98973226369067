import { newDataAPI } from "../apiConsumer";

//Este endpoint busca o chat por processo

export const getProcessChat = async (id) => {
  return await newDataAPI.get(`chats/${id}`);
};

// Este endpoint busca a lista de chats por usuário

export const getListChat = async (id) => {
  return await newDataAPI.get(`chats/${id}/list`);
};

// Pesquisa o processo pelo processNumber e  caso tenha chat criado retorna para o usuário do contrário ele cria um novo chat.
// necessário passar o processNumber e o tipo do chat.

export const searchChat = async (item, role) => {
  return await newDataAPI.post(`chats/`, {
    processNumber: item,
    type: role,
  });
};

// Envia uma nova mensagem no chat

export const newMessage = async (idChat, data) => {
  return await newDataAPI.post(`chats/${idChat}/message`, data);
};

//Novas chamadas do Chat para a API da CP intermediar o processo
export const newGetMessages = async (groupId, userId) => {
  return await newDataAPI.get(`/chat/${groupId}/${userId}`);
};

export const getMessagesByUser = async (callback) => {
  await window.Applozic.ALApiService.getMessages({
    data: {
      startIndex: 0,
      mainPageSize: 100,
      pageSize: 50,
    },
    success: async function (response) {
      callback(response.data);
      console.log("GET MESSAGE BY USER =>", response.data);
    },
    error: function (error) {
      console.error(error);
      getMessagesByUser(callback);

      console.error("GET MESSAGE BY USER ERROR =>", error, callback);
    },
  });
};
