import { combineReducers } from "redux";

import processReducer from "./processReducer";
import userReducer from "./userReducer";
import modalReducer from "./modalReducer";
import menuReducer from "./menuReducer";
import filterReducer from "./filterReducer";
import companyReducer from "./companyReducer";
import notificationReducer from "./notificationReducer";
import chatReducer from "./chatReducer";
import settingsReducer from "./settingsReducer";
import selectedReducer from "./selectedReducer";
import openMapsReducer from "./openMapsReducer";
import notifyReducer from "./notifyReducer";
import filteredReducer from "./filteredReducer";
import administratorReducer from "./administratorReducer";
import alertReducer from "./alertReducer";
import documentsReducer from "./documentsReducer";
import formErrorsReducer from "./formErrorsReducer";

const rootReducer = combineReducers({
  stateProcess: processReducer,
  stateModal: modalReducer,
  stateUser: userReducer,
  stateMenu: menuReducer,
  stateFilter: filterReducer,
  stateCompany: companyReducer,
  stateNotification: notificationReducer,
  stateChat: chatReducer,
  stateSettings: settingsReducer,
  stateSelected: selectedReducer,
  stateShowMaps: openMapsReducer,
  stateNotify: notifyReducer,
  stateFiltered: filteredReducer,
  stateAdministrator: administratorReducer,
  stateAlert: alertReducer,
  stateDocuments: documentsReducer,
  stateFormErrors: formErrorsReducer,
});

export default rootReducer;
