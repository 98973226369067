/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  OutlinedInput,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { Types as companyTypes } from "store/reducers/companyReducer";

import { patchProcess } from "services/Process/process.service";

import "./BrokerSelect.scss";
import { Alert } from "@material-ui/lab";
import { getCompany, getCompanies } from "services/Companies/companies.service";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";

const BrokerSelect = () => {
  const dispatch = useDispatch();
  const { currentCompany } = useSelector((state) => state.stateCompany);
  const { currentProcess } = useSelector((state) => state.stateSelected);
  const [listBrokers, setListBrokers] = React.useState([]);
  const [brokers, setBrokers] = React.useState([]);
  const [broker, setBroker] = React.useState(null);

  const [profiles, setProfiles] = React.useState([]);
  // const [broker, setBroker] = React.useState(
  //   currentProcess && currentProcess.broker ? currentProcess.broker : "-1"
  // );
  const [alert, setAlert] = React.useState({
    severity: "",
    message: "",
    show: false,
  });

  const getCurrentCompany = async () => {
    try {
      const result = await getCompany(RetrieveUserInformation().employer._id);

      dispatch({
        type: companyTypes.CURRENT_COMPANY_REQUEST,
        currentCompany: result.data,
      });

      setBrokers(result.data.brokers);

      setProfiles(result.data.profiles);

      changeBroker();
    } catch (error) {
      console.log(error);
    }
  };

  const changeBroker = async () => {
    if (await currentProcess.broker) {
      setBroker(currentProcess.broker);
    }
  };

  useEffect(() => {
    getCurrentCompany();
    getBrokers();
  }, []);

  /**
   * Carrega os usuários que possuem cadastro do tipo Broker no banco de dados.
   */
  const getBrokers = async () => {
    let result;
    let criteria = [];
    criteria.push({
      name: { $exists: true },
    });
    criteria.push({
      profiles: { $in: "broker" },
    });
    result = await getCompanies(
      criteria.length > 0 && JSON.stringify({ $and: criteria })
    );
    setListBrokers(result.data.items);
  };

  const getBrokerName = (item) => {
    let name = listBrokers.filter((f) => f._id === item).map((i) => i.name);

    return name[0];
  };

  const updateBroker = async () => {
    patchProcess(currentProcess._id, {
      broker: broker !== "-1" ? broker : null,
    })
      .then((r) =>
        setAlert({
          ...alert,
          severity: "success",
          message: "Alteração de despachante concluída!",
          show: true,
        })
      )
      .catch((e) =>
        setAlert({
          ...alert,
          severity: "error",
          message: "Não foi possível processar sua solicitação",
          show: true,
        })
      );
  };

  return (
    <div className="broker-container">
      {currentCompany && (
        <div className="broker-div">
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={broker !== null ? broker : "-1"}
              disabled={
                profiles.length > 0 &&
                profiles.includes("client") &&
                currentProcess.client === currentCompany._id
                  ? true
                  : false
              }
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={(event) => setBroker(event.target.value)}
              style={{ color: "#ada5b1" }}
              input={<OutlinedInput />}
            >
              <MenuItem value="-1">Sem despachante selecionado</MenuItem>
              {profiles.includes("broker")
                ? listBrokers &&
                  listBrokers.length > 0 &&
                  listBrokers.map((b) => (
                    <MenuItem key={b._id} value={b._id}>
                      {b.name}
                    </MenuItem>
                  ))
                : brokers &&
                  brokers.length > 0 &&
                  brokers.map((b) => (
                    <MenuItem key={b.person} value={b.person}>
                      {getBrokerName(b.person)}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          {profiles.length > 0 &&
            profiles.includes("client") &&
            currentProcess?.client?._id === currentCompany?._id && (
              <Button
                className="broker-submit"
                variant="contained"
                size="medium"
                onClick={() => updateBroker()}
              >
                Confirmar
              </Button>
            )}
        </div>
      )}

      {alert.show && (
        <Alert
          onClose={() => setAlert({ ...alert, show: false })}
          style={{ marginTop: "12px" }}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      )}
    </div>
  );
};

export default BrokerSelect;
