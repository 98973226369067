import React, { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";

const content = {
  overflow: "auto",
  flex: "1 1 auto",
};

const Public = (props) => {
  const { route } = props;

  return (
    <main style={content}>
      <Suspense fallback={<ProgressBar striped />}>
        {renderRoutes(route.routes)}
      </Suspense>
    </main>
  );
};

Public.propTypes = {
  route: PropTypes.object,
};

export default Public;
