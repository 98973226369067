import React from "react";
import { Button } from "@material-ui/core";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";

import "./Paginator.scss";

const Paginator = (props) => {
  const { pagination } = props;

  return (
    <div className="paginator">
      <Button
        onClick={() => props.onClick((pagination.page = 1))}
        disabled={!pagination.previous}
      >
        {" "}
        <FastRewindIcon fontSize={"large"} />
      </Button>
      <Button
        style={{ marginRight: "1px" }}
        disabled={!pagination.previous}
        onClick={() => props.onClick((pagination.page -= 1))}
      >
        <SkipPreviousIcon fontSize={"large"} />
      </Button>
      <div className="infos">
        {pagination.page}
        <span>
          {" "}
          de{" "}
          {Math.ceil(pagination.total_items / 10) > 1
            ? Math.ceil(pagination.total_items / 10)
            : 1}
        </span>
      </div>
      <Button
        onClick={() => props.onClick((pagination.page += 1))}
        disabled={!pagination.next}
      >
        <SkipNextIcon fontSize={"large"} />
      </Button>
      <Button
        onClick={() =>
          props.onClick(
            (pagination.page = Math.ceil(pagination.total_items / 10))
          )
        }
        disabled={!pagination.next}
      >
        <FastForwardIcon fontSize={"large"} />
      </Button>
    </div>
  );
};

export default Paginator;
