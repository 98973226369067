import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";

import "../../DetailTab.scss";

const BoardingDetails = (props) => {
  const {
    boarding,
    shipper,
    notify,
    consignee,
    cargoVoyageName,
    cargocraftName,
  } = props;

  return (
    <React.Fragment>
      {boarding && boarding.origin ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">Origem</b>
            <br />
            <span className="subtitle-grid">{boarding.origin}</span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {boarding && boarding.destination ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">Destino</b>
            <br />
            <span className="subtitle-grid">{boarding.destination}</span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      <Grid xs={3} item={true}>
        <Typography color="textSecondary">
          <b className="title-grid">Navio</b>
          <br />
          <span className="subtitle-grid">{cargocraftName || " - "}</span>
        </Typography>
      </Grid>

      <Grid xs={3} item={true}>
        <Typography color="textSecondary">
          <b className="title-grid">Viagem</b>
          <br />
          <span className="subtitle-grid">{cargoVoyageName || " - "}</span>
        </Typography>
      </Grid>

      {shipper ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid"> Shipper</b>
            <br />
            <Tooltip title={shipper} placement="top" arrow>
              <span className="subtitle-grid">{shipper}</span>
            </Tooltip>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {consignee ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid"> Consignee</b>
            <br />
            <Tooltip title={consignee} placement="top" arrow>
              <span className="subtitle-grid span-text">{consignee}</span>
            </Tooltip>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {notify ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid"> Notify</b>
            <br />
            <Tooltip title={notify} placement="top" arrow>
              <span className="subtitle-grid span-text">{notify}</span>
            </Tooltip>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {boarding && boarding.transitTimeInDays ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">TT in days</b>
            <br />
            <span className="subtitle-grid">{boarding.transitTimeInDays}</span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default BoardingDetails;
