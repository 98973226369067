import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

const KnowledgeDetails = (props) => {
  const { classes } = props;

  const { currentProcess } = useSelector((state) => state.stateSelected);

  return (
    <React.Fragment>
      {currentProcess.konfereResponse && (
        <React.Fragment>
          <React.Fragment>
            <Typography className={classes.subtitle1}>Booking</Typography>
            <Grid xs={12} item={true}>
              <Typography color="textSecondary">
                {currentProcess.konfereResponse.booking}
              </Typography>
            </Grid>
          </React.Fragment>

          {currentProcess.konfereResponse && (
            <React.Fragment>
              <Typography className={classes.subtitle1}>Containers</Typography>
              {currentProcess.konfereResponse.containers.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid xs={3} item={true}>
                    <Typography
                      color="textSecondary"
                      className="d-flex flex-row align-items-center"
                    >
                      <b className="subtitle-grid"> Nº</b>
                      <span className="subtitle-grid ml-1">{item}</span>
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default KnowledgeDetails;
