import { createActions, createReducer } from "reduxsauce";

const initialState = {
  currentCNPJ: "",
  isLoading: false,
  error: {},
};

export const { Types, Creator } = createActions({
  currentRequest: ["isLoading"],
  currentSuccess: ["currentCNPJ", "isLoading"],
  currentFailure: ["error", "isLoading"],
});

const currentRequest = (state = initialState, action) => ({
  ...state,
  isLoading: true,
});

const currentSuccess = (state = initialState, action) => ({
  ...state,
  currentCNPJ: action.currentCNPJ,
  isLoading: false,
});

const currentFailure = (state = initialState, action) => ({
  ...state,
  error: action.error || state,
  isLoading: false,
});

export default createReducer(initialState, {
  [Types.CURRENT_REQUEST]: currentRequest,
  [Types.CURRENT_SUCCESS]: currentSuccess,
  [Types.CURRENT_FAILURE]: currentFailure,
});
