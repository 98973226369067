import { createActions, createReducer } from "reduxsauce";

const initialState = {
  notify: [],
  isLoading: false,
};

// Cria os action types e creators

export const { Types, Creators } = createActions({
  notifyRequest: ["isLoading"],
  notifySuccess: ["notify", "isLoading"],
  notifyFailure: ["notify", "isLoading"],
});

// Criando os reducers handle

const notifyRequest = (state = initialState, action) => ({
  ...state,
  isLoading: true,
});

const notifySuccess = (state = initialState, action) => ({
  ...state,
  notify: action.notify,
  isLoading: false,
});

const notifyFailure = (state = initialState, action) => ({
  ...state,
  notify: action.notify,
  isLoading: false,
});

// Criando reducer

export default createReducer(initialState, {
  [Types.NOTIFY_REQUEST]: notifyRequest,
  [Types.NOTIFY_SUCCESS]: notifySuccess,
  [Types.NOTIFY_FAILURE]: notifyFailure,
});
