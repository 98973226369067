import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// import { CargoItems } from "./components";
import { CargoItems } from "..";

const KnowledgeDetails = (props) => {
  const { knowledge, classes } = props;

  return (
    <React.Fragment>
      {knowledge && (
        <React.Fragment>
          <React.Fragment>
            <Typography className={classes.subtitle1}>BL Master</Typography>
            <Grid xs={3} item={true}>
              <Typography color="textSecondary" style={{ paddingLeft: "6px" }}>
                <b className="title-grid">Nº</b>
                <br />
                <span className="subtitle-grid">
                  {knowledge.blMaster
                    ? knowledge.blMaster.identification
                    : " - "}
                </span>
              </Typography>
            </Grid>

            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid">CE Master</b>
                <br />
                <span className="subtitle-grid">
                  {knowledge.blMaster
                    ? knowledge.blMaster.ceIdentification
                    : " - "}
                </span>
              </Typography>
            </Grid>
            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid"> Incoterm</b>
                <br />
                <span className="subtitle-grid">{knowledge.incoterm}</span>
              </Typography>
            </Grid>
          </React.Fragment>

          {knowledge.bls && knowledge.bls.length > 0 && (
            <React.Fragment>
              <Typography className={classes.subtitle1}>BL House</Typography>
              {knowledge.bls.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid xs={3} item={true}>
                    <Typography
                      color="textSecondary"
                      style={{ paddingLeft: "6px" }}
                    >
                      <b className="title-grid"> Nº</b>
                      <br />
                      <span className="subtitle-grid">
                        {item.identification}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={3} item={true}>
                    <Typography color="textSecondary">
                      <b className="title-grid"> CE House</b>
                      <br />
                      <span className="subtitle-grid">
                        {item.deconsolidation}
                      </span>
                    </Typography>
                  </Grid>
                  {item.cargoItems && item.cargoItems.length > 0 && (
                    <CargoItems
                      cargoItems={item.cargoItems}
                      classes={classes}
                    />
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default KnowledgeDetails;
