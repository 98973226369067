/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Dropdown } from "react-bootstrap";
import { CircularProgress, Backdrop } from "@material-ui/core";

import { KanbanMenu, AlertConfirmation } from "components";
import { CardExternal, CardNext } from "./cards";

import { delOtherProcess } from "services/Process/process.service";

import { Types as processTypes } from "store/reducers/processReducer";
import { Types as alertTypes } from "store/reducers/alertReducer";

import "./styles.scss";

import { useHistory } from "react-router";

const Kanban = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    currentPreBoarding,
    currentOnBoarding,
    currentFinish,
    currentAwait,
    isLoading,
  } = useSelector((state) => state.stateProcess);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const { handleOpenExternalDetails } = props;

  const handleOrdinationExternal = (value) => {
    let array = currentAwait;

    if (value === "master") {
      array.sort(function (a, b) {
        return a.master < b.master ? -1 : a.master > b.master ? 1 : 0;
      });

      dispatch({
        type: processTypes.CURRENT_AWAIT_SUCCESS,
        currentAwait: array,
      });
    }
  };

  const handleOrdinationNext = (value, process) => {
    if (process === "pre") {
      let array = currentPreBoarding;

      if (value === "processNumber") {
        array.sort(function (a, b) {
          return a.processNumber < b.processNumber
            ? -1
            : a.processNumber > b.processNumber
            ? 1
            : 0;
        });
      }

      if (value === "microstatus") {
        array.sort(function (a, b) {
          return a.microStatus < b.microStatus
            ? -1
            : a.microStatus > b.microStatus
            ? 1
            : 0;
        });
      }
      if (value === "shipper") {
        array.sort(function (a, b) {
          return a.shipper.name < b.shipper.name
            ? -1
            : a.shipper.name > b.shipper.name
            ? 1
            : 0;
        });
      }

      if (value === "origin") {
        array.sort(function (a, b) {
          return a.boarding.origin < b.boarding.origin
            ? -1
            : a.boarding.origin > b.boarding.origin
            ? 1
            : 0;
        });
      }
      if (value === "destination") {
        array.sort(function (a, b) {
          return (a.boarding.destinationFinal || a.boarding.destination) <
            (b.boarding.destinationFinal || b.boarding.destination)
            ? -1
            : (a.boarding.destinationFinal || a.boarding.destination) >
              (b.boarding.destinationFinal || b.boarding.destination)
            ? 1
            : 0;
        });
      }

      if (value === "reference") {
        array.sort(function (a, b) {
          return a.clientReference < b.clientReference
            ? -1
            : a.clientReference > b.clientReference
            ? 1
            : 0;
        });
      }

      dispatch({
        type: processTypes.CURRENT_PRE_BOARDING_SUCCESS,
        currentPreBoarding: array,
      });
    }

    if (process === "on") {
      let array = currentOnBoarding;

      if (value === "processNumber") {
        array.sort(function (a, b) {
          return a.processNumber < b.processNumber
            ? -1
            : a.processNumber > b.processNumber
            ? 1
            : 0;
        });
      }

      if (value === "microstatus") {
        array.sort(function (a, b) {
          return a.microStatus < b.microStatus
            ? -1
            : a.microStatus > b.microStatus
            ? 1
            : 0;
        });
      }
      if (value === "shipper") {
        array.sort(function (a, b) {
          return a.shipper.name < b.shipper.name
            ? -1
            : a.shipper.name > b.shipper.name
            ? 1
            : 0;
        });
      }

      if (value === "origin") {
        array.sort(function (a, b) {
          return a.boarding.origin < b.boarding.origin
            ? -1
            : a.boarding.origin > b.boarding.origin
            ? 1
            : 0;
        });
      }
      if (value === "destination") {
        array.sort(function (a, b) {
          return (a.boarding.destinationFinal || a.boarding.destination) <
            (b.boarding.destinationFinal || b.boarding.destination)
            ? -1
            : (a.boarding.destinationFinal || a.boarding.destination) >
              (b.boarding.destinationFinal || b.boarding.destination)
            ? 1
            : 0;
        });
      }

      if (value === "reference") {
        array.sort(function (a, b) {
          return a.clientReference < b.clientReference
            ? -1
            : a.clientReference > b.clientReference
            ? 1
            : 0;
        });
      }
      if (value === "etd") {
        array.sort(function (a, b) {
          return a.boarding.cargoLoad - b.boarding.cargoLoad;
        });

        // array.sort(function (a, b) {
        //   return moment(a.boarding.cargoLoad, "YYYY-MM-DD").format(
        //     "DD-MM-YYYY"
        //   ) < moment(b.boarding.cargoLoad, "YYYY-MM-DD").format("DD-MM-YYYY")
        //     ? -1
        //     : moment(a.boarding.cargoLoad, "YYYY-MM-DD").format("DD-MM-YYYY") >
        //       moment(b.boarding.cargoLoad, "YYYY-MM-DD").format("DD-MM-YYYY")
        //     ? 1
        //     : 0;
        // });
      }

      dispatch({
        type: processTypes.CURRENT_ON_BOARDING_SUCCESS,
        currentOnBoarding: array,
      });
    }
  };
  const handleDeleteExternalProcess = async () => {
    try {
      await delOtherProcess(idDelete);
      setConfirmDelete(false);
      const arr = currentAwait;
      let idx = arr.findIndex((item) => item._id === idDelete);
      // eslint-disable-next-line eqeqeq
      if (idx != -1) {
        arr.splice(idx, 1);
      }
      dispatch({
        type: processTypes.CURRENT_AWAIT_SUCCESS,
        currentAwait: arr,
      });
      dispatch({
        type: alertTypes.SHOW_ALERT,
        open: true,
        message: "Processo externo excluído com sucesso !",
        serverity: "success",
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: alertTypes.SHOW_ALERT,
        open: true,
        message: "Erro ao excluir processo !",
        serverity: "error",
      });
    }
  };

  return (
    <Col className="kanbanContainer">
      {isLoading && (
        <Backdrop className="loading" open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {confirmDelete && (
        <AlertConfirmation
          open={confirmDelete}
          title="Excluir Processo"
          text={"Deseja excluir esse processo ?"}
          fnConfirm={() => handleDeleteExternalProcess(idDelete)}
          onClose={() => setConfirmDelete(false)}
        />
      )}

      {confirmDelete && (
        <AlertConfirmation
          open={confirmDelete}
          title="Excluir Processo"
          text="Deseja realmente excluir este processo?"
          onClose={() => setConfirmDelete(false)}
          fnConfirm={() => handleDeleteExternalProcess(idDelete)}
        />
      )}

      <Row className="flex-nowrap">
        {currentAwait.length > 0 && (
          <Col className="p-0 ml-1" xs="auto">
            <Col className="card-next-header-title">
              <Col className="d-flex justify-content-between align-items-center">
                Aguardando Atualização{" "}
                <KanbanMenu>
                  <Dropdown.Item
                    onClick={() => handleOrdinationExternal("master")}
                  >
                    Master
                  </Dropdown.Item>
                </KanbanMenu>
              </Col>

              <Col>{currentAwait.length}</Col>
            </Col>
            <Col className="card-scroll p-0 pr-2 ">
              {currentAwait.length > 0 &&
                currentAwait.map((up, index) => (
                  <CardExternal
                    itemSelected={up}
                    handleDelete={() => {
                      setIdDelete(up._id);
                      setConfirmDelete(true);
                    }}
                    key={index}
                  />
                ))}
            </Col>
          </Col>
        )}

        {/* PRE EMBARQUE */}

        <Col>
          <Col className="card-next-header-title">
            <Col className="d-flex justify-content-between">
              Pré-embarque{" "}
              <KanbanMenu>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("processNumber", "pre")}
                >
                  Número do processo
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("microstatus", "pre")}
                >
                  Micro status
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("shipper", "pre")}
                >
                  Shipper
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("origin", "pre")}
                >
                  Origem
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("destination", "pre")}
                >
                  Destino
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => handleOrdinationNext("etd", "pre")}
                >
                  ETD
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("eta", "pre")}
                >
                  ETA
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("reference", "pre")}
                >
                  Referência do Cliente
                </Dropdown.Item>
              </KanbanMenu>
            </Col>
            <Col>{currentPreBoarding.length}</Col>
          </Col>
          {/* FIM DO HEADER  */}
          <Col className="card-scroll p-0 pr-2">
            {currentPreBoarding.length > 0 ? (
              <Col className="p-0">
                {currentPreBoarding.map((pre, index) => {
                  if (pre.microStatus)
                    return (
                      <CardNext
                        myItem={() => {
                          history.push(`/process-details/${pre._id}`);
                        }}
                        key={"pre_" + pre._id}
                        itemSelected={pre}
                      />
                    );

                  return (
                    <CardExternal
                      itemSelected={pre}
                      key={index}
                      myItem={() => handleOpenExternalDetails(pre)}
                    />
                  );
                })}
              </Col>
            ) : (
              <Col className="empty-text">
                <span>Nenhum registro para ser exibido</span>
              </Col>
            )}
          </Col>
        </Col>

        {/* EM TRANSITO  */}

        <Col>
          <Col className="card-next-header-title">
            <Col className="d-flex justify-content-between">
              Em trânsito{" "}
              <KanbanMenu>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("processNumber", "on")}
                >
                  Número do processo
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("microstatus", "on")}
                >
                  Micro status
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("shipper", "on")}
                >
                  Shipper
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("origin", "on")}
                >
                  Origem
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("destination", "on")}
                >
                  Destino
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => handleOrdinationNext("etd", "on")}
                >
                  ETD
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("eta", "on")}
                >
                  ETA
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("reference", "on")}
                >
                  Referência do Cliente
                </Dropdown.Item>
              </KanbanMenu>
            </Col>
            <Col>{currentOnBoarding.length}</Col>
          </Col>
          {/* FIM DO HEADER  */}
          <Col className="card-scroll p-0 pr-2">
            {currentOnBoarding.length > 0 ? (
              <Col className="p-0">
                {currentOnBoarding.map((on, index) => {
                  if (on.microStatus)
                    return (
                      <CardNext
                        myItem={() => {
                          history.push(`/process-details/${on._id}`);
                        }}
                        key={"on_" + on._id}
                        itemSelected={on}
                      />
                    );

                  return (
                    <CardExternal
                      itemSelected={on}
                      key={index}
                      myItem={() => handleOpenExternalDetails(on)}
                    />
                  );
                })}
              </Col>
            ) : (
              <Col className="empty-text">
                <span>Nenhum registro para ser exibido</span>
              </Col>
            )}
          </Col>
        </Col>

        {/*  DESEMBARQUE */}

        <Col>
          <Col className="card-next-header-title">
            <Col className="d-flex justify-content-between">
              Desembarque{" "}
              <KanbanMenu>
                <Dropdown.Item
                  onClick={() =>
                    handleOrdinationNext("processNumber", "finish")
                  }
                >
                  Número do processo
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("microstatus", "finish")}
                >
                  Micro status
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("shipper", "finish")}
                >
                  Shipper
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("origin", "finish")}
                >
                  Origem
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("destination", "finish")}
                >
                  Destino
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => handleOrdinationNext("etd", "finish")}
                >
                  ETD
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("eta", "finish")}
                >
                  ETA
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => handleOrdinationNext("reference", "finish")}
                >
                  Referência do Cliente
                </Dropdown.Item>
              </KanbanMenu>
            </Col>

            <Col>{currentFinish.length}</Col>
          </Col>
          {/* FIM DO HEADER  */}
          <Col className="card-scroll p-0 pr-2">
            {currentFinish.length > 0 ? (
              <Col className="p-0">
                {currentFinish.map((finish, index) => {
                  if (finish.microStatus)
                    return (
                      <CardNext
                        myItem={() => {
                          history.push(`/process-details/${finish._id}`);
                        }}
                        key={"finish_" + finish._id}
                        itemSelected={finish}
                      />
                    );

                  return (
                    <CardExternal
                      itemSelected={finish}
                      key={index}
                      myItem={() => handleOpenExternalDetails(finish)}
                    />
                  );
                })}
              </Col>
            ) : (
              <Col className="empty-text">
                <span>Nenhum registro para ser exibido</span>
              </Col>
            )}
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default Kanban;
