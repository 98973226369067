import React from "react";

import mapboxgl from "mapbox-gl";

import "./MapBoxModal.scss";

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

class MapBoxModal extends React.Component {
  constructor(props) {
    const { lat, lng, zoom, operationType, type } = props;
    super(props);
    this.state = {
      lat: lat ? lat : -25.4364585,
      lng: lng ? lng : -49.2628813,
      zoom: zoom ? zoom : 2,
      operationType: operationType ? operationType : "",
      type: type || "",
    };
  }

  componentDidMount() {
    mapboxgl.accessToken = TOKEN;
    const map = new mapboxgl.Map({
      container: this.map,
      style: `mapbox://styles/${process.env.REACT_APP_MAPBOX_STYLE_ID}`,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    var geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            message: "Foo",
            iconSize: [16, 16],
          },
          geometry: {
            type: "Point",
            coordinates: [this.state.lng, this.state.lat],
          },
        },
      ],
    };

    // var flip = this.state.operationType === "Exportação" ? false : true;

    // add markers to map
    geojson.features.forEach(function (marker) {
      // create a DOM element for the marker
      var el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundColor = "#6f2c91";
      el.style.borderRadius = "50%";
      el.style.border = "solid #fff 2px";
      el.style.width = marker.properties.iconSize[0] + "px";
      el.style.height = marker.properties.iconSize[1] + "px";

      // add marker to map
      new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
    });
  }

  render() {
    return (
      <div className="map-container">
        <div ref={(el) => (this.map = el)} className="map"></div>
      </div>
    );
  }
}

export default React.memo(MapBoxModal);
