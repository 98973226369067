import { createActions, createReducer } from "reduxsauce";

const initialState = {
  filteredAwait: [],
  filteredFinish: [],
  filteredPreBoarding: [],
  filteredOnBoarding: [],
  clearFilter: false,
  activeFilter: false,
  genericCode: "",
};

export const { Types, Creator } = createActions({
  FilteredFinishSuccess: ["filterFinish"],
  FilteredOnBoardingSuccess: ["filterOnBoarding"],
  FilteredPreBoardingSuccess: ["filterPreBoarding"],
  FilteredAwaitSuccess: ["filterAwait"],
  FilteredClearSuccess: [
    "filterFinish",
    "filterOnBoarding",
    "filterPreBoarding",
    "filterAwait",
  ],
  ClearFilter: ["clearFilter"],
  SetFilter: ["activeFilter"],
  SetGenericCode: ["genericCode"],
});

const ClearFilter = (state = initialState, action) => ({
  ...state,
  clearFilter: action.clearFilter,
});

const SetFilter = (state = initialState, action) => ({
  ...state,
  activeFilter: action.activeFilter,
});

const SetGenericCode = (state = initialState, action) => ({
  ...state,
  genericCode: action.genericCode,
});

const FilteredAwaitSuccess = (state = initialState, action) => ({
  ...state,
  filteredAwait: action.filteredAwait || state,
});

const FilteredPreBoardingSuccess = (state = initialState, action) => ({
  ...state,
  filteredPreBoarding: action.filteredPreBoarding || state,
});

const FilteredOnBoardingSuccess = (state = initialState, action) => ({
  ...state,
  filteredOnBoarding: action.filteredOnBoarding || state,
});

const FilteredFinishSuccess = (state = initialState, action) => ({
  ...state,
  filteredFinish: action.filteredFinish || state,
});

const FilteredClearSuccess = (state = initialState, action) => ({
  ...state,
  filteredAwait: state,
  filteredPreBoarding: state,
  filteredOnBoarding: state,
  filteredFinish: state,
});

export default createReducer(initialState, {
  [Types.FILTERED_AWAIT_SUCCESS]: FilteredAwaitSuccess,
  [Types.FILTERED_PRE_BOARDING_SUCCESS]: FilteredPreBoardingSuccess,
  [Types.FILTERED_ON_BOARDING_SUCCESS]: FilteredOnBoardingSuccess,
  [Types.FILTERED_FINISH_SUCCESS]: FilteredFinishSuccess,
  [Types.FILTERED_CLEAR_SUCCESS]: FilteredClearSuccess,
  [Types.CLEAR_FILTER]: ClearFilter,
  [Types.SET_FILTER]: SetFilter,
  [Types.SET_GENERIC_CODE]: SetGenericCode,
});
