import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import "./CustomTooltip.scss";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(0, 178, 175, 1) ",
    fontSize: 12,
    color: "#FFF",
  },
  tooltipOff: {
    display: "none",
  },
  arrowProps: {
    color: "rgba(0, 178, 175, 1) ",
  },
}));

const CustomTooltip = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { retract } = useSelector((state) => state.stateMenu);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="right"
      arrow
      classes={{
        tooltip: retract ? classes.tooltip : classes.tooltipOff,
        arrow: classes.arrowProps,
      }}
      {...props}
    />
  );
};

export default CustomTooltip;
