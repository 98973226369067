import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";

import { useSelector } from "react-redux";

import "../../DetailTab.scss";

const BoardingDetails = () => {
  const { currentProcess } = useSelector((state) => state.stateSelected);

  return (
    <React.Fragment>
      {currentProcess.konfereResponse ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">Origem</b>
            <br />
            <span className="subtitle-grid">
              {currentProcess.konfereResponse.origin.name}
            </span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {currentProcess.konfereResponse ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">Destino</b>
            <br />
            <span className="subtitle-grid">
              {currentProcess.konfereResponse.destination.name}
            </span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {currentProcess.konfereResponse ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">Navio</b>
            <br />
            <span className="subtitle-grid">
              {currentProcess.konfereResponse.currentVoyage
                ? currentProcess.konfereResponse.currentVoyage.vehicle
                    .identifier
                : " - "}
            </span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {currentProcess.konfereResponse ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">Viagem</b>
            <br />
            <span className="subtitle-grid">
              {currentProcess.konfereResponse.currentVoyage
                ? currentProcess.konfereResponse.currentVoyage.voyage
                : " - "}
            </span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {currentProcess.konfereResponse ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid"> Armador</b>
            <br />
            <Tooltip
              title={currentProcess.konfereResponse.carrier}
              placement="top"
              arrow
            >
              <span className="subtitle-grid">
                {currentProcess.konfereResponse.carrier}
              </span>
            </Tooltip>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}

      {currentProcess.konfereResponse ? (
        <Grid xs={3} item={true}>
          <Typography color="textSecondary">
            <b className="title-grid">TT in days</b>
            <br />
            <span className="subtitle-grid">
              {Math.trunc(currentProcess.konfereResponse.progressInDays.total)}
            </span>
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default BoardingDetails;
