/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";

import SwipeableViews from "react-swipeable-views";
import { withStyles, useTheme, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import { Backdrop, CircularProgress } from "@material-ui/core";

import { checkIssues } from "util/useIssues";

import "./NewModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShip,
  faPlaneArrival,
  faPlaneDeparture,
  faClock,
  faComments,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Fab from "@material-ui/core/Fab";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import ActivityTab from "./components/Tabs/ActivityTab";
import DocumentTab from "./components/Tabs/DocumentTab";
import NoteTab from "./components/Tabs/NoteTab";
import DetailTab from "./components/Tabs/DetailTab";

import { ChatUser, AlertNotification, PDFViewer, EtdEta } from "components";

import { RetrieveUserInformation } from "services/Auth/usermanager.service";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "100%",
  },
  content: {
    padding: "5px",
    height: "100%",
  },
  modalWidth: {
    maxWidth: "fit-content",
    overflow: "hidden",
  },
  dialogContent: {
    margin: "auto !important",
    display: "inline !important",
    left: 0,
    height: "100%",
    width: "100%",
    padding: "0px",
  },
  leftContentModal: {
    height: "100%",
    boxShadow: "0px 0px 22px 0px rgba(184,175,184,1)",
    marginTop: "-5px",
  },
  rightContentModal: {
    width: "507px",
    height: "100%",
    top: "32px",
    right: "32px",
    background: "#f5f5f5",
  },
  rightContentModalPdf: {
    width: "503px",
    height: "100%",
    float: "right",
    paddingLeft: "2%",
  },
  tabContainer: {
    padding: "2px",
    flexGrow: 1,
  },
  tabRoot: {
    minWidth: 100,
    textTransform: "none",
    fontSize: "10px",
  },
  textTitle: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
    overflow: "hidden !important",
    flex: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  textSubTitle: {
    fontSize: 12,
    textAlign: "left",
    paddingTop: "0px",
    width: "max-content",
  },
  activeChat: {
    fontSize: 15,
    float: "right",
    height: "11px",
    paddingRight: "12%",
    cursor: "pointer",
    color: "#707070",
  },
  indicatorCurrentTabActived: {
    backgroundColor: "transparent",
  },
  currentTabSelected: {
    backgroundColor: "#F2DFFC",
    color: "#6F2C91",
    borderRadius: "5px",
  },
  customIconFavorite: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#FFBFB5" },
    marginTop: "-9px",
    marginLeft: "5px",
    padding: "3px",
    alignSelf: "start",
  },
  dateBorder: {
    border: "1px grey",
    borderRadius: "5px",
    background: "#F1F1F1",
    width: "fit-content",
    textAlign: "center",
    padding: "5px",
  },
  chatButton: {
    width: "130px",
    fontSize: "18px",
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
  wrapper: {
    fontSize: 15,
    fontWeight: 600,
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0px",
    paddingTop: "0px !important",
    left: 0,
    minHeight: "500px",
    //maxHeight: "80%",
    //height: "80%"
  },
}))(MuiDialogContent);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box style={{ paddingTop: "10px" }} p={3}>
        {children}
      </Box>
    </Typography>
  );
};

function BootstrapTooltip(props) {
  const classes = useStyles();

  return (
    <Tooltip
      TransitionComponent={Zoom}
      placement="top"
      arrow
      classes={{ tooltip: classes.tooltip }}
      {...props}
    />
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function NewModal(props) {
  const { currentProcess } = useSelector((state) => state.stateSelected);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [fullWidth] = React.useState(true);
  const [openChat, setOpenChat] = React.useState(
    props.itemSelected && props.chat !== "" ? true : false
  );
  const [openPDF, setOpenPDF] = React.useState(false);
  const [pdf, setPdf] = React.useState(null);
  const { cargoLocationsLog } = props.itemSelected
    ? props.itemSelected
    : currentProcess;
  const [loading, setLoading] = React.useState(true);
  const [pendingIssues, setPendingIssues] = React.useState(false);
  const [alertMessage] = React.useState("");
  const [alertType] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    if (props.typeChannel !== undefined) {
      setOpenChat(true);
    }
  }, [props.typeChannel]);

  useEffect(() => {
    function getIssues() {
      if (!currentProcess.issues || currentProcess.issues.length < 1)
        return false;
      else {
        return (
          currentProcess.issues.filter((issue) => {
            return (
              issue.solved === false &&
              checkIssues(currentProcess.issues.createdAt)
            );
          }).length > 0
        );
      }
    }
    setPendingIssues(getIssues());
  }, []);

  const handleOpenChat = () => {
    if (openChat) setOpenChat(!openChat);
    else {
      //As duas linhas abaixo devem ser removidas
      //se remover os comentario do conteudo mais abaixo
      setOpenChat(!openChat);
      setOpenPDF(false);
      /*
      let hasPermission = itemSelected.responsibles.filter(i => { return i.email === userID })
      if (hasPermission.length > 0) {
        setOpenChat(!openChat)
        setOpenPDF(false)
      }
      else {
        setAlertMessage("Desculpe! Você não é membro deste chat")
        setAlertType("warning")
        setAlertOpen(true)
        console.log("Vc não tem permissão para abrir o chat")
      }*/
    }
  };

  const viewPDF = (file) => {
    setPdf(file);
    setOpenChat(false);
    setOpenPDF(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <Col className={classes.modal} style={{ zIndex: 99999999999 }}>
      <Dialog
        style={{ overflow: "hidden" }}
        fullWidth={fullWidth}
        maxWidth="sm"
        classes={{
          paperWidthSm: openChat || openPDF ? classes.modalWidth : null,
        }}
        className={(classes.content, openChat || openPDF ? "chat" : "")}
        onClose={props.onClose}
        open={props.open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogContent className="dialogContent">
          <div style={{ width: "100%" }}>
            <Col
              className={
                !pendingIssues
                  ? "d-flex justify-content-center align-items-center new-payment"
                  : "d-flex justify-content-center align-items-center new-pendent"
              }
            >
              <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faClock} />
              <span>{currentProcess && currentProcess.microStatus}</span>
            </Col>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: openChat || openPDF ? "55% 45%" : "100%",
            }}
          >
            <div
              className={openChat || openPDF ? classes.leftContentModal : null}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  padding: "35px 17px 15px 17px",
                }}
              >
                <Backdrop className="loading" open={loading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Typography color="textSecondary" gutterBottom>
                  <BootstrapTooltip
                    title={
                      currentProcess.operationType +
                      " - " +
                      currentProcess.transportationModality
                    }
                  >
                    <>
                      {currentProcess.transportationModality === "Aéreo" ? (
                        <FontAwesomeIcon
                          className="iconCard"
                          title={
                            currentProcess.operationType +
                            " - " +
                            currentProcess.transportationModality
                          }
                          icon={
                            currentProcess.operationType === "Importação"
                              ? faPlaneArrival
                              : faPlaneDeparture
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="iconCard"
                          title={
                            currentProcess.operationType +
                            " - " +
                            currentProcess.transportationModality
                          }
                          icon={
                            currentProcess.operationType === "Importação"
                              ? faShip
                              : faShip
                          }
                        />
                      )}
                    </>
                  </BootstrapTooltip>
                </Typography>

                <div
                  style={{
                    width: "100%",
                    paddingLeft: "6%",
                    minHeight: "42px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <BootstrapTooltip
                      title={`${currentProcess.clientReference || ""} - 
                    ${currentProcess.processNumber || ""}`}
                    >
                      <Typography
                        className={classes.textTitle}
                        color="textSecondary"
                        gutterBottom
                      >
                        {`${currentProcess.clientReference || ""} - 
                    ${currentProcess.processNumber || ""}`}
                      </Typography>
                    </BootstrapTooltip>
                    <>
                      <EtdEta
                        infos={currentProcess.boarding}
                        padding="14px 8px"
                      />
                    </>
                  </div>
                </div>
              </div>
              <div className="modal-subtitle">
                {currentProcess.boarding ? (
                  <>
                    <Typography
                      className="subTitleCardCard"
                      color="textSecondary"
                    >
                      {currentProcess.boarding?.origin || ""}{" "}
                      <ArrowRightAltIcon style={{ fontSize: "20px" }} />
                      {currentProcess.boarding?.destination || ""}
                    </Typography>
                  </>
                ) : (
                  <React.Fragment />
                )}
              </div>

              <Tabs
                variant="fullWidth"
                value={value}
                classes={{
                  indicator: classes.indicatorCurrentTabActived,
                }}
                onChange={handleChange}
                aria-label="tabs of modal"
              >
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.currentTabSelected,
                    wrapper: classes.wrapper,
                  }}
                  label="Atividade"
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.currentTabSelected,
                    wrapper: classes.wrapper,
                  }}
                  label="Detalhes"
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.currentTabSelected,
                    wrapper: classes.wrapper,
                  }}
                  label="Documentos"
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.currentTabSelected,
                    wrapper: classes.wrapper,
                  }}
                  label="Anotações"
                />
              </Tabs>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel
                  className={classes.tabContainer}
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <ActivityTab
                    value={value}
                    updateItem={props.updateItem}
                    setLoading={(status) => setLoading(status)}
                    onClose={props.onClose}
                    itemSelected={currentProcess}
                    //activities={activityLog.activities}
                    currentProcess={currentProcess}
                    kanbanStatus={currentProcess.kanbanStatus}
                    pendingIssues={pendingIssues}
                    locations={
                      cargoLocationsLog && cargoLocationsLog.locations
                        ? cargoLocationsLog.locations
                        : null
                    }
                    openPDF={(e) => viewPDF(e)}
                    lastLocation={props.lastLocation}
                    noMaps={props.noMaps ? true : false}
                  />
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                  <DetailTab itemSelected={props.itemSelected} />
                </TabPanel>

                <TabPanel value={value} index={2} dir={theme.direction}>
                  <DocumentTab
                    documents={currentProcess.documents}
                    financials={currentProcess.financials}
                  />
                </TabPanel>

                <TabPanel value={value} index={3} dir={theme.direction}>
                  <NoteTab
                    processId={currentProcess._id}
                    notes={currentProcess.notes}
                  />
                </TabPanel>
              </SwipeableViews>
            </div>
            {openPDF && (
              <>
                <div className={classes.rightContentModal}>
                  <PDFViewer pdf={pdf} />
                </div>
                <Tooltip title="Fechar o pdf">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    onClick={() => setOpenPDF(false)}
                    title="Fechar PDF"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "42px",
                      top: "58px",
                      color: "#707070",
                      fontSize: "24px",
                    }}
                  />
                </Tooltip>
              </>
            )}
            {openChat && (
              <div className={classes.rightContentModal}>
                <ChatUser process={currentProcess} chat={props.typeChannel} />
              </div>
            )}
          </div>

          {!RetrieveUserInformation().roles.includes("Guest") &&
            !RetrieveUserInformation().roles.includes("Broker") && (
              <>
                {!openChat ? (
                  <Tooltip title="Ativar Chat">
                    <Fab
                      aria-label="Ativar Chat"
                      onClick={() => handleOpenChat()}
                      style={{
                        position: "absolute",
                        right: !openPDF ? "24px" : "95%",
                        bottom: "16px",
                        backgroundColor: "#6f2c91",
                        color: "#fff",
                      }}
                    >
                      <FontAwesomeIcon icon={faComments} />
                    </Fab>
                  </Tooltip>
                ) : (
                  <Tooltip title="Fechar o Chat">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      onClick={() => handleOpenChat()}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "42px",
                        top: "58px",
                        color: "#707070",
                        fontSize: "24px",
                      }}
                    />
                  </Tooltip>
                )}
              </>
            )}

          {alertOpen && (
            <AlertNotification
              message={alertMessage}
              type={alertType}
              handleClose={handleClose}
              open={alertOpen}
            />
          )}
        </DialogContent>
      </Dialog>
    </Col>
  );
}

export default NewModal;
