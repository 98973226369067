import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-regular-svg-icons";
import React from "react";

import "./NoteItem.scss";

const NoteItem = (props) => {
  const { note } = props;

  return (
    <div className="note-item">
      <div className="note-actions">
        <p
          className={
            note.priority == "high"
              ? "note-priority high"
              : note.priority == "normal"
              ? "note-priority normal"
              : "note-priority low"
          }
        >
          {note.priority == "high"
            ? "Importante"
            : note.priority == "normal"
            ? "Normal"
            : "Baixa importância"}
        </p>
        <div style={{ textAlign: "right" }}>
          <FontAwesomeIcon
            className="note-icons"
            onClick={() => props.onRemove(note)}
            icon={faTrashAlt}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            className="note-icons"
            onClick={() => props.onEdit(note)}
            icon={faEdit}
          ></FontAwesomeIcon>
        </div>
      </div>
      <p className="note-content">{note.content}</p>
      <p className="note-info">
        {note.owner.name} em <span>{note.createdAt}</span>
      </p>
      <hr className="note-divider" />
    </div>
  );
};

export default NoteItem;
