/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { useSelector } from "react-redux";

import "./DetailTab.scss";

import { KnowledgeDetails, BoardingDetails } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  gridContent: {
    borderBottom: "1px solid #CFCFCF",
    paddingTop: "4%",
    paddingBottom: "4%",
  },
  subtitle1: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.75",
    letterSpacing: "0.00938em",
    color: "#6f2c91",
    width: "100%",
    paddingTop: "15px",
    paddingLeft: "0px",
  },
  textLine: {
    fontSize: "11px",
  },
  AccordionSummary: {
    margin: "0px",
  },
  heading: {
    fontSize: "19px",
  },
}));

function DetailTab(props) {
  const { currentProcess } = useSelector((state) => state.stateSelected);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Typography
        color="textSecondary"
        variant="h6"
        style={{ fontWeight: "600" }}
      >
        Despachante
      </Typography>
      <Grid container style={{ padding: "12px" }}>
        <BrokerSelect />
      </Grid> */}
      <hr />
      <Typography
        color="textSecondary"
        variant="h6"
        style={{ fontWeight: "600" }}
      >
        Embarque
      </Typography>
      <Grid container style={{ padding: "12px" }}>
        <BoardingDetails classes={classes} />
      </Grid>
      <hr />
      {currentProcess.konfereResponse && (
        <Grid container style={{ padding: "12px" }}>
          <KnowledgeDetails classes={classes} />
        </Grid>
      )}
    </div>
  );
}

export default DetailTab;
