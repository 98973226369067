import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const EquipmentsDetail = (props) => {
  const { equipaments, classes } = props;

  return (
    <>
      <Typography className={classes.subtitle1}>Equipamentos</Typography>
      {equipaments.map((equip, index) => (
        <div
          key={equip._id}
          style={{ display: "flex", width: "100%", padding: "6px" }}
          className={index % 2 == 0 ? "" : "bgGray"}
        >
          <Grid xs={3} item={true}>
            <Typography color="textSecondary">
              <b className="title-grid">Tipo</b>
              <br />
              <span className="subtitle-grid">{equip.type}</span>
            </Typography>
          </Grid>
          <Grid xs={3} item={true}>
            <Typography color="textSecondary">
              <b className="title-grid">Quantidade</b>
              <br />
              <span className="subtitle-grid">{equip.quantity}</span>
            </Typography>
          </Grid>
          <Grid xs={3} item={true}>
            <Typography color="textSecondary">
              <b className="title-grid">Free Time</b>
              <br />
              <span className="subtitle-grid">{equip.freeTimeDestination}</span>
            </Typography>
          </Grid>
          <Grid xs={3} item={true}>
            <Typography color="textSecondary">
              <b className="title-grid">Limite devolução</b>
              <br />
              <span className="subtitle-grid">
                {moment.utc(equip.devolutionLimit).format("DD MMM")}
              </span>
            </Typography>
          </Grid>
        </div>
      ))}
    </>
  );
};

export default EquipmentsDetail;
