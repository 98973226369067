import { newDataAPI, buildURL } from "../apiConsumer";

export const getCompanies = async (query, sort, page, limit) => {
  return await newDataAPI.get(
    `employers/${buildURL(query, sort, page, limit ? limit : 9999)}`
  );
};

export const getAdminCompanies = async (query, sort, page, limit) => {
  return await newDataAPI.get(
    `employers/${buildURL(query, sort, page, limit ? limit : 9999)}`
  );
};

export const postCompany = async (data) => {
  return await newDataAPI.post(`employers/`, data);
};

export const getCompany = async (id) => {
  return await newDataAPI.get(`employers/${id}`);
};

export const patchCompany = async (id, data) => {
  return await newDataAPI.patch(`employers/${id}`, data);
};

export const getCNPJInformation = async (cnpj) => {
  return await newDataAPI.get(`cnpj/${cnpj}`);
};

export const getListActors = async (id, text) => {
  return await newDataAPI.get(`employers/filter/${id}/${text}`);
};

export const checkBrokerPermissionsRole = async (
  employerId,
  brokerId,
  role
) => {
  return await newDataAPI.get(
    `employers/${employerId}/broker/${brokerId}/role/${role}`
  );
};
