/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { NewTimeline, MapBoxModal, IssuesTimeline } from "./components";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    minWidth: 100,
  },
  tabAtividade: {
    bottom: 0,
    fontSize: "14px",
  },
  grid: {
    textAlign: "center",
    fontWeight: 600,
    color: "#6B6B6B",
  },
  gridTextRight: {
    textAlign: "right",
    fontWeight: 600,
    color: "#6B6B6B",
  },
  gridTextLeft: {
    textAlign: "left",
    fontWeight: 600,
    color: "#6B6B6B",
  },
  indicator: {
    backgroundColor: "#fdcfca",
  },
  tabSelected: {
    backgroundColor: "#fdcfca",
  },
  dialogTitle: {
    paddingLeft: "10%",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  subTitle: {
    fontSize: 14,
    textAlign: "left",
    paddingTop: "0px",
    paddingLeft: "4.5%",
  },
  dialogContent: {
    margin: "auto !important",
    left: 0,
    height: "100%",
    width: "100%",
    padding: "0px",
  },
  dateBorder: {
    border: "1px solid grey",
    borderRadius: "4px",
    width: "9%",
    background: "#D3D3D3",
    marginLeft: "4.5%",
    alignItems: "center",
  },
  card: {
    padding: "5px",
  },
  mapModal: {
    height: "140px",
    cursor: "pointer",
  },
  root: {
    padding: "2px",
  },
  map: {
    height: "80% !important",
  },
}));

function ActivityTab(props) {
  const classes = useStyles();
  const [myLocation, setMyLocation] = React.useState({
    lat: -1,
    lng: -1,
    done: false,
  });
  const { currentProcess, itemSelected, kanbanStatus, noMaps } = props;

  const [completed] = React.useState(
    kanbanStatus && kanbanStatus === "Pré embarque"
      ? 10
      : kanbanStatus === "Em trânsito"
      ? 40
      : kanbanStatus === "Desembarcado"
      ? 65
      : kanbanStatus === "Concluído"
      ? 100
      : 1
  );

  const ColorLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: "#2C28284D",
    },
    barColorPrimary: {
      backgroundColor: "#6F2C91",
    },
  })(LinearProgress);

  const openPDF = (file) => {
    props.openPDF(file);
  };

  function setLocation() {
    let location = { lat: -1, lng: -1, done: true };
    if (
      currentProcess.transportationModality === "Marítimo" ||
      currentProcess.mode === "SEA"
    ) {
      if (currentProcess.onBoard) {
        location.lat =
          currentProcess.dynamicVessel !== null &&
          currentProcess.dynamicVessel.lastLocation
            ? currentProcess.dynamicVessel.lastLocation.latitude
            : -1;
        location.lng =
          currentProcess.dynamicVessel !== null &&
          currentProcess.dynamicVessel.lastLocation
            ? currentProcess.dynamicVessel.lastLocation.longitude
            : -1;
        return location;
      } else {
        location.lat = currentProcess.dynamicPort
          ? currentProcess.dynamicPort.latitude
          : -1;
        location.lng = currentProcess.dynamicPort
          ? currentProcess.dynamicPort.longitude
          : -1;
        return location;
      }
    } else {
      location.lat = currentProcess.dynamicAirport
        ? currentProcess.dynamicAirport.latitude
        : -1;
      location.lng = currentProcess.dynamicAirport
        ? currentProcess.dynamicAirport.longitude
        : -1;
      return location;
    }
  }

  useEffect(() => {
    setMyLocation(setLocation());
  }, [currentProcess]);

  return (
    <div>
      {itemSelected && !noMaps && (
        <div
          className={classes.mapModal}
          onClick={() => {
            props.onClose();
            if (document.getElementsByClassName("mapboxgl-map").length === 1) {
              let mapShow = document.getElementById("myDiv");
              if (!mapShow.classList.contains("map-home")) {
                let btn = document.getElementById("btnMapa");
                btn.click();
              }
            }
          }}
        >
          {myLocation.done && props.value === 0 && (
            <MapBoxModal
              value={props.value}
              zoom={4}
              maxZoom={4}
              minZoom={4}
              interactive={false}
              lat={myLocation.lat}
              lng={myLocation.lng}
              operationType={currentProcess.operationType}
              type={currentProcess.transportationModality}
            />
          )}
        </div>
      )}

      <div className={classes.tabAtividade}>
        <Grid container spacing={3}>
          <Grid className={classes.gridTextLeft} item xs={6} sm={3}>
            Pré-embarque
          </Grid>
          <Grid className={classes.grid} item xs={6} sm={3}>
            Em trânsito
          </Grid>
          <Grid className={classes.grid} item xs={6} sm={3}>
            Desembarque
          </Grid>
          <Grid className={classes.gridTextRight} item xs={6} sm={3}>
            Concluído
          </Grid>
        </Grid>
        <ColorLinearProgress variant="determinate" value={completed} />

        <div>
          {itemSelected.issues && (
            <IssuesTimeline
              issues={itemSelected.issues}
              processID={currentProcess._id}
              responsibles={itemSelected.responsibles}
              processNumber={currentProcess.processNumber}
              refClient={currentProcess.clientReference}
              openPDF={(e) => openPDF(e)}
            />
          )}
        </div>

        <div>
          {
            <NewTimeline
              itemSelected={itemSelected}
              updateItem={props.updateItem}
              setLoading={props.setLoading}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default ActivityTab;
