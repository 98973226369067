/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";

import { CircularProgress } from "@material-ui/core";
import { searchChat } from "services/Chat/chat.service";

import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";

//import SCSS

import "./ChatContent.scss";

const ChatContent = (props) => {
  const { type, processNumber, supportChat, active, index } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [messages] = useState([]);
  const userId = RetrieveUserInformation().email;
  const messagesEnd = useRef(null);

  const loadingChat = async () => {
    setIsLoading(true);
    try {
      if (active === index) {
        // eslint-disable-next-line no-unused-vars
        const response = await searchChat(processNumber, type);
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadingChat();
  }, [index, active]);

  const handleHistory = () => {
    const win = window.open("/historico-de-conversa", "_blank");
    window.localStorage.setItem("supportChat", supportChat);
    win.focus();
  };

  // const scrollToBottom = () => {
  //   messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  // };

  // const sendMessage = () => {
  //   console.log("mensagem enviada");
  // };

  const getUserName = () => {
    console.log("GET USER NAME");
  };

  return (
    <div className="card-chat">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {supportChat && (
            <div className="card-header">
              <p>
                <i>
                  Todas as mensagens estão salvas no "
                  <span
                    onClick={handleHistory}
                    style={{ color: "#00B2AF", cursor: "pointer" }}
                  >
                    {" "}
                    Histórico de mensagens{" "}
                  </span>
                  "
                </i>
              </p>
            </div>
          )}
        </div>
      )}

      {/*  fim do header com o link  para visualizar o chat antigo */}

      {/* container das mensagens */}
      <div className="card-chat-content">
        <div className="card-messages" id="messages">
          {messages.length === 0 && (
            <div className="emptyMessage">
              <SpeakerNotesOffIcon />
              <span>Nenhuma mensagem nesse chat </span>
            </div>
          )}

          {messages.map((item, index) => {
            let labelData;
            let showLabel = true;
            if (
              moment(item.createdAtTime).format("DD/MM/YYYY") ===
              moment().format("DD/MM/YYYY")
            ) {
              labelData = "HOJE";
            } else if (
              moment(item.createdAtTime).format("DD/MM/YYYY") ===
              moment().subtract(1, "d").format("DD/MM/YYYY")
            ) {
              labelData = "ONTEM";
            } else {
              labelData = moment(item.createdAtTime).format(
                "DD [de] MMMM [de] YYYY"
              );
            }
            if (
              index > 0 &&
              moment(item.createdAtTime).format("DD/MM/YYYY") ==
                moment(messages[index - 1].createdAtTime).format("DD/MM/YYYY")
            ) {
              showLabel = false;
            }
            return (
              <div id="messages" key={index}>
                {showLabel && (
                  <div className="containerLabelData">
                    <div className="labelData">{labelData}</div>
                  </div>
                )}
                <div className="containerMessageDefault">
                  <div
                    className={
                      item.metadata && item.metadata.sendUser !== userId
                        ? "card-chat-received-messages"
                        : "card-chat-sent-messages"
                    }
                  >
                    {item.metadata && item.metadata.sendUser !== userId && (
                      <div
                        className={
                          item.metadata && item.metadata.sendUser !== userId
                            ? "userReceivedMessage"
                            : "userSentMessage"
                        }
                      >
                        {item.metadata && item.metadata.sendUser === userId
                          ? userId
                          : getUserName(
                              item.metadata && item.metadata.sendUser
                                ? item.metadata && item.metadata.sendUser
                                : item.to
                            )}
                      </div>
                    )}
                    <p>{item.message}</p>
                    <span className="time_date">
                      {" "}
                      {moment.utc(item.createdAtTime).format("HH:mm")}
                    </span>{" "}
                  </div>
                </div>
                <div ref={messagesEnd}></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChatContent;
