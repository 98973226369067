/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Button, Radio, FormLabel } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import "./DateFilter.scss";

const PurpleRadio = withStyles({
  root: {
    color: "#c1c1c1",
    "&$checked": {
      color: "#c79bde",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const DateFilter = (props) => {
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [status, setStatus] = useState("");
  const [requiredDate, setRquiredDate] = useState(false);

  const selectDate = (option) => {
    let dateMoment = moment().utc();
    switch (option) {
      case "today":
        setDateStart(moment().utc().toDate());
        setDateEnd(moment().utc().toDate());
        break;
      case "thisWeek":
        setDateStart(moment().utc().startOf("isoWeek").toDate());
        setDateEnd(moment().utc().endOf("isoWeek").toDate());
        break;
      case "nextWeek":
        setDateStart(
          moment().utc().add(1, "weeks").startOf("isoWeek").toDate()
        );
        setDateEnd(moment().utc().add(1, "weeks").endOf("isoWeek").toDate());
        break;
      case "thisFortnight":
        setDateStart(new Date(dateMoment));
        setDateEnd(new Date(dateMoment.add(15, "days")));
        break;
      case "thisMonth":
        setDateStart(new Date(dateMoment.startOf("month")));
        setDateEnd(new Date(dateMoment.endOf("month")));
        break;
      case "thisYear":
        setDateStart(new Date(dateMoment.startOf("year")));
        setDateEnd(new Date(dateMoment.endOf("year")));
        break;
      case "lastYear":
        setDateStart(new Date(dateMoment.startOf("year").subtract(1, "year")));
        setDateEnd(new Date(dateMoment.endOf("year")));
        break;
      default:
        break;
    }
  };

  const handleChangeRadio = (select) => {
    setStatus(select);
    setRquiredDate(true);
  };

  const onStartDateChange = (date) => {
    setDateStart(date);
  };

  const onEndDateChange = (date) => {
    setDateEnd(date);
  };

  const handleSubmit = () => {
    props.submit({ dateStart, dateEnd, status });
  };

  useEffect(() => {
    if (dateStart && dateEnd && status !== "") {
      handleSubmit();
    }
  }, [dateStart, dateEnd, status]);

  return (
    <div className="date-filter">
      <FormLabel component="legend" className="pt-2">
        Ocorridos
      </FormLabel>
      <div
        className="d-flex flex-row flex-wrap justify-content-between"
        style={{ width: "300px" }}
      >
        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "cargoLoad"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="cargoLoad"
          />
          <span>Embarque</span>
        </div>

        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "cargoUnload"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="cargoUnload"
          />
          <span>Desembarque</span>
        </div>

        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "cargoReady"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="cargoReady"
          />
          <span>Prontidão</span>
        </div>

        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "completedPickup"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="completedPickup"
          />
          <span>Coleta</span>
        </div>
      </div>

      <FormLabel component="legend" className="pt-2">
        Previstos
      </FormLabel>

      <div
        className="d-flex flex-row flex-wrap justify-content-between"
        style={{ width: "300px" }}
      >
        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "estimatedCargoLoad"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="estimatedCargoLoad"
          />
          <span>Embarque</span>
        </div>

        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "estimatedCargoUnload"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="estimatedCargoUnload"
          />
          <span>Desembarque</span>
        </div>

        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "estimatedCargoReady"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="estimatedCargoReady"
          />
          <span>Prontidão</span>
        </div>

        <div
          className="d-flex align-items-center p-0"
          style={{ width: "150px" }}
        >
          <PurpleRadio
            checked={status === "estimatedPickup"}
            onChange={(e) => handleChangeRadio(e.target.value)}
            value="estimatedPickup"
          />
          <span>Coleta</span>
        </div>
      </div>

      <div className="date-section">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="start-picker-inline"
            required={requiredDate}
            placeholder="Data início"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            value={dateStart}
            onChange={onStartDateChange}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="end-picker-inline"
            required={requiredDate}
            placeholder="Data limite"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            value={dateEnd}
            onChange={onEndDateChange}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className="date-buttons">
        <Button
          fullWidth
          variant="outlined"
          onClick={() => selectDate("today")}
        >
          Hoje
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => selectDate("thisWeek")}
        >
          Esta semana
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => selectDate("nextWeek")}
        >
          Próxima semana
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => selectDate("thisFortnight")}
        >
          Esta quinzena
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => selectDate("thisMonth")}
        >
          Este mês
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => selectDate("thisYear")}
        >
          Este ano
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => selectDate("lastYear")}
        >
          Ano passado
        </Button>
      </div>
    </div>
  );
};

export default DateFilter;

// return (
//   <div className="date-filter">
//     <h5>Ocorridos </h5>
//     <hr />
//     <div>
//       <RadioGroup
//         aria-label="anonymous"
//         name="anonymous"
//         value={status}
//         className="coleta-section"
//         onChange={(e) => handleChangeRadio(e.target.value)}
//       >
//         <FormControlLabel
//           value="cargoLoad"
//           control={<Radio />}
//           label="Data embarque"
//         />
//         <FormControlLabel
//           value="cargoUnload"
//           control={<Radio />}
//           label="Data desembarque"
//         />
//         <FormControlLabel
//           value="cargoReady"
//           control={<Radio />}
//           label="Data prontidão da carga"
//         />
//         <FormControlLabel
//           value="completedPickup"
//           control={<Radio />}
//           label="Data de coleta da carga"
//         />
//       </RadioGroup>
//     </div>
//     <h5 style={{ paddingTop: "12px" }}>Previsão </h5>
//     <hr />
//     <div>
//       <RadioGroup
//         aria-label="anonymous"
//         name="anonymous"
//         value={status}
//         className="coleta-section"
//         onChange={(e) => handleChangeRadio(e.target.value)}
//       >
//         <FormControlLabel
//           value="estimatedCargoLoad"
//           control={<Radio />}
//           label="Data previsão embarque"
//         />
//         <FormControlLabel
//           value="estimatedCargoUnload"
//           control={<Radio />}
//           label="Data previsão desembarque"
//         />
//         <FormControlLabel
//           value="estimatedCargoReady"
//           control={<Radio />}
//           label="Data previsão da prontidão"
//         />
//         <FormControlLabel
//           value="estimatedPickup"
//           control={<Radio />}
//           label="Data previsão da coleta"
//         />
//       </RadioGroup>
//     </div>

//     <div className="date-section">
//       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//         <KeyboardDatePicker
//           disableToolbar
//           variant="inline"
//           format="dd/MM/yyyy"
//           margin="normal"
//           id="start-picker-inline"
//           placeholder="Data início"
//           KeyboardButtonProps={{
//             "aria-label": "change date",
//           }}
//           value={dateStart}
//           onChange={onStartDateChange}
//         />
//       </MuiPickersUtilsProvider>
//       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//         <KeyboardDatePicker
//           disableToolbar
//           variant="inline"
//           format="dd/MM/yyyy"
//           margin="normal"
//           id="end-picker-inline"
//           placeholder="Data limite"
//           KeyboardButtonProps={{
//             "aria-label": "change date",
//           }}
//           value={dateEnd}
//           onChange={onEndDateChange}
//         />
//       </MuiPickersUtilsProvider>
//     </div>

//     <div className="date-buttons">
//       <Button
//         fullWidth
//         variant="outlined"
//         onClick={() => selectDate("today")}
//       >
//         Hoje
//       </Button>
//       <Button
//         fullWidth
//         variant="outlined"
//         onClick={() => selectDate("thisWeek")}
//       >
//         Esta semana
//       </Button>
//       <Button
//         fullWidth
//         variant="outlined"
//         onClick={() => selectDate("nextWeek")}
//       >
//         Próxima semana
//       </Button>
//       <Button
//         fullWidth
//         variant="outlined"
//         onClick={() => selectDate("thisFortnight")}
//       >
//         Esta quinzena
//       </Button>
//       <Button
//         fullWidth
//         variant="outlined"
//         onClick={() => selectDate("thisMonth")}
//       >
//         Este mês
//       </Button>
//       <Button
//         fullWidth
//         variant="outlined"
//         onClick={() => selectDate("thisYear")}
//       >
//         Este ano
//       </Button>
//       <Button
//         fullWidth
//         variant="outlined"
//         onClick={() => selectDate("lastYear")}
//       >
//         Ano passado
//       </Button>
//     </div>

//     <hr />

//     <div className="buttons-section">
//       <Button className="button" onClick={props.onClick}>
//         Cancelar
//       </Button>
//       <Button
//         className="button"
//         style={{ color: "green" }}
//         onClick={handleSubmit}
//       >
//         Aplicar
//       </Button>
//     </div>
//   </div>
// );
