import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import PublicLayout from "layouts/Public";
import PrivateLayout from "layouts/Private";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/send-files/:userID/:processID/:processNumber/:token",
    component: PublicLayout,
    routes: [
      {
        path: "/send-files/:userID/:processID/:processNumber/:token",
        exact: true,
        component: lazy(() =>
          import("pages/Demurrage/components/MobileFilesRelease")
        ),
      },
    ],
  },
  {
    path: "/terms-of-use",
    component: PublicLayout,
    exact: true,
    routes: [
      {
        path: "/terms-of-use",
        exact: true,
        component: lazy(() => import("pages/TermsOfUse")),
      },
    ],
  },

  {
    path: "/login",
    component: PublicLayout,
    exact: true,
    routes: [
      {
        path: "/login",
        exact: true,
        component: lazy(() => import("pages/Login")),
      },
    ],
  },

  {
    route: "*",
    component: PrivateLayout,
    routes: [
      {
        path: "/dashboards",
        exact: true,
        component: lazy(() => import("pages/Dashboard")),
      },
      {
        path: "/user-details/:id",
        exact: true,
        component: lazy(() => import("pages/Users/UserDetails")),
      },
      {
        path: "/process-details/:id",
        exact: true,
        component: lazy(() => import("pages/ProcessDetails")),
      },
      {
        path: "/companies",
        exact: true,
        component: lazy(() => import("pages/Companies")),
      },
      {
        path: "/company-details",
        exact: true,
        component: lazy(() => import("pages/Companies/CompanyDetails")),
      },
      {
        path: "/company-details/:id",
        exact: true,
        component: lazy(() => import("pages/Companies/CompanyDetails")),
      },
      {
        path: "/create-user",
        exact: true,
        component: lazy(() => import("pages/Users/UserDetails")),
      },
      // {
      //   path: "/create-user",
      //   exact: true,
      //   component: lazy(() => import("pages/Users/UserNew")),
      // },
      {
        path: "/financial",
        exact: true,
        component: lazy(() => import("pages/Financial")),
      },
      {
        path: "/orders-list",
        exact: true,
        component: lazy(() => import("pages/Orders/List")),
      },
      {
        path: "/orders-history",
        exact: true,
        component: lazy(() => import("pages/Orders/History")),
      },
      {
        path: "/done-cargos",
        exact: true,
        component: lazy(() => import("pages/Cargo/Done")),
      },
      {
        path: "/archived-cargos",
        exact: true,
        component: lazy(() => import("pages/Cargo/Archived")),
      },
      {
        path: "/settings",
        exact: true,
        component: lazy(() => import("pages/Settings")),
      },
      {
        path: "/liberacao-online",
        exact: true,
        component: lazy(() => import("pages/Demurrage")),
      },
      {
        path: "/chat",
        exact: true,
        component: lazy(() => import("pages/NewChat")),
      },
      {
        path: "/historico-de-conversa",
        exact: true,
        component: lazy(() => import("pages/HistoryChat")),
      },
      // {
      //   path: "/chat",
      //   exact: true,
      //   component: lazy(() => import("pages/CustomerChat")),
      // },
    ],
  },
];

export default routes;
