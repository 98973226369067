/* eslint-disable array-callback-return */
import { call, put } from "redux-saga/effects";
import { Types as processTypes } from "../reducers/processReducer";
import { getNewOtherProcess } from "services/Process/process.service";
import { getProcessesNew } from "services/Process/process.service";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";
import moment from "moment";
export function* processRequest(action) {
  let myNewProcess;
  let myOtherProcess;
  let data;
  let currentAwait;
  let currentFinish;
  let currentOnBoarding;
  let currentPreBoarding;
  const id = RetrieveUserInformation()._id;
  let tempData;
  let newData = [];

  try {
    const criteria = [];
    const externalCriteria = [];

    externalCriteria.push({
      client: RetrieveUserInformation().employer._id,
    });
    externalCriteria.push({
      $or: [
        { lastUpdate: { $exists: false } },
        {
          lastUpdate: {
            $gt: moment().subtract(40, "days").format("YYYY-MM-DD"),
          },
        },
      ],
    });

    criteria.push({
      $or: [
        //posso ser cliente, ou
        { client: RetrieveUserInformation().employer._id },
        { client: { $in: RetrieveUserInformation().employersAccessList } },
        // //posso ser notify, ou
        { notify: RetrieveUserInformation().employer._id },
        { notify: { $in: RetrieveUserInformation().employersAccessList } },
        // //posso ser consignee, ou
        { consignee: RetrieveUserInformation().employer._id },
        { consignee: { $in: RetrieveUserInformation().employersAccessList } },
        //posso ser broker
        { broker: RetrieveUserInformation().employer._id },
        { broker: { $in: RetrieveUserInformation().employersAccessList } },
        //posso ser shipper
        { shipper: RetrieveUserInformation().employer._id },
        { shipper: { $in: RetrieveUserInformation().employersAccessList } },
      ],
    });

    criteria.push({
      kanbanStatus: { $nin: ["Fora do Kanban", "Concluído", "Cancelado"] },
    });

    criteria.push({
      microStatus: { $nin: ["Novo Processo"] },
    });

    criteria.push({
      $or: [
        { doneAt: { $exists: false } },
        { doneAt: { $gt: moment().subtract(30, "days").format("YYYY-MM-DD") } },
      ],
    });
    myNewProcess = yield call(() => {
      return getProcessesNew(
        criteria.length > 0 && JSON.stringify({ $and: criteria }),
        "-issues, clientReference"
      );
    });
    myOtherProcess = yield call(() => {
      return getNewOtherProcess(
        externalCriteria.length > 0 &&
        JSON.stringify({ $and: externalCriteria }),
        "-issues, clientReference"
      );
    });
    // myOtherProcess = yield call(() => {
    //   return getOtherProcess(RetrieveUserInformation().employer._id);
    // });

    tempData = myNewProcess.data.items.concat(myOtherProcess.data.items);

    // filtro do analista ou do processo sem analista

    tempData.map((process) => {
      if (
        RetrieveUserInformation().roles.includes("CompanyAdministrator") ||
        RetrieveUserInformation().roles.includes("Guest")
      ) {
        newData.push(process);
      } else {
        if (
          RetrieveUserInformation().employersAccessList.includes(
            process.client._id
          )
        ) {
          if (process.analist) {
            if (process.analist.length === 0) {
              newData.push(process);
            } else {
              if (process.analist.findIndex((f) => f.id === id) > -1) {
                newData.push(process);
              }
            }
          } else {
            newData.push(process);
          }
        } else {
          /**
           * 29/06/2023
           * Eu não consegui entender o método (ou o motivo das checagens) de como estava funcionando, mas o fato era que após cadastrar o
           * acesso para o novo cliente da HEUSI COMISSARIA DE DESPACHOS E AGENCIAMENTOS LTDA os processos retornavam no endpoint mas não 
           * eram listados.
           * 
           * Cheguei os IFs todos e esse cliente não entrava em nenhum. Eu imagino que o método que procura por employerAccessList esta incorreto
           * pois o retorno não é uma lista apenas de ids. Como eu não entendi o motivo de todos os ifs preferi não alterar eles e simplesmente adicionei 
           * um novo else e if.
           * 
           * Eu tbm verifiquei que esse novo usuário cadastrado pelo intranet veio a principio com o employersAccessList vazio. Não sei se foi um erro ou se era
           * pra ser assim mesmo. Eu adicionei manualmente no banco.
           * 
           * Todos essse SE se devem ao fato do time estar focado no iNext e não podemos parar nesse momento para entender a raiz do problema.
           */
          const tempEmployersAccessList = RetrieveUserInformation().employersAccessList.map(employer => employer._id)
          if (tempEmployersAccessList?.includes(process?.client?._id)) {
            newData.push(process);
          }
        }
      }
    });

    currentAwait = tempData.filter((f) => f.status === "PENDING");

    currentPreBoarding = newData.filter(
      (f) =>
        (f.kanbanStatus === "Pré embarque" && f.microStatus !== "NENHUM") ||
        f.status === "SCHEDULED" ||
        f.status === "LOADED"
    );

    currentOnBoarding = newData.filter(
      (f) => f.kanbanStatus === "Em trânsito" || f.status === "DEPARTED"
    );

    currentFinish = newData.filter(
      (f) =>
        f.kanbanStatus === "Desembarcado" ||
        f.status === "ARRIVED" ||
        f.status === "DISCHARGED"
    );

    yield put({
      type: processTypes.CURRENT_AWAIT_SUCCESS,
      currentAwait,
    });

    yield put({
      type: processTypes.CURRENT_PROCESS_DATA_SUCCESS,
      data: newData,
    });
    yield put({
      type: processTypes.CURRENT_PRE_BOARDING_SUCCESS,
      currentPreBoarding,
    });
    yield put({
      type: processTypes.CURRENT_ON_BOARDING_SUCCESS,
      currentOnBoarding,
    });
    yield put({
      type: processTypes.CURRENT_FINISH_SUCCESS,
      currentFinish,
    });
  } catch (error) {
    console.log("error", error);

    yield put({ type: processTypes.CURRENT_PROCESS_FAILURE, data });
  }
}
