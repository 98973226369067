import axios from "axios";

const apiConsumer = axios.create({
  baseURL: process.env.REACT_APP_CONSUMER_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Expose-Headers": "Api-version",
    "X-Requested-With": "XMLHttpRequest",
  },
});

export const newDataAPI = axios.create({
  baseURL: process.env.REACT_APP_NEW_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiConsumer.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (
      error &&
      ((401 === error.response.status &&
        error.response.statusText === "Unauthorized") ||
        (400 === error.response.status &&
          error.response.statusText === "Unauthorized")) &&
      // eslint-disable-next-line eqeqeq
      window.location.hash != "#/"
    ) {
      window.location = "/";
    }
    console.error(error);
    return Promise.reject(error.response);
  }
);

apiConsumer.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

newDataAPI.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const buildURL = (query, sort, page, limit) => {
  let url = "";
  if (query !== null && query !== undefined && query !== false)
    url = url.concat(`?q=${query}`);
  if (sort !== null && sort !== undefined) url = url.concat(`&sort=${sort}`);
  if (page !== null && page !== undefined) url = url.concat(`&_page=${page}`);
  if (limit !== null && limit !== undefined)
    url = url.concat(query ? `&_pageSize=${limit}` : `?_pageSize=${limit}`);

  return url;
};

export default apiConsumer;
