import { createActions, createReducer } from "reduxsauce";

const initialState = {
  retract: true,
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  changeMenu: ["retract"],
});

// Criando os reducers handle
const changeMenu = (state = initialState, action) => ({
  ...state,
  retract: action.retract,
});
// Criando reducer
export default createReducer(initialState, {
  [Types.CHANGE_MENU]: changeMenu,
});
