import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

const CargoItems = (props) => {
  const { cargoItems, classes } = props;
  const { currentProcess } = useSelector((state) => state.stateSelected);

  return (
    <React.Fragment>
      <Typography className={classes.subtitle1}>
        <b>Itens de Carga</b>
      </Typography>
      {cargoItems.map((carga, index) => (
        <div
          key={carga._id}
          className={index % 2 === 0 ? "" : "bgGray"}
          style={{ width: "100%", padding: "6px" }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              textAlign: "left",
              flexWrap: "wrap",
            }}
          >
            {carga.identification && (
              <Grid xs={3} item={true}>
                <Typography color="textSecondary">
                  <b className="title-grid"> Container</b>
                  <br />
                  <span className="subtitle-grid">{carga.identification}</span>
                </Typography>
              </Grid>
            )}
            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid"> Peso Bruto</b>
                <br />
                <span className="subtitle-grid">
                  {carga.grossWeight + " kg"}
                </span>
              </Typography>
            </Grid>
            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid"> Metros cúbicos</b>
                <br />
                <span className="subtitle-grid">
                  {currentProcess.transportationModality === "Aéreo"
                    ? carga.cubicMeters + " m³"
                    : `${carga.measurement + " m³"}`}
                </span>
              </Typography>
            </Grid>
            {carga.freeTime && (
              <Grid xs={3} item={true}>
                <Typography color="textSecondary">
                  <b className="title-grid"> Free time</b>
                  <br />
                  <span className="subtitle-grid">{carga.freeTime}</span>
                </Typography>
              </Grid>
            )}

            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid"> Embalagem</b>
                <br />
                <span className="subtitle-grid">{carga.packageType}</span>
              </Typography>
            </Grid>
            <Grid xs={3} item={true}>
              <Typography color="textSecondary">
                <b className="title-grid"> Quantidade</b>
                <br />
                <span className="subtitle-grid">{carga.quantity}</span>
              </Typography>
            </Grid>
            {carga.equipament && (
              <Grid xs={3} item={true}>
                <Typography color="textSecondary">
                  <b className="title-grid"> Equipamento</b>
                  <br />
                  <span className="subtitle-grid">{carga.equipament}</span>
                </Typography>
              </Grid>
            )}

            {carga.seal && (
              <Grid xs={3} item={true}>
                <Typography color="textSecondary">
                  <b className="title-grid"> Lacre</b>
                  <br />
                  <span className="subtitle-grid">{carga.seal}</span>
                </Typography>
              </Grid>
            )}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default CargoItems;
