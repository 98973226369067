import "bootstrap/dist/css/bootstrap.min.css";
import "mapbox-gl/dist/mapbox-gl.css";

import React from "react";
import ReactDOM from "react-dom";

import "./global.scss";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
