import { createActions, createReducer } from "reduxsauce";

const initialState = {
  currentCompany: {
    address: { _id: "", street: "", district: "", city: "", zipcode: "" },
    annualDemurrage: false,
    brokers: [],
    cnpj: "",
    createdAt: "",
    email: "",
    fantasyName: "",
    idHC: null,
    isActive: true,
    name: "",
    permissions: [],
    profiles: [],
    updatedAt: "",
    _id: "",
  },
  id_company: "",
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  currentCompanyRequest: ["currentCompany"],
  currentIdCompany: ["id_company"],
});

// Criando os reducers handle
const currentCompanyRequest = (state = initialState, action) => ({
  ...state,
  currentCompany: action.currentCompany,
});

const currentIdCompany = (state = initialState, action) => ({
  ...state,
  id_company: action.id_company,
});

// Criando reducer
export default createReducer(initialState, {
  [Types.CURRENT_COMPANY_REQUEST]: currentCompanyRequest,
  [Types.CURRENT_ID_COMPANY]: currentIdCompany,
});
