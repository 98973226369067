/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormControl, Select } from "@material-ui/core";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";
import { NewGetUsers } from "services/User/user.service";
import { patchExternalProcess } from "services/Process/process.service";
const AnalistList = (props) => {
  const { currentProcess } = props;
  const [listUsers, setListUsers] = React.useState([]);
  const [analyst, setAnalyst] = React.useState("");
  const [alert, setAlert] = React.useState({
    severity: "",
    message: "",
    show: false,
  });
  const filterAnalystAndCompanyAdministrator = (value) => {
    if (
      value.profiles.includes("Analyst") ||
      value.profiles.includes("CompanyAdministrator")
    ) {
      return value;
    }
  };
  const getUsers = async () => {
    let criteria = [];

    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });
    try {
      const result = await NewGetUsers(
        criteria.length > 0 && JSON.stringify({ $and: criteria })
      );

      setListUsers(
        result.data.items.filter(filterAnalystAndCompanyAdministrator)
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleChooseAnalist = async (event) => {
    setAnalyst(event);
    let tempAnalyst = currentProcess?.analist;
    if (RetrieveUserInformation().roles.includes("CompanyAdministrator")) {
      let n = listUsers.filter((f) => f._id === event).map((m) => m.name);

      let idx = tempAnalyst.findIndex((f) => f.id === event);
      const data = {
        name: n[0],
        id: event,
      };
      if (idx !== -1) {
        tempAnalyst.splice(idx, 1);
      } else {
        tempAnalyst.push(data);
      }
    } else {
      const data = {
        name: RetrieveUserInformation().name,
        id: RetrieveUserInformation()._id,
      };
      let tempAnalyst = currentProcess.analist;
      let idx = tempAnalyst.findIndex(
        (f) => f.id === RetrieveUserInformation()._id
      );
      if (idx !== -1) {
        tempAnalyst.splice(idx, 1);
      } else {
        tempAnalyst.push(data);
      }
    }
    try {
      await patchExternalProcess(currentProcess._id, {
        analist: tempAnalyst,
      });
      setAlert({
        ...alert,
        severity: "success",
        message: "Analista selecionado com sucesso!",
        show: true,
      });
    } catch (error) {
      setAlert({
        ...alert,
        severity: "error",
        message: "Não foi possível processar sua solicitação",
        show: true,
      });
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        native
        disabled={
          RetrieveUserInformation().roles.includes("CompanyAdministrator") ||
          RetrieveUserInformation().roles.includes("Analyst")
            ? false
            : true
        }
        value={
          analyst !== ""
            ? analyst
            : currentProcess?.analist?.find(
                (f) => f.id === RetrieveUserInformation()._id
              )
            ? RetrieveUserInformation()._id
            : currentProcess?.analist[0]
            ? currentProcess?.analist[0].id
            : "-1"
        }
        onChange={(event) => handleChooseAnalist(event.target.value)}
      >
        <option value="-1" selected="true">
          Sem analista selecionado
        </option>
        {RetrieveUserInformation().roles.includes("CompanyAdministrator") ? (
          listUsers.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))
        ) : (
          <option value={RetrieveUserInformation()._id}>
            {RetrieveUserInformation().name}
          </option>
        )}
      </Select>
    </FormControl>
  );
};
export default AnalistList;
