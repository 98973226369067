import React, { useState, useEffect } from "react";

import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import EditIcon from "@material-ui/icons/Edit";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import _ from "lodash";
import moment from "moment";

import "./DataTable.scss";

const DataTable = (props) => {
  const {
    data: stateData,
    columns,
    status = true,
    startStatus = false,
    edit = true,
    callbackStatus,
    callbackEdit,
    emptyMessage,
    actions = null,
    lastAccessAt = true,
  } = props;
  const [data, setData] = useState(stateData);
  const [order, setOrder] = useState({});
  const [styleItem, setStyleItem] = useState({});

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#D9BBE9",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  useEffect(() => {
    setOrder({ [columns[0].name]: "asc" });

    let sub = 0;
    if (status) {
      sub += 12;
    }
    if (edit) {
      sub += 12;
    }
    if (actions) {
      sub += 12;
    }

    setStyleItem({
      minWidth: `${(100 - sub - columns.length * 2) / columns.length}%`,
      maxWidth: `${(100 - sub - columns.length * 2) / columns.length}%`,
    });
  }, [actions, columns, edit, status]);

  useEffect(() => {
    if (lastAccessAt && window.location.hash !== "#/archived-cargos") {
      let dataChanged = stateData.map((item) => {
        let data = item.lastAccessAt
          ? moment(item.lastAccessAt).toDate()
          : null;
        item = { ...item, lastAccessAt: data };
        return item;
      });

      setData(
        _.orderBy(
          dataChanged,
          Object.keys(order)
            .filter((item) => order[item])
            .map((item) => item),
          Object.keys(order)
            .filter((item) => order[item])
            .map((item) => order[item])
        ).map((item) => {
          let data = item.lastAccessAt
            ? moment(item.lastAccessAt)
                .subtract(3, "hours")
                .format("DD MMM YYYY HH:mm")
            : null;
          item = { ...item, lastAccessAt: data };
          return item;
        })
      );
    } else {
      setData(stateData);
    }
  }, [stateData, order, lastAccessAt]);

  const changeStatus = (v, id) => {
    callbackStatus(v, id);
  };

  return (
    <div>
      <div className="columnsDataTable">
        {startStatus && status && (
          <div className="columnStatusDataTable">Status</div>
        )}
        {columns.map((item, index) => (
          <div className="columnDataTable" style={styleItem} key={index}>
            {item.label}
            {item.filter ? (
              order[item.name] === "asc" ? (
                <ArrowDropDownIcon
                  className="iconFilterColumnDataTable"
                  onClick={() => {
                    setOrder({ [item.name]: "desc" });
                  }}
                />
              ) : order[item.name] === "desc" ? (
                <ArrowDropUpIcon
                  className="iconFilterColumnDataTable"
                  onClick={() => {
                    setOrder({ [item.name]: "" });
                  }}
                />
              ) : (
                <IndeterminateCheckBoxIcon
                  className="iconFilterColumnDataTable"
                  onClick={() => {
                    setOrder({ [item.name]: "asc" });
                  }}
                />
              )
            ) : (
              ""
            )}
          </div>
        ))}
        {!startStatus && status && (
          <div className="columnStatusDataTable">Status</div>
        )}
        {edit && <div className="columnStatusDataTable">Editar</div>}
        {actions && <div className="columnStatusDataTable">Ações</div>}
      </div>
      <div className="dataTable">
        {data.length > 0 &&
          data.map((item, index) => {
            let result = [];
            if (startStatus && status) {
              result.push(
                <div className="itemStatusDataTable" key={index}>
                  <IOSSwitch
                    checked={item.isActive}
                    onChange={(e, v) => changeStatus(v, item.id)}
                  />
                </div>
              );
            }
            columns.map((column, index) => {
              
              result.push(
                <div className="itemDataTable" style={styleItem} key={index}>
                  {/* {item[column.name] ? item[column.name] : "Não informado"} */}
                  {column.name === "employer" || column.name === "responsible"
                    ? item[column.name].name
                    : item[column.name]
                    ? item[column.name]
                    : ""}
                </div>
              );
              return column;
            });
            if (!startStatus && status) {
              result.push(
                <div className="itemStatusDataTable" key={index}>
                  <IOSSwitch
                    checked={item.isActive}
                    onChange={(e, v) => changeStatus(v, item.id)}
                  />
                </div>
              );
            }
            if (edit) {
              result.push(
                <div className="itemStatusDataTable" key={index}>
                  <EditIcon
                    className="actionIconDataTable"
                    onClick={() => callbackEdit(item)}
                  />
                </div>
              );
            }
            if (actions) {
              result.push(actions(item));
            }
            return (
              <div className="rowDataTable" key={index}>
                {result}
              </div>
            );
          })}
        {data.length === 0 && (
          <div className="msgEmptyDataTable">{emptyMessage}</div>
        )}
      </div>
    </div>
  );
};

export default DataTable;
