/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { MobileTokenInformation } from "./User/user.service";

export const mobileDataAPI = axios.create({
  baseURL: process.env.REACT_APP_NEW_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

mobileDataAPI.interceptors.request.use(async (config) => {
  const token = MobileTokenInformation();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
