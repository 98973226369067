import { createActions, createReducer } from "reduxsauce";

const initialState = {
  formErrors: {
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    currentPassword: "",
    profiles: "",
    companyName: "",
    faturas: "",
  },
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  getFormErrors: ["formErrors"],
});

// Criando os reducers handle
const getFormErrors = (state = initialState, action) => ({
  ...state,
  formErrors: action.formErrors,
});
// Criando reducer
export default createReducer(initialState, {
  [Types.GET_FORM_ERRORS]: getFormErrors,
});
