/* eslint-disable eqeqeq */
import React from "react";
import { useSelector } from "react-redux";

import { TextField, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";

import { NoteItem } from "./components";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";

import "./NoteTab.scss";
import { patchProcess } from "services/Process/process.service";

const NoteTab = (props) => {
  const { data } = useSelector((state) => state.stateProcess);
  const { processId } = props;
  const [currentNotes, setCurrentNotes] = React.useState(
    props.notes && props.notes.length > 0 ? props.notes : []
  );

  const [note, setNote] = React.useState({
    content: "",
    isPrivate: false,
    priority: "low",
  });

  const addNote = async () => {
    let data = {
      notes: currentNotes,
    };
    let obj = {
      content: note.content,
      isPrivate: note.isPrivate,
      priority: note.priority,
      owner: {
        name: RetrieveUserInformation().name,
        userID: RetrieveUserInformation()._id,
      },
    };
    data.notes.push(obj);
    await patchProcess(processId, data)
      .then((r) => {
        setNote({
          content: "",
          isPrivate: false,
          priority: "low",
        });
        updateDatabase(r.data);
        setCurrentNotes(r.data.notes);
      })
      .catch((e) => console.log("Erro", e));
  };

  const updateNote = async () => {
    let tempArray = currentNotes;
    let objIndex = tempArray.findIndex((obj) => obj._id == note._id);
    tempArray[objIndex] = note;
    await patchProcess(processId, { notes: tempArray })
      .then((r) => {
        setNote({
          content: "",
          isPrivate: false,
          priority: "low",
        });
        updateDatabase(r.data);
        setCurrentNotes(r.data.notes);
      })
      .catch((e) => console.log("Erro", e));
  };

  const editNote = (note) => {
    setNote(note);
  };

  const removeNote = async (note) => {
    let data = {
      notes: currentNotes.filter(function (item) {
        return item._id !== note._id;
      }),
    };
    await patchProcess(processId, data)
      .then((r) => {
        setNote({
          content: "",
          isPrivate: false,
          priority: "low",
        });
        updateDatabase(r.data);
        setCurrentNotes(r.data.notes);
      })
      .catch((e) => console.log("Erro", e));
  };

  const updateDatabase = async (process) => {
    let indexToChange = -1;
    const res = data.filter((item, index) => {
      if (item._id === processId) indexToChange = index;
      return item._id === processId;
    });
    if (indexToChange !== -1) {
      let newData = data;
      newData[indexToChange] = process;
      console.log(
        "Achei o processo pra atualizar as notas",
        res[0].processNumber
      );
    }
  };

  return (
    <div className="note-item">
      <hr />
      <div className="note-add">
        <TextField
          style={{ width: "100%" }}
          minRows={2}
          maxRows={5}
          multiline
          autoComplete="on"
          variant="outlined"
          placeholder="Adicionar uma anotação"
          value={note.content}
          onChange={(e) => {
            setNote({ ...note, content: e.target.value });
          }}
        />
        <div className="note-priority-select">
          <Button
            className="note-button"
            onClick={() => (note._id ? updateNote() : addNote())}
          >
            {note._id ? "Atualizar" : "Salvar"}
          </Button>
          <div>
            <FontAwesomeIcon
              onClick={() => {
                setNote({ ...note, isPrivate: !note.isPrivate });
              }}
              title={
                note.isPrivate
                  ? "Somente você terá acesso"
                  : "Todo mundo pode ver sua anotação."
              }
              icon={note.isPrivate ? faEyeSlash : faEye}
            ></FontAwesomeIcon>
            <span
              className="dot high"
              title="Bem importante"
              style={note.priority == "high" ? { opacity: 1 } : {}}
              onClick={() => {
                setNote({ ...note, priority: "high" });
              }}
            ></span>
            <span
              className="dot normal"
              title="Normal"
              style={note.priority == "normal" ? { opacity: 1 } : {}}
              onClick={() => {
                setNote({ ...note, priority: "normal" });
              }}
            ></span>
            <span
              className="dot low"
              title="Baixa importância"
              style={note.priority == "low" ? { opacity: 1 } : {}}
              onClick={() => {
                setNote({ ...note, priority: "low" });
              }}
            ></span>
          </div>
        </div>
      </div>
      {currentNotes &&
        currentNotes.length > 0 &&
        currentNotes
          .filter((i) => {
            return (
              i.isPrivate === false ||
              i.owner.userID === RetrieveUserInformation()._id
            );
          })
          .map((item, index) => (
            <NoteItem
              key={index}
              note={item}
              onRemove={(e) => removeNote(e)}
              onEdit={(e) => editNote(e)}
            ></NoteItem>
          ))}
    </div>
  );
};

export default NoteTab;

// function mapStateToProps(state) {
//     const { database } = state;
//     return {
//         database
//     };
// }

// const mapDispatchToProps = {
//     dispatchSetDatabase: database};

// export default connect(mapStateToProps, mapDispatchToProps)(NoteTab);
