import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import Reactotron from "../ReactotronConfig";

import rootReducer from "./reducers";
import sagas from "./sagas";

export default function configureStore(preloadedState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunkMiddleware, sagaMiddleware]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers, Reactotron.createEnhancer());

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  sagaMiddleware.run(sagas);

  return store;
}
