import React from "react";
import { Col } from "react-bootstrap";
import { Button, Modal, Divider } from "@material-ui/core";
import PropTypes from "prop-types";

import "./AlertReturn.scss";

const AlertReturn = (props) => {
  const { title, text, onClose, open } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Col className="alert-return-container">
        <Col className="alert-return-header">{title}</Col>
        <Divider />
        <Col className="alert-return-body">{text}</Col>
        <Col className="alert-return-footer">
          <Button className="alert-return-footer-ok" onClick={onClose}>
            Ok
          </Button>
        </Col>
      </Col>
    </Modal>
  );
};

AlertReturn.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AlertReturn;
