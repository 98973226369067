import { createActions, createReducer } from "reduxsauce";

const initialState = {
  currentAwait: [],
  currentFinish: [],
  currentPreBoarding: [],
  currentOnBoarding: [],
  isLoading: false,
  data: [],
  error: {},
};

export const { Types, Creator } = createActions({
  currentProcessRequest: ["isLoading"],
  currentProcessDataSuccess: ["data", "isLoading"],
  currentFinishSuccess: ["currentFinish"],
  currentOnBoardingSuccess: ["currentOnBoarding"],
  currentPreBoardingSuccess: ["currentPreBoarding"],
  currentAwaitSuccess: ["currentAwait"],
  currentProcessFailure: ["error", "isLoading"],
});

const currentProcessRequest = (state = initialState, action) => ({
  ...state,
  isLoading: true || state,
});

// const currentProcessLoading = (state = initialState, action ) => ({
//   isLoading: state
// })

const currentProcessDataSuccess = (state = initialState, action) => ({
  ...state,
  data: action.data || state,
  isLoading: false,
});

const currentAwaitSuccess = (state = initialState, action) => ({
  ...state,
  currentAwait: action.currentAwait || state,
});

const currentPreBoardingSuccess = (state = initialState, action) => ({
  ...state,
  currentPreBoarding: action.currentPreBoarding || state,
});

const currentOnBoardingSuccess = (state = initialState, action) => ({
  ...state,
  currentOnBoarding: action.currentOnBoarding || state,
});

const currentFinishSuccess = (state = initialState, action) => ({
  ...state,
  currentFinish: action.currentFinish || state,
});

const currentProcessFailure = (state = initialState, action) => ({
  ...state,
  error: action.error || state,
  isLoading: false,
});

export default createReducer(initialState, {
  [Types.CURRENT_PROCESS_REQUEST]: currentProcessRequest,
  [Types.CURRENT_AWAIT_SUCCESS]: currentAwaitSuccess,
  [Types.CURRENT_PROCESS_DATA_SUCCESS]: currentProcessDataSuccess,
  [Types.CURRENT_PRE_BOARDING_SUCCESS]: currentPreBoardingSuccess,
  [Types.CURRENT_ON_BOARDING_SUCCESS]: currentOnBoardingSuccess,
  [Types.CURRENT_FINISH_SUCCESS]: currentFinishSuccess,
  [Types.CURRENT_PROCESS_FAILURE]: currentProcessFailure,
});
