import { createActions, createReducer } from "reduxsauce";

const initialState = {
  link: {
    type: "",
    fileName: "",
    group: "procuration",
    url: "",
  },
};

// Cria os action types e creators
export const { Types, Creators } = createActions({
  getLink: ["link"],
});

// Criando os reducers handle
const getLink = (state = initialState, action) => ({
  ...state,
  link: action.link,
});
// Criando reducer
export default createReducer(initialState, {
  [Types.GET_LINK]: getLink,
});
