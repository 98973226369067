import React from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  FormControlLabel,
  Modal,
  Switch,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { NewEditUser } from "services/User/user.service";
import moment from "moment";
import "./UserItem.scss";

const UserItem = (props) => {
  let history = useHistory();
  const { u } = props;
  const [confirmChangeStatus, setConfirmChangeStatus] = React.useState(false);

  //Chama a edição de usuários
  const editUser = (item) => {
    item.profiles = [item.profiles[0]];
    item.cnpj = item.cnpj == null ? "" : item.cnpj[0];
    history.push({
      pathname: `/user-details/${item._id}`,
      state: {
        editAdm: true,
        item,
      },
    });
  };

  //Confirma a alteração de status
  const changeStatus = async () => {
    u.disabled = !u.disabled;
    console.log("Change Status", u);
    await NewEditUser(u)
      .then((r) => {
        setConfirmChangeStatus(false);
      })
      .catch((e) => console.log("Erro", e));
  };

  return (
    <>
      <div className="user-item">
        <FormControlLabel
          control={
            <Switch
              checked={!u.disabled}
              name="Status"
              onChange={() => setConfirmChangeStatus(true)}
            />
          }
        />
        <span className="text">{u.name}</span>
        <span className="text">
          {u.profiles && u.profiles.length > 0 && u.profiles[0]}
        </span>
        <Tooltip
          title={
            u && u.lastLogin
              ? moment.utc(u.lastLogin).format("DD/MM/YYYY - HH:mm")
              : ""
          }
        >
          <span className="text">
            {u.lastLogin && moment.utc(u.lastLogin).format("DD/MM/YYYY")}
          </span>
        </Tooltip>
        <span
          style={{ textAlign: "left", cursor: "pointer", color: "#9fa9bc" }}
        >
          <EditIcon onClick={() => editUser(u)} />
        </span>
      </div>

      <Modal
        open={confirmChangeStatus}
        onClose={() => {
          setConfirmChangeStatus(false);
        }}
      >
        <div className="modalListUser">
          <div className="messageModalListUser">
            Tem certeza de que gostaria de alterar o status desse usuário?
          </div>
          <div className="containerButtons">
            <Button
              className="cancelButtonListUser"
              variant="contained"
              size="medium"
              onClick={() => setConfirmChangeStatus(false)}
            >
              Cancelar
            </Button>
            <Button
              className="confirmButtonListUser"
              variant="contained"
              size="medium"
              onClick={changeStatus}
            >
              Alterar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserItem;
