import moment from "moment";

export const checkIssues = (issueTime) => {
  const diff = moment(new Date()).diff(
    moment.utc(issueTime).local().format(),
    "hours"
  );
  const currentTime = moment(issueTime).weekday();

  if (currentTime <= 4 && diff > 24) {
    return true;
  }

  if (currentTime === 5 && diff > 72) {
    return true;
  }
  if (currentTime === 6 && diff > 48) {
    return true;
  }

  return false;
};
