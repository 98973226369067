import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";

import SwipeableViews from "react-swipeable-views";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import ActivityTab from "./components/Tabs/ActivityTab";

import DetailTab from "./components/Tabs/DetailTab";
import { EtdEta } from "components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShip } from "@fortawesome/free-solid-svg-icons";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import useWindowDimensions from "util/useWindowDimensions";
import AnalystList from "./components/AnalystList";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "100%",
  },
  content: {
    padding: "5px",
    height: "100%",
  },
  modalWidth: {
    maxWidth: "fit-content",
    overflow: "hidden",
  },
  dialogContent: {
    margin: "auto !important",
    display: "inline !important",
    left: 0,
    height: "100%",
    width: "100%",
    padding: "0px",
  },
  leftContentModal: {
    height: "100%",

    boxShadow: "0px 0px 22px 0px rgba(184,175,184,1)",

    marginTop: "-5px",
  },
  rightContentModal: {
    width: "507px",

    paddingLeft: "2%",
    position: "absolute",
    top: "32px",
    right: "32px",
  },
  rightContentModalPdf: {
    width: "503px",
    height: "100%",
    float: "right",
    paddingLeft: "2%",
  },
  tabContainer: {
    padding: "2px",
    flexGrow: 1,
  },
  tabRoot: {
    minWidth: 100,
    textTransform: "none",
    fontSize: "10px",
  },
  textTitle: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
    overflow: "hidden !important",
    flex: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  textSubTitle: {
    fontSize: 12,
    textAlign: "left",
    paddingTop: "0px",
    width: "max-content",
  },
  activeChat: {
    fontSize: 15,
    float: "right",
    height: "11px",
    paddingRight: "12%",
    cursor: "pointer",
    color: "#707070",
  },
  indicatorCurrentTabActived: {
    backgroundColor: "transparent",
  },
  currentTabSelected: {
    backgroundColor: "#F2DFFC",
    color: "#6F2C91",
    borderRadius: "5px",
  },
  customIconFavorite: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#FFBFB5" },
    marginTop: "-9px",
    marginLeft: "5px",
    padding: "3px",
    alignSelf: "start",
  },
  dateBorder: {
    border: "1px grey",
    borderRadius: "5px",
    background: "#F1F1F1",
    width: "fit-content",
    textAlign: "center",
    padding: "5px",
  },
  chatButton: {
    width: "130px",
    fontSize: "18px",
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
  wrapper: {
    fontSize: 15,
    fontWeight: 600,
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box style={{ paddingTop: "10px" }} p={3}>
        {children}
      </Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ExportModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { height } = useWindowDimensions();

  const { currentProcess } = useSelector((state) => state.stateSelected);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Row
      className={
        height >= 720
          ? "externalModalContainer externalFull"
          : "externalModalContainer externalPartial"
      }
    >
      {console.log("currentProcess", currentProcess)}
      <Col md={12} className="p-0">
        <Row>
          <Col md={12} className="modalLastUpdate">
            ÚLTIMA ATUALIZAÇÃO:{" "}
            {moment
              .utc(currentProcess?.lastUpdate)
              .format("DD/MM/YYYY HH:mm:ss")}
          </Col>
          {/* <Col md={12} className="modalMaster">
            {currentProcess.master}
          </Col> */}
        </Row>
        <Row className="mt-4">
          <Col xs="auto" className="d-flex align-items-center ">
            <Typography
              className={classes.textTitle}
              color="textSecondary"
              gutterBottom
            >
              <FontAwesomeIcon icon={faShip} />
            </Typography>
          </Col>
          <Col
            xs={11}
            className="d-flex justify-content-between align-items-center p-0"
          >
            <Col xs="auto">
              <Typography
                className={classes.textTitle}
                color="textSecondary"
                gutterBottom
              >
                {currentProcess?.clientReference} - {currentProcess?.master}
              </Typography>

              <Typography
                className={classes?.textTitle}
                color="textSecondary"
                gutterBottom
              >
                {currentProcess.konfereResponse
                  ? `${currentProcess?.konfereResponse?.origin?.name}`
                  : `${currentProcess?.origin?.name}`}
                <ArrowRightAltIcon />
                {currentProcess?.konfereResponse
                  ? `${currentProcess?.konfereResponse?.destination?.name}`
                  : `${currentProcess?.destination?.name}`}
              </Typography>
            </Col>
            <Col xs={5} className="p-0 d-flex justify-content-end">
              <EtdEta infos={currentProcess?.dates} external />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="select">
            <AnalystList currentProcess={currentProcess} />
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <Tabs
              variant="fullWidth"
              value={value}
              classes={{
                indicator: classes.indicatorCurrentTabActived,
              }}
              onChange={handleChange}
              aria-label="tabs of modal"
            >
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.currentTabSelected,
                  wrapper: classes.wrapper,
                }}
                label="Atividade"
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.currentTabSelected,
                  wrapper: classes.wrapper,
                }}
                label="Detalhes"
              />
              {/* <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.currentTabSelected,
                  wrapper: classes.wrapper,
                }}
                label="Anotações"
              /> */}
            </Tabs>

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                className={classes.tabContainer}
                value={value}
                index={0}
                dir={theme.direction}
              >
                <ActivityTab
                  value={value}
                  onClose={props.onClose}
                  locations={null}
                  lastLocation={props.lastLocation}
                  noMaps={props.noMaps ? true : false}
                />
              </TabPanel>

              <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                className="p-0"
              >
                <DetailTab itemSelected={props.itemSelected} />
              </TabPanel>

              {/* <TabPanel value={value} index={3} dir={theme.direction}>
                <NoteTab
                  processId={currentProcess._id}
                  notes={currentProcess.notes}
                />
              </TabPanel> */}
            </SwipeableViews>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExportModal;
