import React from "react";
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Modal,
} from "@material-ui/core";

import "./RequestRevision.scss";

const RequestRevision = (props) => {
  const [data, setData] = React.useState({
    title: props.title ? props.title : "",
    content: "",
    responsible: props.responsible ? props.responsible : "",
  });

  return (
    <Modal
      className="request-revision"
      open={props.show}
      onClose={() => {
        props.onClose();
      }}
    >
      <Card>
        <CardHeader
          title="Pedido de revisão/atualização"
          subheader={data.title}
        />
        <CardContent>
          <TextField
            style={{ width: "100%" }}
            rows={6}
            rowsMax={10}
            multiline
            autoComplete="on"
            variant="outlined"
            placeholder="Descreva os itens que precisam de revisão/correção ou solicite a atualização da taxa de conversão."
            value={data.content}
            onChange={(e) => {
              setData({ ...data, content: e.target.value });
            }}
          />
        </CardContent>
        <CardActions className="buttons-area">
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              props.onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            className="primary"
            variant="contained"
            size="medium"
            onClick={() => props.requestValueReview(data.content)}
          >
            Enviar solicitação
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default RequestRevision;
