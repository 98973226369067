/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  Grid,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@material-ui/core";

import SendIcon from "@material-ui/icons/Send";
import // LoginUser,
// readConversation,
// getMessagesByUser,
// newGetMessages,
// newSendMessage,
// newCreateGroup,
"services/Chat/chat.service";

import "./Messenger.scss";
import { useSelector } from "react-redux";

import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";

const Messenger = () => {
  const { groupSelected, message } = useSelector((state) => state.stateChat);
  const messagesEnd = useRef(null);
  const userId = RetrieveUserInformation().email;
  const [usersDetails] = useState([]);
  const [newMessage, setNewMessage] = useState();
  let enterPress = false;

  const getUserName = (email) => {
    let user = usersDetails.filter((item) => {
      return item.userId === email;
    });
    return user.length > 0 && user[0].displayName ? user[0].displayName : email;
  };
  const scrollToBottom = () => {
    messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  };

  const sendMessage = async (e) => {
    //apenas para pegar o processNumber e o clientReference de itens que estão sem processo.
    // let partsOfName = groupSelected.name.split("|");

    // let message = {
    //   type: 5,
    //   contentType: 0,
    //   message: e,
    //   groupId: groupSelected.id,
    //   source: 1,
    //   read: true,

    //   metadata: {
    //     read: true,
    //     sendUser: userId,
    //     processNumber: process.processNumber
    //       ? process.processNumber
    //       : partsOfName[1].trim(),
    //     clientReference: process.clientReference
    //       ? process.clientReference
    //       : partsOfName[0].trim(),
    //     chat: RetrieveUserInformation().roles[0].toLowerCase(),
    //   },
    // };

    // await newSendMessage(message);
    await readChat(groupSelected.id)
      .then((r) => {
        showMessages();
      })
      .catch((e) => console.log("Erro no envio da mensagem"));
  };

  const onSendMessage = async (newMessage) => {
    if (newMessage) {
      sendMessage(newMessage);
    }
    setNewMessage("");
  };

  const showMessages = async () => {
    try {
      // const result = await newGetMessages(groupSelected.id);

      // dispatch({
      //   type: chatTypes.LOAD_MESSAGE_REQUEST,
      //   message: result.data.response.message.reverse(),
      // });

      // setUsersDetails(result.data.response.userDetails);

      scrollToBottom();
    } catch (error) {
      console.log(error);
    }
  };

  const readChat = async (groupId) => {
    // readConversation(groupId, userId);
  };

  useEffect(() => {
    if (Object.keys(groupSelected).length !== 0) showMessages();
  }, [groupSelected]);

  return (
    <Grid container className="messenger p-0">
      <div className="header">{groupSelected.name}</div>
      <div className="message-container">
        {message.length > 0 ? (
          <div className="message-list">
            {message.map((item, index) => {
              let labelData;
              let showLabel = true;
              if (
                moment(item.createdAtTime).format("DD/MM/YYYY") ===
                moment().format("DD/MM/YYYY")
              ) {
                labelData = "HOJE";
              } else if (
                moment(item.createdAtTime).format("DD/MM/YYYY") ===
                moment().subtract(1, "d").format("DD/MM/YYYY")
              ) {
                labelData = "ONTEM";
              } else {
                labelData = moment(item.createdAtTime).format(
                  "DD [de] MMMM [de] YYYY"
                );
              }
              if (
                index > 0 &&
                moment(item.createdAtTime).format("DD/MM/YYYY") ===
                  moment(message[index - 1].createdAtTime).format("DD/MM/YYYY")
              ) {
                showLabel = false;
              }
              return (
                <div id="messages" key={index} style={{ heigth: "100%" }}>
                  {showLabel && (
                    <div className="containerLabelData">
                      <div className="labelData">{labelData}</div>
                    </div>
                  )}
                  <div className="message">
                    <div
                      className={
                        item.metadata && item.metadata.sendUser !== userId
                          ? "card-chat-received-messages"
                          : "card-chat-sent-messages"
                      }
                    >
                      {item.metadata && item.metadata.sendUser !== userId && (
                        <div
                          className={
                            item.metadata && item.metadata.sendUser !== userId
                              ? "userReceivedMessage"
                              : "userSentMessage"
                          }
                        >
                          {item.metadata && item.metadata.sendUser === userId
                            ? userId
                            : getUserName(
                                item.metadata && item.metadata.sendUser
                                  ? item.metadata && item.metadata.sendUser
                                  : item.to
                              )}
                        </div>
                      )}
                      <p>{item.message}</p>
                      <span className="time_date">
                        {" "}
                        {moment(item.createdAtTime).format("HH:mm")}
                      </span>{" "}
                    </div>
                  </div>
                </div>
              );
            })}
            <div ref={messagesEnd} />
          </div>
        ) : (
          <div className="emptyMessageChat">
            <QuestionAnswerIcon />
            <p>Selecione uma conversa para exibir as mensagens.</p>
          </div>
        )}
        <div className="inputSendMessage">
          {message && message.length > 0 && (
            <OutlinedInput
              fullWidth
              onKeyPress={(event) => {
                if (!enterPress) {
                  enterPress = true;
                  if (event.key === "Enter") {
                    onSendMessage(newMessage);
                    setNewMessage("");
                    enterPress = false;
                  }
                }
              }}
              autoComplete="off"
              placeholder="Escreva sua mensagem"
              onFocus
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={() => {
                    onSendMessage(newMessage);
                  }}
                >
                  <IconButton
                    aria-label="toggle"
                    edge="start"
                    style={{ color: "#00B2AF" }}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        </div>
      </div>
    </Grid>
  );
};

export default Messenger;
