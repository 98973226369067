import apiConsumer, { newDataAPI, buildURL } from "../apiConsumer";

export const CreateAccount = async (data) => {
  return await apiConsumer.post("api/signup", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetRoles = async (data) => {
  return await apiConsumer.get("api/signup/roles", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ForgotPassword = async () => {
  return await apiConsumer.get("");
};

export const LogOut = async () => {
  return await apiConsumer.get("");
};

export const GetUsers = async () => {
  return await apiConsumer.get("/api/users/all", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ChangeStatusUser = async (data) => {
  return await apiConsumer.put(`/api/users/status/${data.id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetUser = async () => {
  return await apiConsumer.get(`/api/users`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const EditUserAdm = async (data) => {
  return await apiConsumer.put(`/api/users/admin/${data.id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const EditUser = async (data) => {
  return await apiConsumer.put(`/api/users`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetCompanies = async () => {
  return await apiConsumer.get(`/api/users/companies`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Novos métodos
export const NewGetUser = async (id) => {
  return await newDataAPI.get(`/users/${id}`);
};

export const NewGetUsers = async (query) => {
  return await newDataAPI.get(`/users/${buildURL(query, null, null, 99999)}`);
};

export const NewGetMyUsers = async (query) => {
  return await newDataAPI.get(
    `/users/myusers/${buildURL(query, null, null, 99999)}`
  );
};

export const NewGetRoles = () => {
  return [
    { name: "Administrador Cargo Play", value: "Administrator" },
    { name: "Administrador da Empresa", value: "CompanyAdministrator" },
    { name: "Comercial", value: "Commercial" },
    { name: "Analista", value: "Analyst" },
    { name: "Convidado", value: "Guest" },
    { name: "Financeiro", value: "Financial" },
    { name: "Financeiro da Empresa", value: "CompanyFinancial" },
    { name: "Operacional", value: "Operational" },
    { name: "Despachante", value: "Broker" },
    { name: "Liberação Online", value: "DocumentRelease" },
  ];
};

export const NewCreateAccount = async (data) => {
  return await newDataAPI.post("/users", data);
};

export const NewEditUser = async (data) => {
  if (data._id) return await newDataAPI.patch(`/users/${data._id}`, data);
  return null;
};
export const MobileTokenInformation = () => {
  const token = localStorage.getItem("mobileToken");
  if (token) return token;

  return undefined;
};
