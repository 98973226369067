/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { Button, Backdrop, CircularProgress } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Types as userTypes } from "store/reducers/userReducer";
import Upload from "assets/icons/upload_cloud.svg";

import "./Uploader.scss";
import { upload } from "services/Upload/upload.service";

export default (props) => {
  const { type, getLink } = props;
  const { currentUser } = useSelector((state) => state.stateUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    const uploadData = new FormData();
    uploadData.append("image", acceptedFiles[0]);
    await upload(uploadData)
      .then((r) => {
        setLoading(false);
        console.log(r);

        if (getLink) {
          getLink(r.data.link, acceptedFiles[0]);
        }

        if (type === "avatar") {
          dispatch({
            type: userTypes.LOAD_USER,
            currentUser: { ...currentUser, avatar: r.data.link },
          });
        }

        if (type === "validated") {
          dispatch({
            type: userTypes.GET_URL,
            currentURL: {
              group: "validated",
              url: r.data.link,
            },
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Erro de upload", e);
      });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <Backdrop className="loading" open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="uploader">
        <div {...getRootProps()} className="drop-area">
          <input {...getInputProps()} />
          <img src={Upload} alt="upload" />
          <p>Arraste e solte seu arquivo aqui</p>
          <p>ou</p>
          <Button variant="outlined" className="send-button">
            Escolher Arquivo
          </Button>
        </div>
      </div>
    </>
  );
};
