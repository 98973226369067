import { createActions, createReducer } from "reduxsauce";

const initialState = {
  newNotification: [],
  events: {},
};

// Cria os action types e creators

export const { Types, Creators } = createActions({
  loadChatRequest: ["chat", "events"],
  loadChatFailure: ["chat", "events"],
});

// Criando os reducers handle

const loadChatRequest = (state = initialState, action) => ({
  ...state,
  newNotification: action.newNotification,
  events: action.events,
});

const loadChatFailure = (state = initialState, action) => ({
  ...state,
  newNotification: action.newNotification,
  events: action.events,
});

// Criando reducer

export default createReducer(initialState, {
  [Types.LOAD_CHAT_REQUEST]: loadChatRequest,
  [Types.LOAD_CHAT_FAILURE]: loadChatFailure,
});
