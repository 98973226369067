/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  resolveIssue,
  askForCorrection,
} from "services/Process/process.service";

import "./IssueItem.scss";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";
import { checkIssues } from "util/useIssues";
export default (props) => {
  const { item, processID, responsibles, processNumber, refClient } = props;
  const [isTimeouted, setIsTimedOut] = useState(false);
  const [auxSolved, setAuxSolved] = React.useState(false);
  const [needsCorrect, setNeedsCorrect] = React.useState(false);
  const [correction, setCorrection] = React.useState("");
  const [operationalResponsible, setOperationalResponsible] =
    React.useState(null);
  const [correctionSent, setCorrectionSent] = React.useState(false);

  useEffect(() => {
    if (checkIssues(item.createdAt)) {
      setIsTimedOut(true);
    }
    // eslint-disable-next-line array-callback-return
    let resp = responsibles.filter((e) => {
      if (e.role === "OPERACIONAL") return e;
    });
    setOperationalResponsible(resp[0]);
  }, []);

  const viewPDF = (file) => {
    props.openPDF(file);
  };

  const approve = async (issueID) => {
    const r = await resolveIssue(processID, issueID);
    if (r.data.isSolved) setAuxSolved(true);
  };

  const sendCorrection = async () => {
    setNeedsCorrect(false);
    setCorrectionSent(true);
    let data = {
      issueID: item._id,
      processNumber: processNumber,
      status: "Reprovado",
      comments: correction,
      to: `gutemberg.pereira@nextshipping.com.br, joao.mendonca@nextshipping.com.br, ${
        operationalResponsible !== undefined ? operationalResponsible.email : ""
      }`,
      cc: RetrieveUserInformation().email,
      subject: `[${processNumber} | ${refClient}] Feedback de documentos`,
      responsible:
        operationalResponsible !== undefined
          ? operationalResponsible.name
          : "João Mendonça",
    };
    await askForCorrection(data).then(() => {
      setCorrection("");
    });
  };

  return (
    <>
      {!isTimeouted && (
        <div className="issue-container right">
          <p className="text-grey fs-15 mg-0">
            <b>{item.status}</b>
          </p>
          <small className="text-grey">
            Criado em{" "}
            {new Date(item.createdAt).toLocaleDateString("pt-BR", {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </small>
          <p style={{ whiteSpace: "pre-wrap" }}>
            <b>{item.title}</b>.
            <a
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={() => viewPDF(item.file)}
            >
              Clique aqui
            </a>{" "}
            para visualizar o documento.
          </p>
          <p>
            Caso prefira realizar o download do documento{" "}
            <a href={item.file} download>
              clique aqui
            </a>
            .
          </p>
          <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
            <Button
              onClick={() => approve(item._id)}
              style={{ marginRight: "32px" }}
              className="btnResolvePendingTimeline issue-buttons"
              variant="outlined"
              disabled={item.solved || auxSolved || isTimeouted}
            >
              {item.solved || auxSolved
                ? "Documento aprovado"
                : isTimeouted
                ? "Aprovado automáticamente"
                : "Aprovar documento"}
            </Button>

            <Button
              variant="outlined"
              className=" issue-buttons"
              disabled={isTimeouted}
              onClick={() => setNeedsCorrect(!needsCorrect)}
            >
              {isTimeouted
                ? "Limite expirado"
                : correctionSent || item.solved
                ? "Solicitar nova revisão"
                : "Solicitar revisão"}
            </Button>
          </div>
          {needsCorrect && (
            <div
              style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}
            >
              <TextField
                style={{ width: "100%", marginTop: "16px" }}
                rows={5}
                multiline
                autoComplete="on"
                variant="outlined"
                placeholder="Informe quais as correções necessárias."
                value={correction}
                onChange={(e) => {
                  setCorrection(e.target.value);
                }}
              />
              <Button
                className="btnResolvePendingTimeline issue-buttons"
                style={{ marginTop: "16px" }}
                disabled={isTimeouted}
                onClick={() => sendCorrection()}
              >
                Enviar
              </Button>
            </div>
          )}
          {correctionSent && !needsCorrect && (
            <Alert style={{ marginTop: "12px" }} severity="success">
              Sua solicitação foi enviada. Obrigado.
            </Alert>
          )}
        </div>
      )}
    </>
  );
};
