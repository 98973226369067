/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  OutlinedInput,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { patchProcess } from "services/Process/process.service";
import { NewGetUsers } from "services/User/user.service";

import "./AnalistSelect.scss";
import { Alert } from "@material-ui/lab";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";

const AnalistSelect = () => {
  const { currentCompany } = useSelector((state) => state.stateCompany);
  const { currentProcess } = useSelector((state) => state.stateSelected);
  const [listUsers, setListUsers] = useState([]);
  const [analyst, setAnalyst] = useState([]);
  const [newAnalyst, setNewAnalyst] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = React.useState({
    severity: "",
    message: "",
    show: false,
  });

  const getUsers = async () => {
    let criteria = [];

    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });
    try {
      const result = await NewGetUsers(
        criteria.length > 0 && JSON.stringify({ $and: criteria })
      );

      setListUsers(result.data.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
    if (
      RetrieveUserInformation().roles.includes("CompanyAdministrator") ||
      RetrieveUserInformation().roles.includes("Analyst")
    ) {
      setShowButton(true);
    }
    if (
      currentProcess.analist.length > 0 &&
      RetrieveUserInformation().roles.includes("Analyst")
    ) {
      setListUsers(currentProcess.analist);
    }
  }, []);

  // atualiza o analista do processo

  const handleChooseAnalist = async (event) => {
    setNewAnalyst(event);
    if (RetrieveUserInformation().roles.includes("CompanyAdministrator")) {
      let n = listUsers.find((f) => f._id === event);
      let tempAnalyst = currentProcess.analist;
      let idx = tempAnalyst.findIndex((f) => f.id === event);

      const data = {
        name: n.name,
        email: n.email,
        id: event,
      };

      if (idx !== -1) {
        tempAnalyst.splice(idx, 1);
        setMessage("Analista removido com sucesso");
      } else {
        tempAnalyst.push(data);
        setMessage("Analista alterado com sucesso");
      }
      if (event === "-1") {
        tempAnalyst = [];
        setAnalyst(tempAnalyst);
        setMessage("Analistas removidos com sucesso");
      }
      setAnalyst(tempAnalyst);
    } else {
      const data = {
        name: RetrieveUserInformation().name,
        id: RetrieveUserInformation()._id,
        email: RetrieveUserInformation().email,
      };
      let tempAnalyst = currentProcess.analist;
      let idx = tempAnalyst.findIndex(
        (f) => f.id === RetrieveUserInformation()._id
      );
      if (idx !== -1) {
        tempAnalyst.splice(idx, 1);
      } else {
        tempAnalyst.push(data);
      }
      setAnalyst(tempAnalyst);
    }
  };

  const updateAnalist = async () => {
    patchProcess(currentProcess._id, {
      analist: analyst,
    })
      .then((r) =>
        setAlert({
          ...alert,
          severity: "success",
          message: message,
          show: true,
        })
      )
      .catch((e) =>
        setAlert({
          ...alert,
          severity: "error",
          message: "Não foi possível processar sua solicitação",
          show: true,
        })
      );
  };

  return (
    <div className="broker-container">
      {currentCompany && (
        <div className="broker-div">
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={
                newAnalyst !== ""
                  ? newAnalyst
                  : currentProcess?.analist[0]?.id || "-1"
              }
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={(event) => handleChooseAnalist(event.target.value)}
              style={{ color: "#ada5b1" }}
              disabled={
                RetrieveUserInformation().roles.includes(
                  "CompanyAdministrator"
                ) || RetrieveUserInformation().roles.includes("Analyst")
                  ? false
                  : true
              }
              input={<OutlinedInput />}
            >
              <MenuItem value="-1" selected="true">
                Sem analista selecionado
              </MenuItem>
              {RetrieveUserInformation().roles.includes("CompanyAdministrator")
                ? listUsers &&
                  listUsers.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))
                : !currentProcess?.analist?.find(
                    (f) => f.id === RetrieveUserInformation()._id
                  ) && (
                    <MenuItem
                      key={RetrieveUserInformation()._id}
                      value={RetrieveUserInformation()._id}
                    >
                      {RetrieveUserInformation().name}{" "}
                    </MenuItem>
                  )}
              {RetrieveUserInformation().roles.includes("Analyst") &&
                listUsers.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {showButton && (
            <Button
              className="broker-submit"
              variant="contained"
              size="medium"
              onClick={updateAnalist}
            >
              Confirmar
            </Button>
          )}
        </div>
      )}

      {alert.show && (
        <Alert
          onClose={() => setAlert({ ...alert, show: false })}
          style={{ marginTop: "12px" }}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      )}
    </div>
  );
};

export default AnalistSelect;
