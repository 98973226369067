/* eslint-disable no-console */
import Reactotron from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";

const reactotron = Reactotron.configure({ name: "Reactotron log" })
  .use(reactotronRedux())
  .connect();

/*
 * Adiciona o reactotron ao console
 * para que possa ser utilizado em qualquer lugar na aplicação
 */
console.tron = reactotron;
console.tron.clear();

export default reactotron;
