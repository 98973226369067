/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import "./EtdEta.scss";
import { Col } from "react-bootstrap";

export default (props) => {
  const { infos, padding, external } = props;
  const [eta, setEta] = React.useState("");
  const [etd, setEtd] = React.useState("");
  const [exEta, setExEta] = useState("");
  const [exEtd, setExEtd] = useState("");
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (infos && infos.actualLoad)
      setExEtd(moment.utc(infos.actualLoad).format("DD MMM"));
    if (infos && infos.plannedLoad && !infos.actualLoad)
      setExEtd(moment.utc(infos.plannedLoad).format("DD MMM"));
    if (infos && infos.actualDischarge)
      setExEta(moment.utc(infos.actualDischarge).format("DD MMM"));
    if (infos && infos.plannedDischarge && !infos.actualDischarge)
      setExEta(moment.utc(infos.plannedDischarge).format("DD MMM"));
  }, [infos]);

  useEffect(() => {
    if (infos && infos.cargoLoad)
      setEtd(moment.utc(infos.cargoLoad).format("DD MMM"));
    else if (infos && infos.estimatedCargoLoad && !infos.cargoLoad)
      setEtd(moment.utc(infos.estimatedCargoLoad).format("DD MMM"));
    else if (
      infos &&
      infos.preBooking &&
      infos.preBooking.etd &&
      !infos.estimatedCargoLoad &&
      !infos.cargoLoad
    ) {
      setEtd(moment.utc(infos.preBooking.etd).format("DD MMM"));
      setIsPreview(true);
    }
    if (infos && infos.cargoUnload)
      setEta(moment.utc(infos.cargoUnload).format("DD MMM"));
    else if (infos && infos.estimatedCargoUnload && !infos.cargoUnload)
      setEta(moment.utc(infos.estimatedCargoUnload).format("DD MMM"));
    else if (
      infos &&
      infos.preBooking &&
      infos.preBooking.eta &&
      !infos.estimatedCargoUnload &&
      !infos.cargoUnload
    ) {
      setEta(moment.utc(infos.preBooking.eta).format("DD MMM"));
      setIsPreview(true);
    }
  }, [infos]);

  return (
    <Fragment>
      {external ? (
        <Col className="d-flex flex-row justify-content-between align-items-center">
          {exEtd !== "" && (
            <Col className="d-flex justify-content-center et-content mr-1">
              ETD{" "}
              <span
                style={{
                  marginLeft: "2px",
                  color: infos?.actualLoad ? "green" : "black",
                  padding: padding ? padding : "",
                }}
              >
                {" "}
                {exEtd}
              </span>
            </Col>
          )}
          {exEta !== "" && (
            <Col className="d-flex justify-content-center et-content ml-1">
              ETA{" "}
              <span
                style={{
                  marginLeft: "2px",
                  color: infos?.actualDischarge ? "green" : "black",
                  padding: padding ? padding : "",
                }}
              >
                {" "}
                {exEta}
              </span>
            </Col>
          )}
        </Col>
      ) : (
        <Col className="d-flex flex-row justify-content-between align-items-center">
          {etd !== "" && (
            <Col className="d-flex justify-content-center et-content mr-1">
              ETD{" "}
              <span
                style={{
                  marginLeft: "2px",
                  color: infos?.cargoLoad ? "green" : "black",
                  padding: padding ? padding : "",
                }}
              >
                {" "}
                {etd}
                {isPreview && <span title="Data prevista"> *</span>}
              </span>
            </Col>
          )}
          {eta !== "" && (
            <Col className="d-flex justify-content-center et-content ml-1">
              ETA{" "}
              <span
                style={{
                  marginLeft: "2px",
                  color: infos?.cargoUnload ? "green" : "black",
                  padding: padding ? padding : "",
                }}
              >
                {" "}
                {eta}
                {isPreview && <span title="Data prevista"> *</span>}
              </span>
            </Col>
          )}
        </Col>
      )}
    </Fragment>
  );
};
