import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { CargoItems } from "..";

import "./KnowledgeAir.scss";

const KnowledgeAir = (props) => {
  const { knowledge, classes, modality } = props;
  return (
    <Fragment>
      {knowledge && (
        <Fragment>
          <Typography className={classes.subtitle1}>MAWB</Typography>
          <Grid xs={3} item={true}>
            <Typography color="textSecondary" style={{ paddingLeft: "6px" }}>
              <b className="title-grid">Nº</b>
              <br />
              <span className="subtitle-grid">
                {knowledge.blMaster ? knowledge.blMaster.identification : " - "}
              </span>
            </Typography>
          </Grid>
          <Grid xs={3} item={true}>
            <Typography color="textSecondary">
              <b className="title-grid"> Incoterm</b>
              <br />
              <span className="subtitle-grid">{knowledge.incoterm}</span>
            </Typography>
          </Grid>

          {knowledge.bls && knowledge.bls.length > 0 && (
            <Fragment>
              <Typography className={classes.subtitle1}>HAWB</Typography>
              {knowledge.bls.map((item, index) => (
                <Fragment key={index}>
                  <Grid xs={3} item={true}>
                    <Typography
                      color="textSecondary"
                      style={{ paddingLeft: "6px" }}
                    >
                      <b className="title-grid"> Nº</b>
                      <br />
                      <span className="subtitle-grid">
                        {item.identification}
                      </span>
                    </Typography>
                  </Grid>

                  {item.cargoItems && item.cargoItems.length > 0 && (
                    <CargoItems
                      cargoItems={item.cargoItems}
                      classes={classes}
                      modality={modality}
                    />
                  )}
                </Fragment>
              ))}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default KnowledgeAir;
