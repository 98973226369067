import React from "react";
import { Col } from "react-bootstrap";
import { Button, Modal, Divider } from "@material-ui/core";
import PropTypes from "prop-types";

import "./AlertConfirmation.scss";

const AlertConfirmation = (props) => {
  const { title, text, fnConfirm, open, onClose } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Col className="alert-container">
        <Col className="alert-header">{title}</Col>
        <Divider />
        <Col className="alert-body">{text}</Col>
        <Col className="alert-footer">
          <Button className="alert-footer-cancel" onClick={onClose}>
            Cancelar
          </Button>
          <Button className="alert-footer-confirm" onClick={fnConfirm}>
            Confirmar
          </Button>
        </Col>
      </Col>
    </Modal>
  );
};

AlertConfirmation.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool,
  fnConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default AlertConfirmation;
