import React from "react";
import { IconButton, Badge } from "@material-ui/core";

import "./MyIconButton.scss";

const MyIconButton = (props) => {
  const { children, anchorRef, badgeContent, fnClick } = props;

  return (
    <IconButton
      variant="contained"
      color="inherit"
      ref={anchorRef}
      component="span"
      onClick={fnClick}
    >
      <Badge badgeContent={badgeContent} color="secondary">
        {children}
      </Badge>
    </IconButton>
  );
};

export default MyIconButton;
