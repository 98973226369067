/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { IfAnyGranted } from "react-authorization";

//Import Material-ui e Bootstrap
import { Col, OverlayTrigger, Popover } from "react-bootstrap";

// Import dos icons
import MenuIcon from "@material-ui/icons/Menu";

import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import useWindowDimensions from "util/useWindowDimensions";

//Import dos Components

import {
  ChatListMenu,
  NewModal,
  ModalAlert,
  MyIconButton,
  NotifyListMenu,
} from "components";

// Import dos Types do Redux

import { Types as notificationTypes } from "store/reducers/notificationReducer";
import { Types as chatTypes } from "store/reducers/chatReducer";
import { Types as selectedTypes } from "store/reducers/selectedReducer";
import { Types as notifyTypes } from "store/reducers/notifyReducer";
import { Types as TypesMenu } from "store/reducers/menuReducer";
import { Types as alertTypes } from "store/reducers/alertReducer";

// Import dos Serviços

import {
  getNotificationsNEW,
  putNotification,
} from "services/Notification/notification.service";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";

import logo from "assets/images/logo-cargo-play.svg";

import "./styles.scss";
import { getProcessesNew } from "services/Process/process.service";
import { getListChat } from "services/Chat/chat.service";

import { useHistory } from "react-router-dom";

const stContainer = {
  height: 60,
  backgroundColor: "#24122d",
  color: "#FFFFFF",
};

const MainHeader = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const userId = RetrieveUserInformation()._id;
  const [checkNotify, setCheckNotify] = useState([]);

  const history = useHistory();

  // const [timeUpdate, setTimeUpdate] = useState(null);
  const { notify } = useSelector((state) => state.stateNotify);

  const { newMessages, listChat } = useSelector((state) => state.stateChat);
  const noNotify = JSON.parse(localStorage.getItem("noNotify"));

  const [modal, setModal] = useState({
    notify: false,
    chat: false,
  });

  // const [newFeeds, setNewFeeds] = useState([]);
  const anchorRef = useRef(null);
  const [openChatListMenu, setOpenChatListMenu] = useState(false);
  // const [isFiltered, setIsFiltered] = React.useState(false);
  // const [filteredGroupFeeds, setFilteredGroupFeeds] = React.useState([]);
  // const [typeChannel, setTypeChannel] = useState("");
  const [showNewModal, setShowNewModal] = useState(false);
  const [stateModalAlert, setStateModalAlert] = useState({
    isOpenModalAlert: false,
    message: null,
  });

  // States Redux
  const { retract } = useSelector((state) => state.stateMenu);

  const { data } = useSelector((state) => state.stateProcess);

  // variavel responsável por guardar o tempo de atualização da lista do chat. o chat atualiza a cada 60 seg
  var interval;

  // Carregando para o redux as novas notificações

  const getNewNotify = async () => {
    const criteria = [];

    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });

    //Eu estou na lista de pessoas notificadas e ainda não li ?
    criteria.push({
      recipients: {
        $elemMatch: { email: RetrieveUserInformation().email, isRead: false },
      },
    });

    try {
      if (!RetrieveUserInformation().roles.includes("Guest")) {
        const response = await getNotificationsNEW(
          criteria.length > 0 && JSON.stringify({ $and: criteria }),
          "-createdAt"
        );
        dispatch({
          type: notifyTypes.NOTIFY_SUCCESS,
          notify: response.data.items,
        });
        setCheckNotify(response.data.items);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    if (!RetrieveUserInformation().roles.includes("Guest")) getNewNotify();
  }, []);

  useEffect(() => {
    interval = setInterval(() => {
      // getInitialRooms();
      if (!_.isEqual(notify, checkNotify)) {
        if (!RetrieveUserInformation().roles.includes("Guest")) getNewNotify();
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlerShowModal = () => {
    setShowNewModal(!showNewModal);
  };

  // buscando lista de novas mensagens de chat do usuario

  const getChat = async () => {
    try {
      const response = await getListChat(userId);
      dispatch({
        type: chatTypes.MY_LIST_CHAT,
        listChat: response.data,
        newMessages: response.data.filter((item) => item.newMessages > 0)
          .length,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getChat();
  }, []);

  useEffect(() => {
    if (newMessages > 0) {
      dispatch({
        type: alertTypes.SHOW_ALERT,
        open: true,
        message: "Você tem novas mensagens no chat",
        severity: "info",
      });
    }
  }, [newMessages]);

  // const filterFeeds = (text) => {
  //   if (text.length === 0) {
  //     setIsFiltered(false);
  //     setFilteredGroupFeeds([]);
  //   } else if (text.length >= 3) {
  //     setIsFiltered(true);
  //     setFilteredGroupFeeds(
  //       data.filter((i) => {
  //         return i.name.toUpperCase().includes(text.toUpperCase());
  //       })
  //     );
  //   }
  // };

  const toggleMenu = () => {
    dispatch({
      type: TypesMenu.CHANGE_MENU,
      retract: !retract,
    });
  };

  const notificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("Esse browser não suporta notificações desktop");
    } else {
      if (Notification.permission !== "denied") {
        await Notification.requestPermission();
      }
    }

    if (Notification.permission === "granted" && notify.length > 0) {
      // eslint-disable-next-line array-callback-return
      notify.map((item) => {
        const notification = new Notification("Cargo Play", {
          body: `${item.clientReference} | ${item.processNumber}  \n ${item.message} `,
        });
        notification.onClick = (e) => {
          e.preventDefault();
          window.focus();
          notification.close();
        };
      });
    }
  };

  useEffect(() => {
    if (!noNotify || Notification.permission === "denied") {
      notificationPermission();
    }
  }, [notify, noNotify]);

  // marcando como lido

  const handleChatRead = (id) => {
    const temp = listChat;

    const idx = temp.findIndex((obj) => obj._id === id);

    temp[idx].newMessages = 0;

    dispatch({
      type: chatTypes.HANDLE_CHAT_READ,
      listChat: temp,
    });
  };

  // abrindo chat direto da tela de notificação

  const openModalProcess = async (item) => {
    const tempProcess = await data.filter(
      (d) => d.processNumber === item.process.processNumber
    );

    try {
      dispatch({
        type: chatTypes.ALTER_PROCESS_SELECTED,
        processSelected: {
          _id: item._id,
          processNumber: item.process.processNumber,
        },
      });

      handleChatRead(item._id);

      dispatch({
        type: chatTypes.HANDLE_CHAT_NOTIFY,
        newMessages: newMessages > 0 ? newMessages - 1 : 0,
      });

      dispatch({
        type: selectedTypes.CURRENT_SELECTED,
        currentProcess: tempProcess[0],
      });

      if (tempProcess.length < 1) {
        dispatch({
          type: alertTypes.SHOW_ALERT,
          open: true,
          message: "Erro ao tentar abrir o processo - Processo não encontrado",
          severity: "error",
        });
        return;
      } else {
        history.push(`/process-details/${tempProcess[0]._id}`);
      }
    } catch (err) {
      console.log(err);

      dispatch({
        type: alertTypes.SHOW_ALERT,
        open: true,
        message: "Erro ao tentar abrir o processo",
        severity: "error",
      });
    }
  };

  const openModalFromNotify = async (item) => {
    const result = data.filter((i) => {
      return i.processNumber === item.processNumber;
    });

    // marca a mensagem como lida

    await putNotification(item);

    setCheckNotify(
      checkNotify.filter((i) => i.processNumber !== item.processNumber)
    );
    if (result.length > 0) {
      try {
        dispatch({
          type: selectedTypes.CURRENT_SELECTED,
          currentProcess: result[0],
        });
        setOpenChatListMenu(false);
        //setTypeChannel("operational");
        setShowNewModal(!showNewModal);
      } catch (error) {
        console.error(error);
      }
    } else {
      /* a carga não esta no front */
      try {
        const criteria = [{ processNumber: item.processNumber }];
        await getProcessesNew(
          criteria.length > 0 && JSON.stringify({ $and: criteria }),
          "clientReference"
        )
          .then((resp) => {
            if (resp.data.items && resp.data.items.length > 0) {
              dispatch({
                type: selectedTypes.CURRENT_SELECTED,
                currentProcess: resp.data.items[0],
              });
              setOpenChatListMenu(false);
              //setTypeChannel("operational");
              setShowNewModal(!showNewModal);
            }
          })
          .catch((err) => {
            console.log("Erro consulta processo arquivado", err);
            setOpenChatListMenu(false);

            setStateModalAlert({
              isOpenModalAlert: true,
              message: "Carga selecionada está arquivada",
            });
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (width >= 1450) {
      dispatch({
        type: TypesMenu.CHANGE_MENU,
        retract: !retract,
      });
    }
  }, []);

  const getNotification = async () => {
    const criteria = [];
    //qual cnpj

    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });

    //Eu estou na lista de pessoas notificadas e ainda não li ?
    criteria.push({
      recipients: {
        $elemMatch: { email: RetrieveUserInformation().email, isRead: false },
      },
    });

    try {
      if (!RetrieveUserInformation().roles.includes("Guest")) {
        const response = await getNotificationsNEW(
          criteria.length > 0 && JSON.stringify({ $and: criteria }),
          "-createdAt"
        );

        dispatch({
          type: notificationTypes.LOAD_CHAT_REQUEST,
          newNotification: response.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotification();
    //setTimeUpdate(moment().format("DD-MM-YYYY HH:mm:ss"));
  }, []);

  return (
    <Col style={stContainer} className="d-flex align-items-center p-0 shadow">
      {/* Logo  Icon e Version */}
      <Col md={4} className="d-flex align-items-center p-1">
        <MenuIcon onClick={toggleMenu} style={{ cursor: "pointer" }} />
        <img
          src={logo}
          alt="cargo play"
          style={{ width: 120, height: 34, marginLeft: 16, marginRight: 8 }}
        />
      </Col>

      <Col className="d-flex justify-content-end align-items-center p-0">
        {/* ICON CHAT  */}

        {RetrieveUserInformation().chatAccess === true && (
          <IfAnyGranted
            expected={[
              "Consumer",
              "Analyst",
              "Administrator",
              "CompanyAdministrator",
              "CompanyFinancial",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              onToggle={() => setOpenChatListMenu(false)}
              show={openChatListMenu}
              placement="bottom-start"
              overlay={
                <Popover
                  id={`popover-positioned-bottom`}
                  className="popover-chat-list"
                >
                  <Popover.Title
                    style={{ background: "#573c66", color: "#FFF" }}
                  >
                    Mensagens não lidas
                  </Popover.Title>
                  <Popover.Content className="popover-chat-list-content">
                    <ChatListMenu
                      groupFeeds={data}
                      openProcessModal={(e) => openModalProcess(e)}
                    />
                  </Popover.Content>
                </Popover>
              }
            >
              <MyIconButton
                anchorRef={anchorRef}
                badgeContent={newMessages}
                fnClick={() => setOpenChatListMenu(true)}
              >
                <ChatBubbleOutlineOutlinedIcon />
              </MyIconButton>
            </OverlayTrigger>
          </IfAnyGranted>
        )}

        {/* ICON NOTIFY */}

        <IfAnyGranted
          expected={[
            "Consumer",
            "Administrator",
            "Analyst",
            "CompanyAdministrator",
            "Guest",
            "CompanyFinancial",
          ]}
          actual={[...RetrieveUserInformation().roles]}
        >
          <NotifyListMenu
            fnToggle={() => setModal({ ...modal, notify: false })}
            show={modal.notify}
            fnClick={() => setModal({ ...modal, notify: true })}
            openProcessModal={(e) => openModalFromNotify(e)}
          />
        </IfAnyGranted>

        {JSON.parse(localStorage.getItem("userinfo")) && (
          <div className="circle">
            {JSON.parse(localStorage.getItem("userinfo")).name
              ? JSON.parse(localStorage.getItem("userinfo"))
                  .name.split(" ")
                  .map((n) => n[0])
                  .join("")
                  .substring(0, 2)
              : "CP"}
          </div>
        )}
      </Col>
      {showNewModal && (
        <NewModal onClose={handlerShowModal} open={showNewModal}></NewModal>
      )}

      {stateModalAlert.isOpenModalAlert && (
        <ModalAlert
          isOpenModalAlert={stateModalAlert.isOpenModalAlert}
          setStateModalAlert={setStateModalAlert}
          message={stateModalAlert.message}
        />
      )}
    </Col>
  );
};

export default MainHeader;
