/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import moment from "moment";

import "./Document.scss";

export default (props) => {
  const { item } = props;

  return (
    <ListItem className="document-item">
      <ListItemAvatar>
        <a href={item.url} download>
          <Avatar>
            <GetAppIcon />
          </Avatar>
        </a>
      </ListItemAvatar>
      <ListItemText
        primary={item.fileName}
        secondary={moment.utc(item.createdAt).format("DD/MM/YYYY hh:mm")}
      />
    </ListItem>
  );
};
