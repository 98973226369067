import React from "react";
import { Typography, Grid } from "@material-ui/core";

import "./DocumentTab.scss";

import { Document } from "./components";
import { RetrieveUserInformation } from "services/Auth/usermanager.service";

const DocumentTab = (props) => {
  const knowledge = [
    "DRAFT PARA APROVAÇÃO",
    "DRAFT REVISADO",
    "DOCUMENTO FINAL",
  ];

  const merchant = ["CE HOUSE"];
  const others = ["OUTROS DOCUMENTOS"];
  const cargo = ["PACKING LIST", "INVOICE"];

  const { documents, financials } = props;

  return (
    <div>
      {documents && documents.length > 0 && (
        <div className="root">
          <hr />
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ fontWeight: "600" }}
          >
            Conhecimentos
          </Typography>
          {documents
            .filter((item) => knowledge.includes(item.group))
            .map((item) => (
              <Grid container key={item._id}>
                <Document item={item} />
              </Grid>
            ))}
          <hr />
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ fontWeight: "600" }}
          >
            Mercante
          </Typography>
          {documents
            .filter((item) => merchant.includes(item.group))
            .map((item) => (
              <Grid container key={item._id}>
                <Document item={item} />
              </Grid>
            ))}
          <hr />
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ fontWeight: "600" }}
          >
            Outros Documentos
          </Typography>
          {documents
            .filter((item) => others.includes(item.group))
            .map((item) => (
              <Grid container key={item._id}>
                <Document item={item} />
              </Grid>
            ))}
          <hr />
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ fontWeight: "600" }}
          >
            Carga
          </Typography>
          {documents
            .filter((item) => cargo.includes(item.group))
            .map((item) => (
              <Grid container key={item._id}>
                <Document item={item} />
              </Grid>
            ))}
          <hr />
        </div>
      )}
      {RetrieveUserInformation().roles[0] !== "Guest" && (
        <>
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ fontWeight: "600" }}
          >
            Financeiro
          </Typography>
          {financials.map((item) => (
            <Grid container key={item._id}>
              <Document item={item} />
            </Grid>
          ))}
        </>
      )}
    </div>
  );
};

export default DocumentTab;
