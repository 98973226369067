import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Button, Modal, Card, CardContent } from "@material-ui/core";

import termsPath from './politica_de_privacidade_-_CP_07-2021.md'
import ReactMarkdown from 'react-markdown'

import "./PrivacyPolicy.scss";

const PrivacyPolicy = (props) => {
  const { closePrivacy } = props;
  const [openTerms, setOpenTerms] = useState(false);
  const [terms, setTerms] = useState('')
  
  /*
  Hoje o método só esta mostrando na dashboards.. mas fiquei pensando se isso
  não precisaria ser controlado no estado globarl?
  const [privacyPolicy, setPrivacyPolicy] = useState(localStorage.getItem("privacypolicy") || null);
  */

  useEffect(() => {
    fetch(termsPath)
      .then((response) => response.text())
      .then((text) => {
        setTerms(text)
      })
  }, [])

  const acceptPrivacyPolicy = () => {
    localStorage.setItem("privacypolicy", "YES")
    closePrivacy()

  }

  return (
    <>
      <Col className="terms-container p-0 pt-2 pb-2">
        <Col xs={10}>
          Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua
          experiência em nossos serviços, personalizar publicidade e recomendar
          conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda
          com tal monitoramento. Informamos ainda que atualizamos nossa{" "}
          <strong onClick={() => setOpenTerms(true)}>Política de Privacidade</strong>. Conheça
          nosso Portal da Privacidade e veja a nossa nova Política.
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          <Button onClick={() => acceptPrivacyPolicy()}>PROSSEGUIR</Button>
        </Col>
      </Col>
      {openTerms &&
        <Modal
          open={openTerms}
          onClose={() => setOpenTerms(false)}
          onEscapeKeyDown={() => setOpenTerms(false)}
          className="terms-modal"
        >
          <Card >
            <CardContent className="terms-card">
              <ReactMarkdown children={terms} />
            </CardContent>
          </Card>
        </Modal>
      }
    </>
  );
};

export default PrivacyPolicy;
