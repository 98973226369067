/* eslint-disable array-callback-return */
import React from "react";

import moment from "moment";
import "moment/locale/pt-br";

import "./NewTimeline.scss";

function NewTimeline(props) {
  const { followUps } = props.itemSelected
    ? props.itemSelected
    : props.currentProcess;

  return (
    <div>
      <div
        className=""
        // style={props.itemSelected ? { height: "260px" } : {}}
      >
        <div className="timeline">
          {followUps && followUps.length > 0 ? (
            followUps.map((item, index) => {
              if (item)
                return (
                  <div key={index} className="container right">
                    <p className="text-grey fs-15 mg-0">
                      <b>{item.status}</b>
                    </p>
                    <small className="text-grey pl-2">
                      Atualizado em{" "}
                      {moment
                        .utc(item.date)
                        .locale("pt-br")
                        .format("DD MMMM YYYY - HH:mm")}
                    </small>
                    {item.description ? (
                      <p
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    ) : (
                      <p className="text-justify">Não há nenhuma descrição</p>
                    )}
                  </div>
                );
            })
          ) : (
            <div>Nenhum status encontrado</div>
          )}
        </div>
      </div>
    </div>
  );
}

// const mapDispatchToProps = {
//   dispatchSetCurrentProcess: currentProcess,
// };

// function mapStateToProps(state) {
//   const { process } = state;
//   return {
//     process,
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(NewTimeline);

export default NewTimeline;
