import { all, takeLatest } from "redux-saga/effects";

import { Types as administratorTypes } from "../reducers/administratorReducer";
import { Types as ProcessTypes } from "../reducers/processReducer";
import { processRequest } from "./processSaga";

export default function* rootSaga() {
  yield all([
    takeLatest(ProcessTypes.CURRENT_PROCESS_REQUEST, processRequest),
    takeLatest(administratorTypes.CURRENT_SUCCESS, processRequest),
  ]);
}
