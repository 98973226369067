import { createActions, createReducer } from "reduxsauce";

const initialState = {
  currentFilter: {
    genericCode: null,
    favorite: false,
    air: false,
    marine: false,
    gathering: false,
    boarding: false,
    boardingTranshipment: false,
    unload: false,
    dateStart: null,
    dateEnd: null,
    type: null,
    origin: null,
    destination: null,
    step: "",
    disabledDateEnd: true,
    flagReadyToSearch: true,
    clearFilterToSearch: false,
    isValid: false,
    documents: false,
    priority: false,
  },
};

export const { Types, Creator } = createActions({
  FilterRequest: ["currentFilter"],
});

const FilterRequest = (state = initialState, action) => ({
  currentFilter: action.currentFilter || state,
});

export default createReducer(initialState, {
  [Types.FILTER_REQUEST]: FilterRequest,
});
