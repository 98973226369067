import React from "react";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";

// import { CustomTooltip } from "components";

const MicroStatus = (props) => {
  return (
    <Tooltip title={props.message} placement="top" arrow>
      <Typography
        className="infoBorderCard"
        style={
          props.alert
            ? {
                backgroundColor: "rgb(252, 67, 40, 0.1)",
                color: "#FC4328",
                textAlign: "center",
              }
            : { background: "#e5f7f7", color: "#00b2af", textAlign: "center" }
        }
        color="textSecondary"
      >
        {props.icon && (
          <FontAwesomeIcon
            icon={props.icon}
            style={{
              verticalAlign: "middle",
              fontSize: "12px",
              marginRight: "6px",
            }}
          />
        )}
        {props.message && props.message.length < 23
          ? props.message
          : props.message
          ? props.message.slice(0, 22) + "..."
          : ""}
      </Typography>
    </Tooltip>
  );
};

export default MicroStatus;
